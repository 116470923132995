<template>
    <div>
        <slot></slot>
        <el-dialog 
            v-dialogDrag 
            :title=recordsName
            v-loading="loading5" 
            :visible.sync="dialogCaoZuoSheBei" 
            width="710px" 
            @close="closeCaoZuoSheBei" 
            :close-on-click-modal="false"
            append-to-body>
            <!-- 集中情景面板 -->
			<el-form
				class="records"
				v-if="panelForm.CentralizedScenePanel.indexOf(panelForm.devSecondType) > -1"
			>
				<el-button
					type="primary"
					@click="sceneControl(item)"
					v-for="item in panelForm.records"
					:key="item.jsroId"
					>{{ item.spdDescribe }}</el-button
				>
			</el-form>
            <!-- 雷达 -->
            <el-form v-else-if="panelForm.radar.indexOf(panelForm.devSecondType) > -1">
              <RadarSensor :row="form.clickrow" :radarStatus="radarStatus"/>
            </el-form>
            <!-- 智能门锁 -->
            <el-form v-else-if="panelForm.intelligentDoorLock.indexOf(panelForm.devSecondType) > -1">
              <DoorLock :getCard="getCard" :row="form.clickrow"/>
            </el-form>
            <!-- 水表 -->
            <el-form v-else-if="panelForm.waterMeter.indexOf(panelForm.devSecondType) > -1">
              <WaterMeter :deviceData="this.deviceDatas" :hydropowers="hydropowers" :latsReading="latsReading" :thisReading="thisReading" />
            </el-form>
            <!-- 小语音智能管家 -->
			<el-form v-else-if="panelForm.chamberlainScene.includes(panelForm.devSecondType)">
                <ChamberlainScene 
                v-if="dialogCaoZuoSheBei" 
                @loading5="chamberlainSceneLoading5"
                :row="form.clickrow"
                :changestatus="changestatus" 
                :panelForm="panelForm" 
                :openLight="openLight"/>
			</el-form>
            <!-- 开关  -->
            <el-form v-else-if="panelForm.switch.indexOf(panelForm.devSecondType)>-1" inline>
                <div class='airConditioner'>
                   <div class="airHeader" style="text-align:center">
                        <p>设备已开启</p>
                        <div v-if="isSWShow" style="text-align:center;background:#63cf50;width: 100px;height: 100px;border-radius: 50%;margin: 0px auto;">
                            <svg width="70" height="100" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="#63cf50" fill-opacity="0.01"/><path d="M14.5 8C13.8406 8.37652 13.2062 8.79103 12.6 9.24051C11.5625 10.0097 10.6074 10.8814 9.75 11.8402C6.79377 15.1463 5 19.4891 5 24.2455C5 34.6033 13.5066 43 24 43C34.4934 43 43 34.6033 43 24.2455C43 19.4891 41.2062 15.1463 38.25 11.8402C37.3926 10.8814 36.4375 10.0097 35.4 9.24051C34.7938 8.79103 34.1594 8.37652 33.5 8" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M24 4V24" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div v-else style="text-align:center;background:#9b9c9d;width: 100px;height: 100px;border-radius: 50%;margin: 0px auto;">
                            <svg width="70" height="100" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="#63cf50" fill-opacity="0.01"/><path d="M14.5 8C13.8406 8.37652 13.2062 8.79103 12.6 9.24051C11.5625 10.0097 10.6074 10.8814 9.75 11.8402C6.79377 15.1463 5 19.4891 5 24.2455C5 34.6033 13.5066 43 24 43C34.4934 43 43 34.6033 43 24.2455C43 19.4891 41.2062 15.1463 38.25 11.8402C37.3926 10.8814 36.4375 10.0097 35.4 9.24051C34.7938 8.79103 34.1594 8.37652 33.5 8" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M24 4V24" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                   </div>
                    <div class="swButton">
                        <el-button size="mini" type="primary" @click="openRoad()">开</el-button>
                        <el-button size="mini" type="primary" @click="closeRoad()">关</el-button>
                    </div>
                    <div class="SWSelect">
                        <el-button size="mini" type="primary" @click="pairEq" v-if="panelForm.devSecondType == 75">配对</el-button>
                        <el-button v-if="panelForm.devSecondType == 75" size="mini" type="primary" @click="positioning">定位</el-button>
                        <el-button size="mini" type="primary" @click="pairedClick" v-if="panelForm.devSecondType == 92 || panelForm.devSecondType == 89">配对
                        </el-button>
                        <el-button size="mini" type="primary" @click="clearBinding" v-if="panelForm.devSecondType == 92 || panelForm.devSecondType == 89">清除绑定
                        </el-button>
                    </div>
                </div>
                <!-- 有源无线 -> 配对 -->
                <el-dialog 
                    v-dialogDrag 
                    title="配对" 
                    @close="pairingClose" 
                    v-loading="loading1" 
                    :visible.sync="dialogPairing" 
                    :close-on-click-modal="false" 
                    width="900px" 
                    append-to-body
                >
                    <el-row class="transferStyle">
                        <el-col>
                            <el-transfer 
                                :data="pairingObj.dataList" 
                                v-model="pairingObj.pairedDevices" 
                                :titles="pairingObj.title" 
                                :props="{key: 'jourDeviceId',label: 'devNickname'}" 
                                filterable 
                                filter-placeholder="搜索设备">
                            </el-transfer>
                        </el-col>
                    </el-row>
                    <div slot="footer">
                        <el-button @click="dialogPairing = false">取 消</el-button>
                        <el-button type="primary" @click="pairingSave">保 存</el-button>
                    </div>
                </el-dialog>
            </el-form>
            <!-- 中央空调 -->
            <el-form inline v-else-if="panelForm.centralAirConditioning.indexOf(Number(panelForm.devFirstType)) >-1" class='centralAirConditioning'>
              <!-- 上 -->
              <el-row v-if="form.clickrow.devRoad===0&&!form.clickrow.devSubsidiary">
                <el-button type="primary" @click="queryAirFacility" >查询子空调</el-button>
              </el-row>
              <div v-else>
                <el-row type="flex" justify="space-around">
                  <!-- 左边 -->
                  <el-col :span="11" class="model">
                    <div class="juzhon" style="line-height:60px;color:#02192E;">
                      <el-row>
                        <el-col :span="23" :offset="1">开 关：{{centralVRV.childVRVState.switchState}}</el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="23" :offset="1">室 温：{{centralVRV.childVRVState.envirTemp?centralVRV.childVRVState.envirTemp+'℃':'--'+'℃'}}</el-col>
                      </el-row>
                      <el-row>
                      </el-row>
                      <el-row>
                        <el-col :span="24" align="center">
                          <el-button @click="openVRV" icon="el-icon-switch-button">开</el-button>
                          <el-button @click="closeVRV" icon="el-icon-switch-button" >关</el-button>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                  <!-- 右边 -->
                  <el-col :span="11" class="model">
                    <div class="juzhon">
                       <el-row type="flex" align="middle">
                            <el-col :span="4" align="center" style="color:#02192E;">16℃</el-col>
                            <el-col :span="16" align="left">
                                <el-slider :min='16' :max='30' v-model="centralVRV.percentage" disabled></el-slider>
                            </el-col>
                            <el-col :span="4" align="center" style="color:#02192E;">30℃</el-col>
                        </el-row>
                        <el-row align="center">
                            <el-col style="font-weight: bold;font-size:36px;color:#02192E;" align="center">{{centralVRV.indoorTemperature+"℃"}}</el-col>
                        </el-row>
                        <el-row type="flex" align="middle" class="VRVBtnBox">
                            <el-col :span="8" align="center">
                                <el-button icon="el-icon-minus" @click.native="decrease" circle></el-button>
                            </el-col>
                            <el-col :span="8" align="center" style="color:#02192E;">温度调节</el-col>
                            <el-col :span="8" align="center">
                                <el-button icon="el-icon-plus" @click.native="increase" circle></el-button>
                            </el-col>
                        </el-row>
                    </div>
                  </el-col>
                </el-row>
                <!-- 下 -->
                <el-row type="flex" justify="space-around">
                  <!-- 左 -->
                  <el-col :span="11" class="model" align="center">
                    <div class="juzhon">
                      <el-row>
                        <el-col align="center">
                          <el-progress ref="VRVprogress" v-xr type="dashboard" :percentage="centralVRV.windSpeedPercentage" :color="centralVRV.colors" :format="VRVformat" calss="VRVprogress"></el-progress>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col align="center">
                        <el-button-group>
                          <el-button icon="el-icon-minus" @click.native="windSpeedDecrease"></el-button>
                          <el-button icon="el-icon-plus" @click.native="windSpeedDIncrease"></el-button>
                        </el-button-group>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                  <!-- 右 -->
                  <el-col :span="11" class="model" align="center">
                    <div class="juzhon">
                      <el-row>
                        <el-col :span="6">
                          制冷
                        </el-col>
                        <el-col :span="6">
                          制热
                        </el-col>
                        <el-col :span="6">
                          除湿
                        </el-col>
                        <el-col :span="6">
                          送风
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="6">
                          <div class="bigDot" @click="pointRefrigeration" ref="pointRefrigeration">
                            <el-image :src="img.url1" fit="fill"></el-image>
                          </div>
                        </el-col>
                        <el-col :span="6">
                          <div class="bigDot" @click="pointHeating" ref="pointHeating">
                            <el-image :src="img.url2" fit="fill"></el-image>
                          </div>
                        </el-col>
                        <el-col :span="6">
                          <div class="bigDot" @click="pointArefaction" ref="pointArefaction">
                            <el-image :src="img.url4" fit="fill"></el-image>
                          </div>
                        </el-col>
                        <el-col :span="6">
                          <div class="bigDot" @click="pointAeration" ref="pointAeration">
                            <el-image :src="img.url5" fit="fill"></el-image>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <el-dialog
                width="1000px"
                v-dialogDrag
                title="空调集合"
                :modal-append-to-body="false"
                v-loading="centralVRV.childLoading"
                :visible.sync="centralVRV.childDialog"
                append-to-body
              >
                <el-button size="small" :type="centralVRV.buttonType" @click="editVRV">{{centralVRV.buttonTest}}</el-button>
                  <!-- v-loading="centralVRV.gatherLoading" -->
                <el-table
                  :data="centralVRV.childTable"
                  style="width: 100%"
                  height="50vh">
                  <el-table-column
                    prop="extMhNum"
                    label="空调外机编号"
                    align="center"
                    show-overflow-tooltip>
                  </el-table-column>
                  <el-table-column
                    prop="IntMhNum"
                    label="空调内机编号"
                    align="center"
                    show-overflow-tooltip>
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    label="空调名称"
                    align="center"
                    show-overflow-tooltip>
                    <template slot-scope="scope">
                      <el-input
                        v-if="scope.row.editing == true"
                        ref="name"
                        v-model="scope.row.nameNew"
                        size="small"
                        placeholder="请输入空调名称"
                        clearable
                      ></el-input>
                      <span v-else style="width:150px">{{ scope.row.name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="选择房间"
                    align="center"
                    show-overflow-tooltip>
                    <template slot-scope="scope">
                      <el-col style="cursor: pointer;">
                        <el-input
                          v-if="scope.row.editing == true"
                          ref="roomName"
                          v-model="scope.row.roomNameNew"
                          size="small"
                          readonly
                          @click.native="selectRoom(scope.row)"
                          placeholder="点击选择房间"
                        ></el-input>
                        <span v-else style="width:150px">{{ scope.row.roomName }}</span>
                      </el-col>
                      <!-- <el-tooltip v-else effect="dark" content="双击选择房间" placement="top-start">
                        <span style="width:150px">{{ scope.row.roomName }}</span>
                      </el-tooltip> -->
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="定位"
                    align="center"
                    show-overflow-tooltip>
                    <template slot-scope="scope">
                      <el-button type="primary" size="small" @click="VRVPositioning(scope.row)">定位</el-button>
                      <el-button type="primary" size="small" @click="turnOnVRV(scope.row)">开</el-button>
                      <el-button type="primary" size="small" @click="turnOffVRV(scope.row)">关</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="centralVRV.childDialog = false">取 消</el-button>
                  <el-button type="primary" @click="childVRVSave">保 存</el-button>
                </span> 
                <el-dialog
                  v-dialogDrag
                  title="选择房间"
                  :visible.sync="centralVRV.selectRoomDialog"
                  :close-on-click-modal="false"
                  append-to-body
                  width="1000px"
                >
                  <InstallationAddress
                    @func="getMsgFormSon"
                    v-if="centralVRV.installationAddress"
                  ></InstallationAddress>
                  <div slot="footer">
                    <el-button @click="centralVRV.selectRoomDialog = false"
                      >取 消</el-button
                    >
                    <el-button type="primary" @click="addConfirm">确定</el-button>
                  </div>
                </el-dialog>
              </el-dialog>
            </el-form>
            <!-- 集控开关 -->
			<el-form v-else-if="panelForm.centralizedControl.indexOf(panelForm.devFirstType) > -1">
                <el-form-item>
                    <el-row type="flex" class="row-bg" justify="center">
                        <el-col :span="8">
                            <el-button type="primary" @click="pairedClick"
                                >配对</el-button
                            >
                            <el-button type="primary" @click="openLight"
                                >开</el-button
                            >
                            <el-button type="primary" @click="closeLight">关</el-button>
                        </el-col>
                    </el-row>
                    <el-row v-if="[98, 99].indexOf(panelForm.devSecondType)>-1">
                        <el-col :span="2" :offset="5">
                            亮度:
                        </el-col>
                        <el-col :span="12">
                            <el-slider 
                                :min="1" 
                                v-model="changestatus.nowStates.brightness" 
                                @change="openLight" 
                                :marks="{25: '25', 50: '50', 75: '75'}"
                            >
                            </el-slider>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:20px" v-if="[98, 99].indexOf(panelForm.devSecondType)>-1">
                        <el-col :span="2" :offset="5">
                            色温:
                        </el-col>
                        <el-col :span="12">
                            <el-slider 
                                :min="1" 
                                v-model="changestatus.nowStates.colorTemperature" 
                                @change="openLight" 
                                :marks="{25: '25', 50: '50', 75: '75'}"
                            >
                            </el-slider>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-dialog
                  v-dialogDrag
                  title="已配对"
                  :visible.sync="dialogpairedDev"
                  @close="closeDialogpairedDev" 
                  :close-on-click-modal="false"
                  append-to-body
                  width="1000px"
                >
                  <pair-table
                    :dataText="'已配对'"
                    :deviceId="form.clickrow.jourDeviceId"
                    :address="form.clickrow.hsAddDoorplateno"
                    v-if="pairTable"
                  ></pair-table>
                </el-dialog>
                <!-- 有源无线 -> 配对 -->
                <el-dialog
                    title="配对"
                    @close="pairingClose"
                    v-loading="loading1"
                    :visible.sync="dialogPairing"
                    :close-on-click-modal="false"
                    width="900px"
                    append-to-body
                    >
                    <el-row class="transferStyle">
                        <el-col>
                            <el-transfer
                                :data="pairingObj.dataList"
                                v-model="pairingObj.pairedDevices"
                                :titles="pairingObj.title"
                                filterable
                                filter-placeholder="搜索设备"
                            >
                                <span slot-scope="{ option }">
                                    {{option.label}}
                                </span>
                            </el-transfer>
                        </el-col>
                    </el-row>
                    <div slot="footer">
                        <el-button @click="dialogPairing = false">取 消</el-button>
                        <el-button type="primary" @click="pairingSave">保 存</el-button>
                    </div>
                </el-dialog>
            </el-form>
            <!-- 热水器插座 -->
            <w-socket 
                v-else-if="panelForm.devSecondType == 132 || panelForm.devSecondType == 5" 
                :es="dialogCaoZuoSheBei" 
                :id="infrareds.params.devId"
            >
            </w-socket>
            <!-- 红外 -->
            <el-form v-else-if="panelForm.lnfraredAlarm1.indexOf(panelForm.devSecondType)>-1" class="infrared">
                <el-popover placement="bottom" width="156">
                    <el-button type="primary" slot="reference">
                        <span>开关</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <p>
                        <el-button size="mini" style="width:100%" @click="infraredSwitch('switchs',0)" type="primary">保持</el-button>
                    </p>
                    <p>
                        <el-button size="mini" style="width:100%" @click="infraredSwitch('switchs',1)" type="primary">开</el-button>
                    </p>
                    <p>
                        <el-button size="mini" style="width:100%" @click="infraredSwitch('switchs',2)" type="primary">关</el-button>
                    </p>
                </el-popover>
                <el-popover placement="bottom" width="156" style="text-align: center">
                    <el-button type="primary" slot="reference">
                        <span>事件周期</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-input-number 
                        style="width: 85%;" 
                        size="small" 
                        v-model="infrareds.periodVaule" 
                        controls-position="right" 
                        :min="3" 
                        :max="10"
                    >
                    </el-input-number>&nbsp;秒
                    <p>
                        <el-button 
                            size="mini" 
                            style="width:100%" 
                            @click="infraredSwitch('spacing',infrareds.periodVaule)" 
                            type="primary"
                        >确定
                        </el-button>
                    </p>
                </el-popover>
                <el-popover placement="bottom" width="170" style="text-align: center">
                    <el-button type="primary" slot="reference">
                        <span>报警阈值</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-input-number 
                        style="width: 100%;" 
                        size="small" 
                        v-model="infrareds.thresholdVaule" 
                        placeholder="请输入1-65535" 
                        :min="1" 
                        :max="65535" 
                        controls-position="right">
                    </el-input-number>
                    <p>
                        <el-button 
                            size="mini" 
                            style="width:100%" 
                            @click="infraredSwitch('threshold',infrareds.thresholdVaule)" 
                            type="primary"
                        >确定
                        </el-button>
                    </p>
                </el-popover>
                <el-popover placement="bottom" width="156" style="text-align: center">
                    <el-button type="primary" slot="reference">
                        <span>灵敏度等级</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-input-number 
                        style="width: 100%;" 
                        size="small" 
                        v-model="infrareds.sensitivity" 
                        placeholder="请输入1-255" 
                        :min="1" 
                        :max="255" 
                        controls-position="right">
                    </el-input-number>
                    <p>
                        <el-button 
                            size="mini" 
                            style="width:100%" 
                            @click="infraredSwitch('sensitivity',infrareds.sensitivity)" 
                            type="primary"
                        >确定
                        </el-button>
                    </p>
                </el-popover>
                <!-- 222 -->
                <el-button @click="infraredReport" type="primary">上报记录</el-button>
                <el-dialog 
                    v-dialogDrag 
                    title="上报记录" 
                    :visible.sync="infraredReportDialog" 
                    width="800px" 
                    append-to-body 
                    :close-on-click-modal="false"
                >
                    <!-- 内容表格 -->
                    <el-table :data="infraredReportArr" highlight-current-row style="width:100%;">
                        <el-table-column prop="saDeviceSn" label="SN码" min-width="100px"></el-table-column>
                        <el-table-column prop="saDeviceStatus" label="设备状态" min-width="80px"></el-table-column>
                        <el-table-column prop="saWarningStatus" label="报警状态" min-width="80px"></el-table-column>
                        <el-table-column prop="saReportingTime" label="上报时间" min-width="80px"></el-table-column>
                    </el-table>
                </el-dialog>
            </el-form>
            <!-- 插座 -->
            <el-form v-else-if="panelForm.socket.indexOf(panelForm.devSecondType)>-1">
                <div class='airConditioner'>
                   <div class="airHeader" style="text-align:center">
                        <p>设备已开启</p>
                        <div v-if="issocketShow" style="text-align:center;background:#63cf50;width: 100px;height: 100px;border-radius: 50%;margin: 0px auto;">
                            <svg width="100" height="100" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"/><path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" fill="#63cf50" stroke="#63cf50" stroke-width="4" stroke-linejoin="round"/><path d="M15 26L17 32" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M33 26L31 32" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M24 13V19" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div v-else style="text-align:center;background:#9b9c9d;width: 100px;height: 100px;border-radius: 50%;margin: 0px auto;">
                            <svg width="100" height="100" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"/><path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" fill="#9b9c9d" stroke="#9b9c9d" stroke-width="4" stroke-linejoin="round"/><path d="M15 26L17 32" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M33 26L31 32" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M24 13V19" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                   </div>
                    <div class="swButton">
                        <el-button size="mini" type="primary" @click="openLight">通电</el-button>
                        <el-button size="mini" type="primary" @click="closeLight">断电</el-button>
                    </div>
                    <div class="SWSelect">
                        <el-button size="mini" type="primary" >配对</el-button>
                        <el-button size="mini" type="primary" @click="positioning">定位</el-button>
                    </div>
                </div>

                <el-form-item style="margin:15px 0">
                    <!-- <el-button @click="openLight" type="primary">通&nbsp; 电</el-button>
                    <el-button @click="closeLight" type="primary">断 &nbsp;电</el-button> -->

                    <!-- <el-button type="primary">过热警报开</el-button>
                        <el-button type="primary">过热警报关</el-button>
                        <el-button type="primary" @click="dialogWenDuSheZhi=true">设置警报温度</el-button>
                        </el-form-item>
                        <el-form-item>
                        <el-button type="primary" @click="dialogChaZuo=true">设置指示灯亮度</el-button>&nbsp;&nbsp;
                        <el-button type="primary">同步时钟</el-button>
                        <el-button type="primary">设置定时</el-button>
                        <el-button type="primary">恢复出厂设置</el-button>
                        <el-button type="primary" @click="dialogShangDian=true">上电默认设置</el-button>-->
                </el-form-item>
                <el-dialog v-dialogDrag title="温度设置" :visible.sync="dialogWenDuSheZhi" :close-on-click-modal="false" width="30%" center append-to-body>
                    温度：<el-input style="width:150px" size="mini"></el-input>
                </el-dialog>
                <el-dialog v-dialogDrag title="插座指示灯亮度设置" :visible.sync="dialogChaZuo" :close-on-click-modal="false" width="30%" center append-to-body>
                    亮度：<el-input style="width:150px"></el-input>
                </el-dialog>
                <el-dialog v-dialogDrag title="插座上电默认设置" :visible.sync="dialogShangDian" :close-on-click-modal="false" width="30%" center append-to-body>
                    选项：<el-select v-model="value10" placeholder="请选择" size="mini">
                        <el-option label="开" value="0"></el-option>
                        <el-option label="关" value="1"></el-option>
                        <el-option label="记忆" value="2"></el-option>
                    </el-select>
                </el-dialog>
            </el-form>
            <!-- 2.4G插座 -->
            <el-form v-else-if="panelForm.socket2.indexOf(panelForm.devSecondType)>-1">
                <div class='airConditioner'>
                   <div class="airHeader" style="text-align:center">
                        <p>设备已开启</p>
                        <div v-if="issocketShow" style="text-align:center;background:#63cf50;width: 100px;height: 100px;border-radius: 50%;margin: 0px auto;">
                            <svg width="100" height="100" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"/><path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" fill="#63cf50" stroke="#63cf50" stroke-width="4" stroke-linejoin="round"/><path d="M15 26L17 32" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M33 26L31 32" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M24 13V19" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div v-else style="text-align:center;background:#9b9c9d;width: 100px;height: 100px;border-radius: 50%;margin: 0px auto;">
                            <svg width="100" height="100" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"/><path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" fill="#9b9c9d" stroke="#9b9c9d" stroke-width="4" stroke-linejoin="round"/><path d="M15 26L17 32" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M33 26L31 32" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M24 13V19" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                   </div>
                    <div class="swButton">
                        <el-button size="mini" type="primary" @click="openLight">通电</el-button>
                        <el-button size="mini" type="primary" @click="closeLight">断电</el-button>
                    </div>
                    <div class="SWSelect">
                        <el-button size="mini" type="primary" >配对</el-button>
                        <el-button size="mini" type="primary" @click="positioning">定位</el-button>
                    </div>
                </div>

                <!-- <el-button @click="openLight" type="primary">通电</el-button>
                <el-button type="primary">断电</el-button> -->
            </el-form>
            <!-- 161 -->
            <el-form v-else-if="panelForm.scenePanel.indexOf(panelForm.devSecondType)>-1">
                <el-form-item>
                    <el-row>
                        <el-col :span="6">
                            <el-button @click="scenePanelClick" type="primary">配对面板</el-button>
                        </el-col>
                        <el-col :span="6">
                            <el-button @click="sceneCodeSetting" type="primary">情景码设置</el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item>
                    <el-row>
                        <el-col :span="6" v-for="(item,index) in scenePanelObj.scenePaneBut" :key="index">
                            <el-button @click="executeBtn(item)" type="primary" class="scenePaneBut"> {{ item.jscName }} </el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-dialog v-dialogDrag title="配对面板" :visible.sync="scenePanelObj.pairDialog" :close-on-click-modal="false" width="800px" append-to-body>
                    <el-form>
                        <el-form-item v-for="item in scenePanelObj.scenePaneList" :key="item.cpKeyValue" :label="item.name">
                            <el-select v-model="item.spdId" filterable clearable>
                                <el-option 
                                    v-for="item in scenePanelObj.sceneCategory" 
                                    :key="item.jsSceneCategoryId" 
                                    :label="item.jscName" 
                                    :value="item.jsSceneCategoryId"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item size="large">
                          <el-button type="primary" @click="scenePaneSave">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </el-dialog>
                <el-dialog v-dialogDrag title="情景码设置" :visible.sync="scenePanelObj.codeDialog" :close-on-click-modal="false" width="800px" append-to-body>
                    <el-form :model="scenePanelObj" ref="scenePanelObj">
                        <el-form-item v-for="(item,index) in scenePanelObj.scenePaneList" :key="item.cpKeyValue" :label="item.name" :prop="`scenePaneList.${index}.code`"
                                    :rules="[{min:10, max: 10, message: '长度需要为 10 个字符', trigger: 'blur' }]">
                            <el-input v-model="item.code" size="small" class="sceneInput" maxlength="10" :disabled="item.disabled" @input="scenePaneCodeInput($event,item)"></el-input>
                            <el-checkbox v-model="item.disabled" @change="scenePaneCheckbox($event,item)"></el-checkbox>
                        </el-form-item>
                        <el-form-item size="large">
                          <el-button type="primary" @click="sceneCodeSave('scenePanelObj')">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </el-dialog>
            </el-form>
            <!-- 带温控计量插座 -->
            <!-- 电箱 悠悠电箱i3 -->
            <el-form
              v-else-if="panelForm.socket3.indexOf(panelForm.devSecondType) > -1"
            >
              <el-card class="box-card">
                <div class="text item">
                  <el-row>
                    <el-col :span="8" style="font-size:15px;margin-bottom:10px">
                      <b>设备类型：</b>
                      <el-tag
                        type="success"
                        style="min-width:100px;text-align:center"
                        >{{ powerState.equipmentType }}</el-tag
                      >
                    </el-col>
                    <el-col :span="8" style="font-size:15px;margin-bottom:10px">
                      <b>子设备号：</b>
                      <el-tag
                        type="success"
                        style="min-width:100px;text-align:center"
                        >第{{ powerState.subEquipNum }}路</el-tag
                      >
                    </el-col>
                    <el-col :span="8" style="font-size:15px;margin-bottom:10px">
                      <b>上电模式：</b>
                      <el-tag
                        type="success"
                        style="min-width:100px;text-align:center"
                        v-if="panelForm.devSecondType === 139"
                      >
                        {{ powerState.powerOnStatus }}
                      </el-tag>
                      <el-dropdown @command="handleCommand" v-else>
                        <el-tag
                          type="success"
                          style="margin-left:4px;min-width:100px;text-align:center"
                        >
                          {{ powerState.powerOnStatus }}
                          <i class="el-icon-arrow-down el-icon--right" v-if="powerState.powerOnStatus!='不支持'"></i>
                        </el-tag>
                        <el-dropdown-menu slot="dropdown" v-if="powerState.powerOnStatus!='不支持'">
                          <el-dropdown-item
                            :command="{ devState: '上电配置', dataVal: '记忆' }"
                            >系统记忆</el-dropdown-item
                          >
                          <el-dropdown-item
                            :command="{ devState: '上电配置', dataVal: '开' }"
                            >默认通电</el-dropdown-item
                          >
                          <el-dropdown-item
                            :command="{ devState: '上电配置', dataVal: '关' }"
                            >默认断电</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-col>
                  </el-row>
                </div>
                <div class="text item">
                  <el-row>
                    <el-col :span="8" style="font-size:15px;margin-bottom:10px">
                      <b>开关状态：</b>
                      <el-dropdown @command="handleCommand">
                        <el-tag
                          type="success"
                          style="margin-left:4px;min-width:100px;text-align:center"
                        >
                          {{
                            powerState.switchingStatus ||
                              powerState.switchingState
                          }}
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-tag>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item :command="{ devState: '开' }"
                            >通电</el-dropdown-item
                          >
                          <el-dropdown-item :command="{ devState: '关' }"
                            >断电</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-col>
                    <el-col :span="8" style="font-size:15px;margin-bottom:10px">
                      <b>累计电量：</b>
                      <el-tag
                        type="success"
                        style="min-width:100px;text-align:center"
                        >{{ powerState.totalElecPower }}</el-tag
                      >
                    </el-col>
                    <el-col :span="8" style="font-size:15px;margin-bottom:10px">
                      <b>剩余电量：</b>
                      <el-tag
                        type="success"
                        style="margin-left:4px;min-width:100px;text-align:center"
                        v-if="panelForm.devSecondType === 138"
                      >
                        {{ powerState.electricityRemaining }}
                      </el-tag>
                      <el-popover
                        placement="bottom"
                        width="100"
                        trigger="click"
                        v-else
                      >
                        <el-input-number
                          v-model="powerState.electricityRemaining"
                          @change="
                            setPowerState({
                              devId: powerState.id,
                              devState: '剩余电量',
                              devPercentage: powerState.electricityRemaining,
                            })
                          "
                          :min="1"
                          style="width:100%;"
                          size="small"
                          :controls="false"
                          :precision="0"
                        >
                        </el-input-number>
                        <el-tag
                          slot="reference"
                          type="success"
                          style="margin-left:4px;min-width:100px;text-align:center"
                        >
                          {{ powerState.electricityRemaining }} W/h
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-tag>
                      </el-popover>
                    </el-col>
                  </el-row>
                </div>
                <div class="text item">
                  <el-row>
                    <el-col :span="8" style="font-size:15px;margin-bottom:10px">
                      <b>当前电压：</b>
                      <el-tag
                        type="success"
                        style="min-width:100px;text-align:center"
                        >{{ powerState.voltage }}</el-tag
                      >
                    </el-col>
                    <el-col :span="8" style="font-size:15px;margin-bottom:10px">
                      <b>当前功率：</b>
                      <el-tag
                        type="success"
                        style="min-width:100px;text-align:center"
                        >{{ powerState.power }}</el-tag
                      >
                    </el-col>
                    <el-col :span="8" style="font-size:15px;margin-bottom:10px">
                      <b>当前电流：</b>
                      <el-tag
                        type="success"
                        style="min-width:100px;text-align:center"
                        >{{ powerState.electricCurrent }}</el-tag
                      >
                    </el-col>
                  </el-row>
                </div>
                <div class="text item">
                  <el-row>
                    <el-col :span="8" style="font-size:15px;margin-bottom:10px">
                      <b>电压限定：</b>
                      <el-popover
                        placement="bottom"
                        width="100"
                        title="取值范围180V~260V"
                        trigger="click"
                        v-model="voltageVisible"
                      >
                        <el-input-number
                          v-model="thresholdVoltage"
                          @change="
                            setCurrentOrVoltage({
                              devSecondType:panelForm.devSecondType,
                              sn: powerState.sn,
                              limitedVoltage: thresholdVoltage,
                              id:powerState.id,
                            })
                          "
                          :min="180"
                          :max="260"
                          style="width:100%;"
                          size="small"
                          :controls="false"
                          :precision="0"
                        >
                        </el-input-number>
                        <el-tag
                          slot="reference"
                          type="success"
                          style="margin-left:4px;min-width:100px;text-align:center"
                          @click="showVisible(voltageVisible)"
                        >
                          {{ powerState.thresholdVoltage }} V
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-tag>
                      </el-popover>
                    </el-col>
                    <el-col :span="8" style="font-size:15px;margin-bottom:10px">
                      <b>功率限定：</b>
                      <el-popover
                        placement="bottom"
                        width="100"
                        trigger="click"
                        title="取值范围0-100000W"
                      >
                        <el-input-number
                          v-model="powerState.thresholdPower"
                          @change="
                            setPowerState({
                              devId: powerState.id,
                              devState: '阈值功率',
                              devPercentage: powerState.thresholdPower,
                            })
                          "
                          :min="0"
                          :max="100000"
                          style="width:100%;"
                          size="small"
                          :controls="false"
                          :precision="1"
                        >
                        </el-input-number>
                        <el-tag
                          slot="reference"
                          type="success"
                          style="margin-left:4px;min-width:100px;text-align:center"
                        >
                          {{ powerState.thresholdPower }} W
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-tag>
                      </el-popover>
                    </el-col>
                    <el-col :span="8" style="font-size:15px;margin-bottom:10px">
                      <b>电流限定：</b>
                      <el-popover
                        placement="bottom"
                        width="100"
                        trigger="click"
                        title="取值范围0~61A"
                        v-model="currentVisible"
                      >
                        <el-input-number
                          v-model="limitingCurrent"
                          @change="
                            setCurrentOrVoltage({
                              devSecondType:panelForm.devSecondType,
                              sn: powerState.sn,
                              limitedCurrent: limitingCurrent,
                              id:powerState.id,
                            })
                          "
                          :min="0"
                          :max="61"
                          style="width:100%;"
                          size="small"
                          :controls="false"
                          :precision="2"
                        >
                        </el-input-number>
                        <el-tag
                          slot="reference"
                          type="success"
                          style="margin-left:4px;min-width:100px;text-align:center"
                          @click="showVisible(currentVisible)"
                        >
                          {{ powerState.limitingCurrent }} A
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-tag>
                      </el-popover>
                    </el-col>
                  </el-row>
                </div>
                <div class="text item">
                  <el-row>
                    <el-col :span="8" style="font-size:15px;margin-bottom:10px">
                      <b>当前温度：</b>
                      <el-tag
                        type="success"
                        style="min-width:100px;text-align:center"
                        >{{ powerState.deviceTemperature }}</el-tag
                      >
                    </el-col>
                    <el-col :span="8" style="font-size:15px;margin-bottom:10px">
                      <b>温度限定：</b>
                      <el-popover
                        placement="bottom"
                        width="100"
                        trigger="click"
                        @show="powerStateset = powerState.thresholdTemp"
                        title="取值范围55~127 °C"
                      >
                        <el-input-number
                          v-model="powerStateset"
                          @change="
                            setPowerState({
                              devId: powerState.id,
                              devState: '温度限定',
                              electricityValue: powerStateset,
                            })
                          "
                          :min="55"
                          :max="127"
                          style="width:100%;"
                          size="small"
                          :controls="false"
                          :precision="0"
                        >
                        </el-input-number>
                        <el-tag
                          slot="reference"
                          type="success"
                          style="margin-left:4px;min-width:100px;text-align:center"
                        >
                          {{ powerState.thresholdTemp }} °C
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-tag>
                      </el-popover>
                    </el-col>
                    <el-col :span="8" style="font-size:15px;margin-bottom:10px">
                      <b>动作余流：</b>
                      <el-tag
                        type="success"
                        style="min-width:100px;text-align:center"
                        v-if="panelForm.devSecondType === 138"
                      >
                        {{ powerState.residualOperatCurrent }}
                      </el-tag>
                      <el-popover
                        placement="bottom"
                        width="100"
                        trigger="click"
                        v-else
                      >
                        <el-input-number
                          v-model="residualOperatCurrent"
                          @change="
                            setPowerState({
                              devId: powerState.id,
                              devState: '动作余流',
                              electricityValue: residualOperatCurrent,
                            })
                          "
                          :min="1"
                          style="width:100%;"
                          size="small"
                          :controls="false"
                          :precision="1"
                        >
                        </el-input-number>
                        <el-tag
                          slot="reference"
                          type="success"
                          style="margin-left:4px;min-width:100px;text-align:center"
                          @click="showVisible(residualOperatCurrent)"
                        >
                          {{ powerState.residualOperatCurrent }} mA
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-tag>
                      </el-popover>
                    </el-col>
                  </el-row>
                </div>
                <div class="text item">
                  <el-row>
                    <el-col :span="8" style="font-size:15px;margin-bottom:10px">
                      <b>可用电量预警：</b>
                      <el-tag
                          type="success"
                          style="min-width:100px;text-align:center"
                          v-if="panelForm.devSecondType === 138"
                        >
                          {{ powerState.limitPowerWarningValue }}
                        </el-tag>
                      <el-popover
                        placement="bottom"
                        width="100"
                        trigger="click"
                        v-else
                      >
                        <el-input-number
                          v-model="limitPowerWarningValue"
                          @change="
                            setPowerState({
                              devId: powerState.id,
                              devState: '限定电量预警',
                              devPercentage: limitPowerWarningValue,
                            })
                          "
                          :min="1"
                          style="width:100%;"
                          size="small"
                          :controls="false"
                          :precision="1"
                          v-if="powerState.limitPowerWarningValue!='不支持'"
                        >
                        </el-input-number>
                        <el-tag
                          slot="reference"
                          type="success"
                          style="margin-left:4px;min-width:100px;text-align:center"
                          @click="showVisible(limitPowerWarningValue)"
                        >
                          {{ powerState.limitPowerWarningValue }} W/H
                          <i class="el-icon-arrow-down el-icon--right" v-if="powerState.limitPowerWarningValue!='不支持'"></i>
                        </el-tag>
                      </el-popover>
                    </el-col>
                    <!-- ======= -->
                    <el-col :span="15" v-if="panelForm.devSecondType === 139" align="right">
                        <el-button type="primary" size="mini" @click="btnDialogHistorys">历史用量</el-button>
                        <el-button type="primary" size="mini"  @click="btnDialogSection">区间用量</el-button>
                        <el-button type="primary" size="mini"  @click="btnDialogDevice">设备预警</el-button>
                        <el-button type="primary" size="mini" @click="setPowerState({devId: powerState.id,devState: '故障恢复', })">故障恢复
                        </el-button>
                    </el-col>
                  </el-row>
                  
                </div>
                <el-dialog
                    title="历史用量"
                    :visible.sync="dialogHistorysVisible"
                    append-to-body
                >
                <Historys  :ecDayData="ecDayData" :ecMonthData="ecMonthData" :ecYearData="ecYearData"></Historys>
               </el-dialog>
                <el-dialog
                    title="区间用量"
                    :visible.sync="dialogSectionsVisible"
                    append-to-body
                >
                <Sections :deviceID="this.devicesId"></Sections>
                </el-dialog>
                  <el-dialog
                    title="设备预警"
                    :visible.sync="dialogDeviceVisible"
                    append-to-body
                >
                <!-- :deviceData="this.deviceData" -->
                <DeviceEarlWarning :deviceData="this.deviceDatas"></DeviceEarlWarning>
                </el-dialog>
              </el-card>
            </el-form>
            <!-- ============================ -->
            <!-- 门锁 -->
            <el-form v-else-if="panelForm.doorLock.indexOf(panelForm.devSecondType)>-1">
                <el-button type="primary" @click="getGateLockRecords">获取开锁记录</el-button>
            </el-form>
            <!-- 刷卡门锁 -->
            <el-form v-else-if="panelForm.doorcard.indexOf(panelForm.devSecondType)>-1">
                <el-button @click="setUpIC(true)" type="primary">添加IC卡</el-button>
                <el-button @click="setUpIC(false)" type="primary">注销IC卡</el-button>
                <el-button @click="lockControl('开锁')" type="primary">远程开锁</el-button>
                <el-button type="primary" @click="getGateLockRecords">历史记录</el-button>
                <el-button type="primary" @click="setUpMoreIC">批量操作IC卡</el-button>
                <!--   添加注销IC卡 开始   -->
                <el-dialog
                    :title="card.ICTitle"
                    :visible.sync="dialogIC"
                    @close="closeDialogIC"
                    :close-on-click-modal="false"
                    width="600px"
                    append-to-body
                >
                    <el-form inline :model="card" :rules="card.rules" ref="addCard">
                        <el-form-item label="用户" label-width="55px">
                            <el-cascader
                                :options="userNameList"
                                @change="selectICUser"
                                v-model="card.user"
                                :props="{ expandTrigger: 'hover' }"
                                :show-all-levels="false"
                                size="mini"
                                class="screenInput"
                                clearable
                            >
                            </el-cascader>
                        </el-form-item>
                        <el-form-item :label="card.ICLabel" prop="doorCardId">
                            <el-input
                                v-model="card.doorCardId"
                                size="mini"
                                class="screenInput"
                            ></el-input>
                            <el-button
                                icon="el-icon-bank-card"
                                size="mini"
                                @click="getCard"
                                style="margin-left:10px"
                                >读卡</el-button
                            >
                        </el-form-item>
                    </el-form>
                    <div slot="footer" style="text-slign: center;">
                        <el-button @click="dialogIC = false">取 消</el-button>
                        <el-button type="primary" @click="ICSave()">
                            {{ card.ICButton }}</el-button
                        >
                    </div>
                </el-dialog>
                <!--   添加注销IC卡 结束   -->
                <el-dialog
                    title="批量操作IC卡"
                    :visible.sync="cardsDialog"
                    @close="closeCardsDialog"
                    :close-on-click-modal="false"
                    width="600px"
                    append-to-body
                >
                    <el-tabs type="border-card" @tab-click="cardsHandleClick">
                        <el-tab-pane label="批量添加IC卡">
                            <el-form :model="addCardsData" :rules="addCardsData.rules" ref="addCardsData" label-width="80px">
                                <el-form-item label="用户" prop="user">
                                    <el-cascader
                                        :options="userNameList"
                                        @change="selectICUser"
                                        v-model="addCardsData.user"
                                        :props="{ expandTrigger: 'hover' }"
                                        :show-all-levels="false"
                                        size="mini"
                                        clearable
                                    >
                                    </el-cascader>
                                </el-form-item>
                                <el-form-item label="IC卡号" prop="list">
                                    <el-input type="textarea" v-model="addCardsData.list"></el-input>
                                </el-form-item>
                                <el-button
                                    icon="el-icon-bank-card"
                                    size="mini"
                                    @click="getCard"
                                    style="margin-left:10px"
                                >读卡</el-button>
                            </el-form>
                            <el-button type="primary" @click="addCards" style="float:right">提交</el-button>
                        </el-tab-pane>
                        <el-tab-pane label="批量注销IC卡">
                            <el-form :model="cancelCardsData" :rules="cancelCardsData.rules" label-width="80px" ref="cancelCardsData">
                                <el-form-item label="用户" prop="user">
                                    <el-cascader
                                        :options="userNameList"
                                        @change="selectICUser"
                                        v-model="cancelCardsData.user"
                                        :props="{ expandTrigger: 'hover' }"
                                        :show-all-levels="false"
                                        size="mini"
                                        clearable
                                    >
                                    </el-cascader>
                                </el-form-item>
                                <el-form-item label="IC卡号" prop="list">
                                    <el-input type="textarea" v-model="cancelCardsData.list"></el-input>
                                </el-form-item>
                                <el-button
                                    icon="el-icon-bank-card"
                                    size="mini"
                                    @click="getCard"
                                    style="margin-left:10px"
                                >读卡</el-button>
                            </el-form>
                            <el-button type="primary" @click="cancelCards" style="float:right">提交</el-button>
                        </el-tab-pane>
                    </el-tabs>
                </el-dialog>
            </el-form>
            <!-- 密码门锁 -->
            <el-form v-else-if="panelForm.doorPsd.indexOf(panelForm.devSecondType)>-1">
                <div v-if="panelForm.devSecondType == 58">
                    <el-form-item style="margin-bottom:5px">
                        <el-button @click="addPassword" type="primary">添加在线密码</el-button>
                        <el-button @click="setUpIC(true)" type="primary">添加在线门卡</el-button>
                        <el-button @click="setUpIC(false)" type="primary">注销在线门卡</el-button>
                        <!--<el-button @click="setUpIC(false)" type="primary">注销IC卡</el-button>-->
                        <!--<el-button @click="lockControl('注销所有用户密码')" type="primary">清空密码</el-button>-->
                        <el-button @click="lockControl('注销所有用户密码')" type="primary">清空在线密码</el-button>
                    </el-form-item>
                    <el-form-item style="margin-bottom:5px">
                        <el-button @click="offlinePasswordBtn" type="primary">添加离线密码</el-button>
                        <el-button @click="addIC" type="primary" v-if="panelForm.devSecondType == 58">添加离线门卡</el-button>
                        <el-button type="primary" @click="offlinePswfailure">注销离线授权</el-button>
                        <el-button type="primary" @click="AlwaysOnBtn" v-if="panelForm.devSecondType == 58">启用常开模式</el-button>
                        <!--  <el-button @click="lockControl('注销所有用户IC卡与密码')" type="primary">清空IC卡和密码</el-button>-->
                    </el-form-item>
                    <el-form-item style="margin-bottom:5px">
                        <el-button @click="lockControl('启用本地用户密码')" type="primary">启用密码键盘</el-button>
                        <el-button @click="lockControl('禁用本地用户密码')" type="primary">禁用密码键盘</el-button>
                        <el-button @click="lockControl('启用门卡')" type="primary">启用读卡功能</el-button>
                        <el-button @click="lockControl('禁用门卡')" type="primary">禁用读卡功能</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="lockControl('开锁')" type="primary">远程一键开锁</el-button>
                        <el-button type="primary" @click="initializeBtn" style="width: 126px;">门锁初始化 </el-button>
                        <el-button type="primary" @click="getGateLockRecords">门锁历史记录</el-button>
                        <el-button type="primary" @click="getAuthorizationRecord">门锁授权记录</el-button>
                    </el-form-item>
                </div>
                <div v-else-if="panelForm.devSecondType == 60">
                    <el-form-item style="margin-bottom:5px">
                        <el-button @click="setUpIC(true)" type="primary">添加在线门卡</el-button>
                        <el-button @click="setUpIC(false)" type="primary">注销在线门卡</el-button>
                        <el-button @click="lockControl('启用门卡')" type="primary">启用读卡功能</el-button>
                        <el-button @click="lockControl('禁用门卡')" type="primary">禁用读卡功能</el-button>
                        <!--  <el-button @click="lockControl('注销所有用户IC卡与密码')" type="primary">清空IC卡和密码</el-button>-->
                    </el-form-item>
                    <el-form-item style="margin-bottom:5px">
                        <el-button @click="lockControl('开锁')" type="primary">远程一键开锁</el-button>
                        <el-button @click="lockControl('注销所有用户IC卡')" type="primary" style="width: 126px;">清 空 门 卡</el-button>
                        <el-button type="primary" @click="initializeBtn" style="width: 126px;">离线密码初始化 </el-button>
                        <el-button type="primary" @click="getGateLockRecords">门锁历史记录</el-button>
                    </el-form-item>
                </div>
                <div v-else-if="panelForm.devSecondType == 87">
                    <el-form-item style="margin-bottom:5px">
                        <el-button @click="addPassword" type="primary">添加在线密码</el-button>
                        <el-button @click="setUpIC(true)" type="primary">添加在线门卡</el-button>
                        <el-button @click="setUpIC(false)" type="primary">注销在线门卡</el-button>
                        <el-button @click="offlinePasswordBtn" type="primary">添加离线密码</el-button>
                        <!--  <el-button @click="lockControl('注销所有用户IC卡与密码')" type="primary">清空IC卡和密码</el-button>-->
                    </el-form-item>
                    <el-form-item style="margin-bottom:5px">
                        <el-button @click="lockControl('启用本地用户密码')" type="primary">启用密码键盘</el-button>
                        <el-button @click="lockControl('禁用本地用户密码')" type="primary">禁用密码键盘</el-button>
                        <el-button @click="lockControl('启用门卡')" type="primary">启用读卡功能</el-button>
                        <el-button @click="lockControl('禁用门卡')" type="primary">禁用读卡功能</el-button>
                    </el-form-item>
                    <el-form-item style="margin-bottom:5px">
                        <el-button @click="lockControl('开锁')" type="primary">远程一键开锁</el-button>
                        <el-button @click="lockControl('注销所有用户IC卡')" type="primary" style="width: 126px;">清 空 门 卡</el-button>
                        <el-button @click="lockControl('注销所有用户密码')" type="primary">清空在线密码</el-button>
                        <el-button type="primary" @click="initializeBtn" style="width: 126px;">离线密码初始化 </el-button>
                    </el-form-item>
                    <el-form-item style="margin-bottom:5px">
                        <el-button type="primary" @click="getGateLockRecords">门锁历史记录</el-button>
                        <el-button type="primary" @click="getAuthorizationRecord">门锁授权记录</el-button>
                    </el-form-item>
                </div>
                <div v-else>
                    <el-form-item style="margin-bottom:5px">
                        <el-button @click="addPassword" type="primary">添加在线密码</el-button>
                        <el-button @click="setUpIC(true)" type="primary">添加在线门卡</el-button>
                        <el-button @click="setUpIC(false)" type="primary">注销在线门卡</el-button>
                        <el-button @click="lockControl('注销所有用户密码')" type="primary">清空在线密码</el-button>
                    </el-form-item>
                    <el-form-item style="margin-bottom:5px">
                        <el-button @click="lockControl('启用本地用户密码')" type="primary">启用密码键盘</el-button>
                        <el-button @click="lockControl('禁用本地用户密码')" type="primary">禁用密码键盘</el-button>
                        <el-button @click="lockControl('启用门卡')" type="primary">启用读卡功能</el-button>
                        <el-button @click="lockControl('禁用门卡')" type="primary">禁用读卡功能</el-button>
                    </el-form-item>
                    <el-form-item style="margin-bottom:5px">
                        <el-button @click="lockControl('开锁')" type="primary">远程一键开锁</el-button>
                        <el-button type="primary" @click="getGateLockRecords">门锁历史记录</el-button>
                        <el-button type="primary" @click="getAuthorizationRecord">门锁授权记录</el-button>
                    </el-form-item>
                </div>
                <!--   添加密码 开始   -->
                <el-dialog 
                    v-dialogDrag 
                    :title='passwordTitle' 
                    v-loading="password.loading" 
                    :visible.sync="dialogAddPassword" 
                    @close="closeDialogAddPassword" 
                    :close-on-click-modal="false" 
                    width="700px" 
                    append-to-body>
                    <el-form inline label-width="75px" :model="password" :rules="password.rules" ref="addPassword">
                        <el-row>
                            <el-col :span="7">
                                <el-form-item label="用户" prop="user" label-width="55px">
                                    <el-cascader 
                                        :options="userNameList" 
                                        @change="selectUser" 
                                        v-model="password.user" 
                                        :props="{ expandTrigger: 'hover' }" 
                                        :show-all-levels="false" 
                                        size="mini" 
                                        class="screenInput" 
                                        clearable>
                                    </el-cascader>
                                </el-form-item>
                            </el-col>
                            <el-col :span="17" v-if="password.firstType == '在线密码'">
                                <el-form-item>
                                    <el-select v-model="password.onlineSecondType" @change="clearPasswordDialog(2)" size="mini" class="screenInput">
                                        <el-option label="限次密码" value="限次密码"></el-option>
                                        <el-option label="限时密码" value="限时密码"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="17" v-else-if="password.firstType == '离线密码' && panelForm.devSecondType == 58">
                                <el-form-item>
                                    <el-select v-model="password.offlineSecondType" @change="clearPasswordDialog(1)" size="mini" class="screenInput">
                                        <el-option label="次数密码" :value="0"></el-option>
                                        <el-option label="时数密码" :value="1"></el-option>
                                        <el-option label="天数密码" :value="2"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="indefinitely" prop="offlineUnlockTimes" label-width="55px">
                                    <el-input v-model="password.offlineUnlockTimes" size="mini" @change="indefinitelyChange" class="screenInput"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row v-if="password.firstType == '在线密码'">
                            <el-form-item label="密码" prop="mima" label-width="45px">
                                <el-input v-model="password.mima" placeholder="6-10位数字" size="mini" class="screenInput"></el-input>
                            </el-form-item>
                            <el-form-item label="开锁次数" v-if="password.onlineSecondType == '限次密码'">
                                <el-input v-model="password.onlineUnlockTimes" placeholder="不填写则为永久有效" size="mini" class="screenInput"></el-input>
                            </el-form-item>
                            <el-form-item label="期限时间" v-if="password.onlineSecondType == '限时密码'">
                                <el-date-picker 
                                    v-model="password.onlineDeadline" 
                                    type="datetime" 
                                    placeholder="选择日期时间" 
                                    value-format="yyyy-MM-dd HH:00:00" 
                                    size="mini" 
                                    style="width:125px" 
                                    :picker-options="dateLimit">
                                </el-date-picker>
                            </el-form-item>
                        </el-row>
                        <el-row v-else-if="password.firstType == '离线密码' && panelForm.devSecondType == 58">
                            <el-row>
                                <span>
                                    <el-form-item label="生效时间" prop="offlineDate" label-width="85px">
                                        <el-date-picker 
                                            v-model="password.offlineDate" 
                                            type="date" 
                                            :disabled="effectTimeShow" 
                                            @change="effectDateChange" 
                                            placeholder="选择日期" 
                                            value-format="yyyy-MM-dd" 
                                            size="mini" 
                                            style="width:125px" 
                                            :picker-options="dateLimit">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item prop="offlineHours">
                                        <el-select 
                                            v-model="password.offlineHours" 
                                            :disabled="effectTimeShow" 
                                            placeholder="选择小时" 
                                            @change="changeHours" 
                                            size="mini" 
                                            style="width: 100px;" 
                                            class="none-icon"
                                        >
                                            <el-option 
                                                v-for="item in optionsHours" 
                                                :key="item.value" 
                                                :label="item.label" 
                                                :disabled="item.disabled" 
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item>:</el-form-item>
                                    <el-form-item prop="offlineMinutes">
                                        <el-select 
                                            v-model="password.offlineMinutes" 
                                            @change="changeFailureTime" 
                                            placeholder="选择分钟" 
                                            :disabled="showMinutes" 
                                            size="mini" 
                                            style="width: 100px;" 
                                            class="none-icon"
                                        >
                                            <el-option 
                                                v-for="item in optionsMinutes" 
                                                :key="item.value" 
                                                :label="item.label" 
                                                :disabled="item.disabled" 
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </span>
                            </el-row>
                            <el-row>
                                <span>
                                    <el-form-item label="失效时间" prop="failureDate" label-width="85px">
                                        <el-date-picker 
                                            v-model="password.failureDate" 
                                            disabled type="date" 
                                            placeholder="选择日期" 
                                            value-format="yyyy-MM-dd" 
                                            size="mini" 
                                            style="width:125px" 
                                            :picker-options="dateLimit">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item prop="failureHours">
                                        <el-select 
                                            v-model="password.failureHours" 
                                            disabled placeholder="选择小时" 
                                            size="mini" 
                                            style="width: 100px;" 
                                            class="none-icon"
                                        >
                                            <el-option 
                                                v-for="item in failureoptionsHours" 
                                                :key="item.value" 
                                                :label="item.label" 
                                                :disabled="item.disabled" 
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item>:</el-form-item>
                                    <el-form-item prop="failureMinutes">
                                        <el-select 
                                            v-model="password.failureMinutes" 
                                            disabled 
                                            placeholder="选择分钟" 
                                            size="mini" 
                                            style="width: 100px;" 
                                            class="none-icon"
                                        >
                                            <el-option 
                                                v-for="item in optionsMinutes" 
                                                :key="item.value" 
                                                :label="item.label" 
                                                :disabled="item.disabled" 
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </span>
                            </el-row>
                        </el-row>
                    </el-form>
                    <div slot="footer" style="text-slign: center;">
                        <el-button @click="dialogAddPassword = false">取 消</el-button>
                        <el-button type="primary" @click="addPasswordSave">确认授权</el-button>
                    </div>
                </el-dialog>
                <!--   添加密码 结束   -->
                <!--  门锁初始化 开始-->
                <el-dialog 
                    v-dialogDrag title='门锁初始化' 
                    :visible.sync="dialogDoorLock" 
                    class="DoorLock" 
                    :close-on-click-modal="false"
                    width="700px" 
                    append-to-body
                >
                    <div>
                        <p align="center">您本次获取的门锁初始化密码是</p>
                        <p align="center">{{initializePwd}}</p>
                        <p align="center" v-if="panelForm.devSecondType == 58">(每次初始化需重新生成，非长期固定密码)</p>
                        <p>使用帮助:</p>
                        <p>1、门锁通电情况下长按“#”键3秒，按键灯常亮，即进入初始化模式；</p>
                        <p>2、输入系统生成的初始化密码，按“#”键确认。</p>
                        <p v-if="panelForm.devSecondType == 58">以上步骤操作过程，门锁间隔1秒“滴..滴”响两声代表成功，连续响两声代表失败</p>
                        <div slot="footer" style="text-align: right;">
                            <el-button 
                                type="primary" 
                                v-if="this.panelForm.devSecondType == 87" 
                                @click="lockControl('注销所有用户IC卡与密码'),dialogDoorLock = false">
                                清空
                            </el-button>
                            <el-button type="primary" @click="dialogDoorLock = false">确认</el-button>
                        </div>
                    </div>
                </el-dialog>
                <!--  门锁初始化 结束-->
                <!--   添加注销IC卡 开始   -->
                <el-dialog 
                    v-dialogDrag 
                    :title="card.ICTitle" 
                    v-loading="card.loading" 
                    :visible.sync="dialogAddIC" 
                    @close="closeDialogAddPassword" 
                    :close-on-click-modal="false" 
                    width="600px" 
                    append-to-body
                >
                    <el-form inline :model="password" :rules="password.rules" ref="addPassword">
                        <el-row>
                            <el-col>
                                <el-form-item label="用户" prop="user" label-width="55px">
                                    <el-cascader 
                                        :options="userNameList" 
                                        @change="selectUser" 
                                        v-model="password.user" 
                                        :props="{ expandTrigger: 'hover' }" 
                                        :show-all-levels="false" 
                                        size="mini" 
                                        class="screenInput" clearable>
                                    </el-cascader>
                                </el-form-item>
                                <el-form-item>
                                    <el-select 
                                        v-model="password.offlineSecondType" 
                                        @change="clearPasswordDialog(1)" 
                                        size="mini" 
                                        class="screenInput"
                                    >
                                        <el-option label="次数门卡" :value="0"></el-option>
                                        <el-option label="时数门卡" :value="1"></el-option>
                                        <el-option label="天数门卡" :value="2"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="indefinitely" prop="offlineUnlockTimes" label-width="55px">
                                    <el-input 
                                        v-model="password.offlineUnlockTimes" 
                                        size="mini" 
                                        @change="indefinitelyChange" 
                                        class="screenInput">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-row>
                                <span>
                                    <el-form-item label="生效时间" prop="offlineDate">
                                        <el-date-picker 
                                            v-model="password.offlineDate" 
                                            type="date" 
                                            :disabled="effectTimeShow" 
                                            @change="effectDateChange" 
                                            placeholder="选择日期" 
                                            value-format="yyyy-MM-dd" 
                                            size="mini" 
                                            style="width:125px" 
                                            :picker-options="dateLimit">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item prop="offlineHours">
                                        <el-select 
                                            v-model="password.offlineHours" 
                                            :disabled="effectTimeShow" 
                                            placeholder="选择小时" 
                                            @change="changeHours" 
                                            size="mini" 
                                            style="width: 100px;" 
                                            class="none-icon"
                                        >
                                            <el-option 
                                                v-for="item in optionsHours" 
                                                :key="item.value" 
                                                :label="item.label" 
                                                :disabled="item.disabled" 
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item>:</el-form-item>
                                    <el-form-item prop="offlineMinutes">
                                        <el-select 
                                            v-model="password.offlineMinutes"
                                            @change="changeFailureTime" 
                                            placeholder="选择分钟" 
                                            :disabled="showMinutes" 
                                            size="mini" 
                                            style="width: 100px;" 
                                            class="none-icon"
                                        >
                                            <el-option 
                                                v-for="item in optionsMinutes" 
                                                :key="item.value" 
                                                :label="item.label" 
                                                :disabled="item.disabled" 
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </span>
                            </el-row>
                            <el-row>
                                <span>
                                    <el-form-item label="失效时间" prop="failureDate">
                                        <el-date-picker 
                                            v-model="password.failureDate"
                                            disabled 
                                            type="date" 
                                            placeholder="选择日期" 
                                            value-format="yyyy-MM-dd" 
                                            size="mini" 
                                            style="width:125px" 
                                            :picker-options="dateLimit">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item prop="failureHours">
                                        <el-select 
                                            v-model="password.failureHours" 
                                            disabled placeholder="选择小时" 
                                            size="mini" 
                                            style="width: 100px;" 
                                            class="none-icon"
                                        >
                                            <el-option 
                                                v-for="item in failureoptionsHours" 
                                                :key="item.value" 
                                                :label="item.label" 
                                                :disabled="item.disabled" 
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item>:</el-form-item>
                                    <el-form-item prop="failureMinutes">
                                        <el-select 
                                            v-model="password.failureMinutes" 
                                            disabled 
                                            placeholder="选择分钟" 
                                            size="mini" 
                                            style="width: 100px;" 
                                            class="none-icon"
                                        >
                                            <el-option 
                                                v-for="item in optionsMinutes" 
                                                :key="item.value" 
                                                :label="item.label" 
                                                :disabled="item.disabled" 
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </span>
                            </el-row>
                        </el-row>
                    </el-form>
                    <div slot="footer" style="text-slign: center;">
                        <el-button @click="dialogAddIC = false">取 消</el-button>
                        <!--  <el-button type="primary" @click="ICSave(card.ICTitle)">{{card.ICButton}}</el-button>-->
                        <el-button type="primary" @click="makeCard">确认授权</el-button>
                        <!--<el-popover
                    placement="top"
                    width="250"
                    v-model="cardState">
              <p>确认将{{offlinePassword}}写入卡ID为{{strCard}}的卡吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="cardState = false">取消</el-button>
                <el-button type="primary" size="mini" @click="confirmMakeCard">确定</el-button>
              </div>
                </el-popover>-->
                    </div>
                </el-dialog>
                <!--   添加注销IC卡 结束   -->
                <!--   门锁授权记录 开始  -->
                <el-dialog 
                    v-dialogDrag 
                    title="门锁授权记录" 
                    :visible.sync="authorizationRecord.dialogAuthorizationRecord" 
                    :close-on-click-modal="false" 
                    width="850px" 
                    append-to-body
                >
                    <el-table :data="authorizationRecord.dataList" height="403px" highlight-current-row>
                        <el-table-column prop="address" label="安装地址" min-width="100px"></el-table-column>
                        <el-table-column 
                            prop="jdcAuthorizeType" 
                            :formatter="formatAuthorizeType" 
                            label="授权类型" 
                            min-width="60px">
                        </el-table-column>
                        <el-table-column prop="jdcCardNum" label="卡号" min-width="60px"></el-table-column>
                        <el-table-column prop="jdcPassword" label="密码" min-width="60px"></el-table-column>
                        <el-table-column prop="jdcState" label="状态" min-width="40px"></el-table-column>
                        <el-table-column prop="jdcPublishTime" label="授权时间" min-width="100px"></el-table-column>
                    </el-table>
                    <el-pagination 
                        layout="total, sizes, prev, pager, next, jumper" 
                        :total="authorizationRecord.total" 
                        :page-size="authorizationRecord.limit" 
                        :page-count="authorizationRecord.pages" 
                        :current-page="authorizationRecord.cursor" 
                        @current-change="authorizationRecordCurrent" 
                        @size-change="authorizationRecordSize" 
                        :page-sizes="[5,10,20,40,100]" 
                        background 
                        align='center'>
                    </el-pagination>
                </el-dialog>
                <!--   门锁授权记录 结束  -->
                <!--   添加注销IC卡 开始   -->
                <el-dialog 
                    v-dialogDrag 
                    :title="card.ICTitle" 
                    :visible.sync="dialogIC" 
                    @close="closeDialogIC" 
                    :close-on-click-modal="false" 
                    width="600px" 
                    append-to-body
                >
                    <el-form inline :model="card" :rules="card.rules" ref="addCard">
                        <el-form-item label="用户" label-width="55px">
                            <el-cascader 
                                :options="userNameList" 
                                @change="selectICUser" 
                                v-model="card.user" 
                                :props="{ expandTrigger: 'hover' }" 
                                :show-all-levels="false" 
                                size="mini" 
                                class="screenInput" 
                                clearable>
                            </el-cascader>
                        </el-form-item>
                        <el-form-item :label="card.ICLabel" prop="doorCardId">
                            <el-input v-model="card.doorCardId" size="mini" class="screenInput"></el-input>
                            <el-button
                                icon="el-icon-bank-card"
                                size="mini"
                                @click="getCard"
                                style="margin-left:10px">读卡
                            </el-button>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" style="text-slign: center;">
                        <el-button @click="dialogIC = false">取 消</el-button>
                        <el-button type="primary" @click="ICSave()">{{card.ICButton}}</el-button>
                    </div>
                </el-dialog>
                <!--   添加注销IC卡 结束   -->
            </el-form>
            <!-- 窗帘 -->
            <el-form v-else-if="panelForm.curtain.indexOf(panelForm.devSecondType)>-1">
                <el-row>
                    <el-col :span="24" align="center">
                        <el-button type="primary" @click="openCurtain">开</el-button>
                        <el-button type="primary" @click="closeCurtain">关</el-button>
                        <el-button type="primary" @click="spendCurtain" style="margin-top:10px">暂停</el-button>
                        <el-button type="primary" @click="pairEq" v-if="panelForm.devSecondType!=164">配对</el-button>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" align="center">
                        <div class="block" style="width:70%">
                            <el-slider 
                                v-model="changestatus.percentCurtain" 
                                @change="percentChange" 
                                :step="10" 
                                :format-tooltip="formatTooltip" 
                                show-stops
                            >
                            </el-slider>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
            <!-- 开合窗帘 -->
			<el-form v-else-if="panelForm.curtain1.indexOf(panelForm.devSecondType) > -1">
				<el-row>
					<el-row :gutter="20">
						<el-col :span="24" style="text-align:center;margin-bottom:10px">
							<el-button type="primary" @click="openCurtain">开</el-button>
							<el-button type="primary" @click="spendCurtain">暂停</el-button>
							<el-button type="primary" @click="closeCurtain">关</el-button>
							<el-button type="primary" @click="corotationCurtain">正转</el-button>
							<el-button type="primary" @click="reversalcloseCurtain">反转</el-button>
							<el-button type="primary" @click="pairEq">配对</el-button>
						</el-col>
					</el-row>
					<el-row :gutter="30">
						<el-col :span="4" style="font-size:16px;text-align:center;margin-top:5px">
							转速调整
						</el-col>
						<el-col :span="16">
							<div class="block">
								<el-slider v-model="changestatus.percentCurtain"
									@change="percentChange"
									:step="10"
									:format-tooltip="formatTooltip"
									show-stops>
								</el-slider>
							</div>
						</el-col>
					</el-row>
				</el-row>
			</el-form>
            <!-- 智能窗帘控制器 -->
			<el-form v-else-if="panelForm.curtain2.indexOf(panelForm.devSecondType) > -1">
				<el-row>
					<el-row :gutter="20">
						<el-col :span="24" style="text-align:center;margin-bottom:10px">
							<el-button type="primary" @click="openCurtain">开</el-button>
							<el-button type="primary" @click="spendCurtain">暂停</el-button>
							<el-button type="primary" @click="closeCurtain">关</el-button>
						</el-col>
					</el-row>
				</el-row>
			</el-form>
            <el-form v-else-if="panelForm.curtain3.indexOf(panelForm.devSecondType) > -1">
				<el-row>
					<el-row :gutter="20">
						<el-col :span="24" style="text-align:center;margin-bottom:10px">
							<el-button type="primary" @click="openCurtain">高速风</el-button>
							<el-button type="primary" @click="spendCurtain">停</el-button>
							<el-button type="primary" @click="closeCurtain">低速风</el-button>
						</el-col>
					</el-row>
				</el-row>
			</el-form>
            <!-- 百分比窗帘 -->
            <w-racks 
                v-else-if="panelForm.clothesHorse.indexOf(panelForm.devSecondType) > -1"
                :id="this.form.clickrow.jourDeviceId"
            ></w-racks>
            <!-- 空调 -->
            <el-form v-else-if="panelForm.airConditioner.indexOf(panelForm.devSecondType)>-1">
                <AirConditioner
                @AirConditioner="airConditionerControl"
                :changestatus="changestatus" 
                :panelForm="panelForm" 
                :openLight="openLight"
                v-if="dialogCaoZuoSheBei"/>
                <!-- <el-form-item>
                    <el-col :span="7" style="margin:70px 0 0 20px">
                        <p 
                            style="font-size:60px;color:#3e8f3e;text-align:center;margin-bottom:10px"
                        >
                            {{changestatus.devAirTemperature}}
                            <span style="font-size:36px">℃</span>
                        </p>
                        <el-button 
                            @click="airTemperatureMinus" 
                            size="mini" 
                            type="primary" 
                            icon="el-icon-minus" 
                            style="margin:0px 0 0 40px">
                        </el-button>
                        <el-button 
                            @click="airTemperatureAdd" 
                            size="mini" 
                            type="primary" 
                            icon="el-icon-plus" 
                            style="margin:0px 0 0 40px">
                        </el-button>
                    </el-col>
                    <el-col :span="7" :offset="1" style="color:#3e8f3e">
                        <p>开关:&nbsp;&nbsp;{{changestatus.devState}}</p>
                        <p>室温:&nbsp;&nbsp;{{changestatus.nowStates.roomTemperature}}</p>
                        <p>模式:&nbsp;&nbsp;{{changestatus.devAirPattern}}</p>
                        <p>风速:&nbsp;&nbsp;{{changestatus.devAirWindSpeed}}</p>
                        <p 
                            v-if="panelForm.airConditioner1.indexOf(panelForm.devSecondType)>-1"
                        >
                            扫风:&nbsp;&nbsp;{{changestatus.devAirSweepingWind}}
                        </p>
                        <p 
                            v-if="panelForm.airConditioner3.indexOf(panelForm.devSecondType)>-1"
                        >
                            设备温度:&nbsp;&nbsp;{{changestatus.devAirTemperature2}}
                        </p>
                    </el-col>
                    <el-col 
                        :span="7" 
                        :offset="1" 
                        v-if="panelForm.airConditioner1.indexOf(panelForm.devSecondType)>-1" 
                        style="color:#3e8f3e"
                    >
                        <p>累计电量:&nbsp;&nbsp;{{changestatus.devAirElectricity}}</p>
                        <p>功率:&nbsp;&nbsp;{{changestatus.devAirPower}}</p>
                        <p>电压:&nbsp;&nbsp;{{changestatus.devAirVoltage}}</p>
                        <p>电流:&nbsp;&nbsp;{{changestatus.devAirCurrent}}</p>
                        <p>功率因数:&nbsp;&nbsp;{{changestatus.devAirFactors}}</p>
                        <p 
                            v-if="panelForm.airConditioner3.indexOf(panelForm.devSecondType)>-1"
                        >
                            上限温度:&nbsp;&nbsp;{{changestatus.devAirProtectTemperature}}
                        </p>
                    </el-col>
                </el-form-item>
                <el-form-item align="center">
                    <el-button type="primary" style="width:100px" @click="openLight"> 开 </el-button>
                    <el-button type="primary" style="width:100px" @click="closeLight"> 关 </el-button>
                    <el-button type="primary" @click="dialogMoShi=true" style="width:100px">模 式</el-button>
                    <el-button type="primary" @click="dialogFengSu=true" style="width:100px">风 速</el-button>
                    <el-button 
                        type="primary" 
                        v-if="panelForm.airConditioner2.indexOf(panelForm.devSecondType)>-1" 
                        @click="dialogSaoFeng=true" 
                        append-to-body 
                        style="width:100px"
                    >扫 风
                    </el-button>
                    <el-button type="primary" style="width:100px" @click="pairEq">配对</el-button>
                </el-form-item>
                <el-dialog v-dialogDrag title="模式" :visible.sync="dialogMoShi" :close-on-click-modal="false" width="200px" append-to-body>
                    <el-button type="primary" @click="airSelf" style="width:100%;margin:2px 0 2px -1px">自 动</el-button>
                    <el-button type="primary" @click="airCold" style="width:100%;margin:2px 0 2px -1px">制 冷</el-button>
                    <el-button type="primary" @click="airHot" style="width:100%;margin:2px 0 2px -1px">制 热</el-button>
                    <el-button type="primary" @click="airWet" style="width:100%;margin:2px 0 2px -1px" v-if="panelForm.devSecondType != 137">除 湿</el-button>
                    <el-button type="primary" @click="airWind" style="width:100%;margin:2px 0 2px -1px">送 风</el-button>
                </el-dialog>
                <el-dialog v-dialogDrag title="风速" :visible.sync="dialogFengSu" :close-on-click-modal="false" width="200px" append-to-body>
                    <el-button type="primary" style="width:100%;margin-left:-1px" @click="airWindSpeedSelf">自动</el-button>
                    <el-button type="primary" style="width:100%;margin:2px 0 2px -1px" @click="airWindSpeedHeight">高</el-button>
                    <el-button type="primary" style="width:100%;margin-left:-1px" @click="airWindSpeedMiddle">中</el-button>
                    <el-button type="primary" style="width:100%;margin:2px 0 0 -1px" @click="airWindSpeedLow">低</el-button>
                </el-dialog>
                <el-dialog v-dialogDrag title="扫风" :visible.sync="dialogSaoFeng" :close-on-click-modal="false" width="200px" append-to-body>
                    <el-button type="primary" style="width:100%;margin-left:-1px" @click="airSweepingWindSelf">自动</el-button>
                    <el-button type="primary" style="width:100%;margin:2px 0 0 -1px" @click="airSweepingWindHand">手动</el-button>
                </el-dialog> -->
            </el-form>
            <!-- 网关 -->
            <el-form v-else-if="panelForm.gatewayArr.indexOf(panelForm.devSecondType)>-1">
                <el-button type="primary" v-if="panelForm.devSecondType == 8" @click="gateWayControl('外出布防')">外出布防</el-button>
                <el-button type="primary" v-if="panelForm.devSecondType == 8" @click="gateWayControl('在家布防')">在家布防</el-button>
                <el-button type="primary" v-if="panelForm.devSecondType == 8" @click="gateWayControl('撤防')">撤防</el-button>
                <el-button type="primary" @click="getGateWayHangDevice">下挂设备</el-button>
                <el-button type="primary" v-if="panelForm.devFirstType == 32" @click="searchEquipment">搜索设备</el-button>
                <el-button type="primary" v-if="panelForm.devFirstType == 32" @click="stopTheSearch">停止搜索</el-button>
                <el-button type="primary" v-if="panelForm.devFirstType == 32" @click="pushToReport">推送上报</el-button>
                <el-dialog 
                    v-dialogDrag title="下挂设备" 
                    :visible.sync="dialogGateWayHangDevice" 
                    :close-on-click-modal="false" width="600px" 
                    height="420px" 
                    append-to-body
                >
                    <div style="text-align: center">
                        <el-table :data="gateWayHangDevice.dataList" highlight-current-row height="400px">
                            <el-table-column prop="devSn" label="设备SN码" min-width="100"></el-table-column>
                            <el-table-column prop="devNickname" label="设备名称" min-width="100"></el-table-column>
                        </el-table>
                        <!--<el-pagination
              background layout="total, sizes, prev, pager, next, jumper"
              :page-count="gateWayHangDevice.pages"
              :page-size="gateWayHangDevice.limit"
              :total="gateWayHangDevice.total"
              :current-page="gateWayHangDevice.cursor"
              @size-change="gateWaySizeChange"
              @current-change="gateWayCurrentChange"
              :page-sizes="[5,10,20,40,100]">
                </el-pagination>-->
                    </div>
                </el-dialog>
            </el-form>
            <!-- 灯=>开关 -->
            <el-form v-else-if="panelForm.lightSwitch.indexOf(panelForm.devSecondType)>-1">
                <el-form-item>
                    <el-button type="primary" @click="openLight">开灯</el-button>
                    <el-button type="primary" @click="closeLight">关灯</el-button>
                    <el-button type="primary" @click="pairEq">配对</el-button>
                </el-form-item>
            </el-form>
            <!-- 灯=>亮度 -->
            <el-form v-else-if="panelForm.lightSwitch2.indexOf(panelForm.devSecondType)>-1">
                <el-form-item>
                    <el-row>
                        <el-col :span="2" :offset='1'>
                            <el-button type="primary" @click="openLight">开灯</el-button>
                        </el-col>
                        <el-col :span="2" :offset='1'>
                            <el-button type="primary" @click="closeLight">关灯</el-button>
                        </el-col>
                        <el-col :span="2" :offset="1">
                            <el-button type="primary" @click="pairEq">配对</el-button>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="2" :offset='1'>
                            亮度:
                        </el-col>
                        <el-col :span="12">
                            <el-slider 
                                v-model="changestatus.nowStates.brightness" 
                                @change="openLight" 
                                :marks="{25: '25', 50: '50', 75: '75'}">
                            </el-slider>
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-form>
            <!-- 灯=>色温 -->
            <el-form v-else-if="panelForm.lightSwitch3.indexOf(panelForm.devSecondType)>-1">
                <el-form-item>
                    <el-row>
                        <el-col :span="2" :offset='1'>
                            <el-button type="primary" @click="openLight">开灯</el-button>
                        </el-col>
                        <el-col :span="2" :offset='1'>
                            <el-button type="primary" @click="closeLight">关灯</el-button>
                        </el-col>
                        <el-col :span="2" :offset="1">
                            <el-button type="primary" @click="pairEq">配对</el-button>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="2" :offset='5'>
                            亮度:
                        </el-col>
                        <el-col :span="12">
                            <el-slider 
                                v-model="changestatus.nowStates.brightness" 
                                @change="openLight" 
                                :marks="{25: '25', 50: '50', 75: '75'}">
                            </el-slider>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="2" :offset='5'>
                            色温:
                        </el-col>
                        <el-col :span="12">
                            <el-slider 
                                v-model="changestatus.nowStates.colorTemperature" 
                                @change="openLight" 
                                :marks="{25: '25', 50: '50', 75: '75'}">
                            </el-slider>
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-form>
            <!-- 灯=>RGB -->
            <el-form v-else-if="panelForm.lightSwitch4.indexOf(panelForm.devSecondType)>-1" class="lightRgb">
                <el-tabs v-model="GRBName" @tab-click="tabChange">
                    <el-tab-pane label="白光灯" name="first" v-if="panelForm.devSecondType !== 70">
                        <el-form-item>
                            <el-row>
                                <el-col :span="2">
                                    <el-button type="primary" @click="whiteLight">开灯</el-button>
                                </el-col>
                                <el-col :span="2" :offset='1'>
                                    <el-button type="primary" @click="closeLight">关灯</el-button>
                                </el-col>
                                <el-col :span="2" :offset="1">
                                    <el-button type="primary" @click="pairEq">配对</el-button>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="2" :offset='6'>
                                    亮度:
                                </el-col>
                                <el-col :span="12">
                                    <el-slider 
                                        v-model="changestatus.whiteBrightness"
                                        @change="whiteLight" 
                                        :marks="{25: '25', 50: '50', 75: '75'}">
                                    </el-slider>
                                </el-col>
                            </el-row>
                            <el-row v-if="panelForm.devSecondType == 68">
                                <el-col :span="2" :offset='6'>
                                    色温:
                                </el-col>
                                <el-col :span="12">
                                    <el-slider 
                                        v-model="changestatus.colorTemperature" 
                                        @change="openLight" 
                                        :marks="{25: '25', 50: '50', 75: '75'}">
                                    </el-slider>
                                </el-col>
                            </el-row>
                            <el-row v-if="panelForm.devSecondType == 68">
                                <el-col :span="2" :offset='6'>
                                    模式:
                                </el-col>
                                <el-col :span="12">
                                    <el-slider v-model="changestatus.whiteMode" max="5" @change="openLight"></el-slider>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-tab-pane>
                    <el-tab-pane label="彩光灯" name="second">
                        <el-row>
                            <el-col :span="12">
                                <el-button type="primary" @click="rbgLight">开灯</el-button>
                                <el-button type="primary" @click="closeLight">关灯</el-button>
                                <el-row style="height: 38px;">
                                    <el-col :span="3" style="line-height: 38px;">
                                        亮度:
                                    </el-col>
                                    <el-col :span="21">
                                        <el-slider 
                                            v-model="changestatus.rgbBrightness" 
                                            @change="rbgLight" 
                                            :marks="{25: '25', 50: '50', 75: '75'}">
                                        </el-slider>
                                    </el-col>
                                </el-row>
                                <el-row style="height: 38px;">
                                    <el-col :span="3" style="line-height: 38px;">
                                        速度:
                                    </el-col>
                                    <el-col :span="21">
                                        <el-slider v-model="changestatus.rgbSpeed" :max="15" @change="rbgLight"></el-slider>
                                    </el-col>
                                </el-row>
                                <el-row style="height: 38px;">
                                    <el-col :span="3" style="line-height: 38px;">
                                        模式:
                                    </el-col>
                                    <el-col :span="21">
                                        <el-slider v-model="changestatus.rgbMode" :max="5" @change="rbgLight"></el-slider>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="11" :offset='1'>
                                <el-color-picker 
                                    v-model="color3" 
                                    style="height: 320px!important;" 
                                    :predefine="predefineColors" 
                                    class="theme-picker" 
                                    popper-class="theme-picker-dropdown2" 
                                    color-format="rgb" 
                                    @change="colorChange">
                                </el-color-picker>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
            <!--  CW灯 -->
            <!-- 222 -->
            <el-form v-else-if="panelForm.lightSwitch5.indexOf(panelForm.devSecondType) > -1">
                <el-form-item>
                    <el-row>
                        <el-col :span="2" :offset="1">
                            <el-button type="primary" @click="openLight('开灯')">开灯</el-button>
                        </el-col>
                        <el-col :span="2" :offset="1">
                            <el-button type="primary" @click="closeLight">关灯</el-button>
                        </el-col>
                        <el-col :span="2" :offset="1">
							<el-button type="primary" @click="pairEq">配对</el-button>
						</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="2" :offset="5">
                            亮度:
                        </el-col>
                        <el-col :span="12">
                            <el-slider 
                                :max="10" 
                                :min="1" 
                                v-model="changestatus.nowStates.brightness" 
                                @change="openLight">
                            </el-slider>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="2" :offset="5">
                            色温:
                        </el-col>
                        <el-col :span="12">
                            <el-slider 
                                :max="10" 
                                :min="1" 
                                v-model="changestatus.nowStates.colorTemperature" 
                                @change="openLight">
                            </el-slider>
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-form>
            <!--  6200调光灯 -->
            <el-form v-else-if="panelForm.lightSwitch6.indexOf(panelForm.devSecondType) > -1">
                <el-form-item>
                    <el-row>
                        <el-col :span="2" :offset="1">
                            <el-button type="primary" @click="openLight('开灯')">开灯</el-button>
                        </el-col>
                        <el-col :span="2" :offset="1">
                            <el-button type="primary" @click="closeLight">关灯</el-button>
                        </el-col>
                        <el-col :span="2" :offset="1">
                            <el-button type="primary" @click="pairEq">配对</el-button>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="2" :offset="1">
                            亮度:
                        </el-col>
                        <el-col :span="12">
                            <el-slider 
                                :max="100" 
                                :min="1" 
                                v-model="changestatus.nowStates.brightness" 
                                @change="openLight" 
                                :marks="{25: '25', 50: '50', 75: '75'}">
                            </el-slider>
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-form>
            <!-- 2.4GRGB灯带 -->
            <el-form v-else-if="panelForm.lightSwitch7.indexOf(panelForm.devSecondType) > -1">
                <el-row>
                    <el-row style="height: 38px;">
                        <el-col :span="12" :offset="6" style="line-height: 38px;">
                            <el-button type="primary" @click="openTape">开灯</el-button>
                            <el-button type="primary" @click="closeTape">关灯</el-button>
                        </el-col>
                    </el-row>
                    <el-row style="height: 38px;">
                        <el-col :span="3" :offset="6" style="line-height: 38px;">
                            亮度:
                        </el-col>
                        <el-col :span="12">
                            <el-slider
                                v-model="changestatus.tapeBrightness"
                                @change="brightnessOpenTape"
                                :min="1"
                                :max="10"
                            ></el-slider>
                        </el-col>
                    </el-row>
                </el-row>
                <el-row>
                    <el-col :span="3" :offset="6">
                        灯光颜色:
                    </el-col>
                    <el-col :span="12">
                        <span class="tapeColor" @click="threeFn(1)"></span>
                        <span class="tapeColor" @click="threeFn(2)"></span>
                        <span class="tapeColor" @click="threeFn(3)"></span>
                        <span class="tapeColor" @click="threeFn(4)"></span>
                        <span class="tapeColor" @click="threeFn(5)"></span>
                        <span class="tapeColor" @click="threeFn(6)"></span>
                        <span class="tapeColor" @click="threeFn(7)"></span>
                        <span class="tapeColor" @click="threeFn(8)"></span>
                        <span class="tapeColor" @click="threeFn(9)"></span>
                        <span class="tapeColor" @click="threeFn(10)"></span>
                    </el-col>
                </el-row>
            </el-form>
            <!-- 人脸识别 -->
            <el-form v-else-if="panelForm.faceId.indexOf(panelForm.devFirstType)>-1">
                <el-button type="primary" @click="identify">识别记录</el-button>
                <el-button type="primary" @click="deleteIdentify">删除人脸设备</el-button>
                <el-button @click="restart" type="primary">重启</el-button>
                <el-button type="primary" @click="openDoor">一键开门</el-button>
                <el-button @click="magneticDoor" type="primary">绑定门磁</el-button>
                <el-button @click="deleteBind" type="primary">解除绑定</el-button>
                <!--        人脸识别 => 识别记录&门磁绑定-->
                <el-dialog 
                    v-dialogDrag 
                    title="识别记录" 
                    @close="identifyClose" 
                    :visible.sync="dialogIdentify" 
                    :close-on-click-modal="false" 
                    width="850px" 
                    append-to-body
                >
                    <el-form :model="faceForm">
                        <el-form-item>
                            人员：
                            <el-input 
                                @change="jftiPersonNameChange" 
                                v-model="faceForm.jftiPersonName" 
                                style="width:150px" 
                                size="mini" 
                                clearable>
                            </el-input>&nbsp;&nbsp;
                            时间：
                            <el-date-picker 
                                v-model="faceForm.time" 
                                type="daterange" 
                                align="right" 
                                size="mini" 
                                unlink-panels 
                                range-separator="至" 
                                start-placeholder="开始日期" 
                                end-placeholder="结束日期" 
                                @change="jftiPersonNameChange" 
                                value-format="yyyy-MM-dd" 
                                :picker-options="pickerOptions">
                            </el-date-picker>
                            比对结果：
                            <el-select 
                                v-model="faceForm.result" 
                                @change="identify" 
                                clearable 
                                placeholder="请选择" 
                                size="mini" 
                                style="width: 100px">
                                <el-option label="成功" value="1"></el-option>
                                <el-option label="失败" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <el-table :data="faceForm.faceIdList" highlight-current-row>
                        <el-table-column prop="jftiPasernType" label="人员类型" min-width="80px"></el-table-column>
                        <el-table-column prop="jftiPersonName" label="人员" min-width="80px"></el-table-column>
                        <el-table-column prop="deviceName" label="设备名称" min-width="80px"></el-table-column>
                        <el-table-column prop="jftiRecMode" label="识别模式" min-width="100px"></el-table-column>
                        <el-table-column prop="jfriShowTime" label="识别记录时间" min-width="160px"></el-table-column>
                        <el-table-column prop="jftiType" label="识别结果" min-width="80px"></el-table-column>
                        <el-table-column label="抓拍照片" min-width="40px">
                            <template slot-scope="scope">
                                <img :src="scope.row.jftiPhotoUrl" alt="" style="width:40px" @click.self="imgShow(scope.row.jftiPhotoUrl)">
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-dialog v-dialogDrag :visible.sync="dialogImg" :close-on-click-modal="false" append-to-body>
                        <img width="400px" :src="dialogImageUrl" alt="" style="display:block;margin:0 auto">
                    </el-dialog>
                    <el-pagination 
                        layout="prev, pager, next, total" 
                        :total="faceForm.total" 
                        :page-size="faceForm.limit" 
                        :page-count="faceForm.pages" 
                        :current-page="faceForm.cursor" 
                        @current-change="identifyCurrent" 
                        align="center" 
                        background 
                        style="margin-top:5px"
                    >
                    </el-pagination>
                </el-dialog>
                <el-dialog 
                    v-dialogDrag 
                    title="门磁绑定" 
                    @close="identifyClose" 
                    :visible.sync="dialogMagnetic" 
                    :close-on-click-modal="false" 
                    width="800px" 
                    append-to-body
                >
                    <el-table 
                        :data="faceForm.magneticList" 
                        highlight-current-row 
                        @selection-change="doorSelect" 
                        @select-all="doorSelect" 
                        ref="multipleTable"
                    >
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column prop="devNickname" label="设备名称" min-width="80px"></el-table-column>
                        <el-table-column prop="devSn" label="设备SN" min-width="80px"></el-table-column>
                        <el-table-column prop="hsAddCommunity" label="设备安装位置" min-width="100px"></el-table-column>n>
                    </el-table>
                    <div slot="footer">
                        <el-button @click="dialogMagnetic = false">取 消</el-button>
                        <el-button type="primary" @click="magneticSave">保 存</el-button>
                    </div>
                </el-dialog>
            </el-form>
            <!-- 光照传感器 -->
            <el-form v-else-if="panelForm.lightSensor.indexOf(panelForm.devSecondType)>-1">
                <el-button @click="minlumenFun" type="primary">最小流明</el-button>
                <el-button @click="maxlumenFun" type="primary">最大流明</el-button>
                <el-button @click="intervalLumenFun" type="primary">间隔流明</el-button>
                <el-button @click="reportedRecordFun" type="primary">上报记录</el-button>
                <el-button @click="bindingEquipment" type="primary">绑定设备</el-button>
                <el-button @click="diagramLumenFun" type="primary">流明曲线图</el-button>
                <!-- 光感传感器 -->
                <el-dialog 
                    v-dialogDrag 
                    :title="lightFeelTitle" 
                    :visible.sync="dialogLightFeel" 
                    width="710px" 
                    append-to-body 
                    :close-on-click-modal="false"
                >
                    <el-form v-if="lightFeelTitle == '下限流明'">
                        <el-row>
                            <el-col :span="24">
                                <el-form-item :label="lightFeelTitle" label-width="75px">
                                    <el-input 
                                        v-model="minlumen" 
                                        placeholder="请输入1-65535" 
                                        size="mini" 
                                        @change="minLumenChange">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <el-form v-else-if="lightFeelTitle == '上限流明'">
                        <el-row>
                            <el-col :span="24">
                                <el-form-item :label="lightFeelTitle" label-width="75px">
                                    <el-input 
                                        v-model="maxlumen" 
                                        placeholder="请输入1-65535" 
                                        size="mini" 
                                        @change="maxLumenChange">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <el-form v-else-if="lightFeelTitle == '间隔流明'">
                        <el-row>
                            <el-col :span="24">
                                <el-form-item :label="lightFeelTitle" label-width="75px">
                                    <el-input 
                                        v-model="intervalLumen"
                                        placeholder="请输入1-65535" 
                                        size="mini" 
                                        @change="spaceLumenChange">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <div slot="footer">
                        <el-button @click="dialogLightFeel = false">取 消</el-button>
                        <el-button type="primary" @click="lumenPost">保 存</el-button>
                    </div>
                </el-dialog>
                <!-- 光感传感器=>上报记录 -->
                <el-dialog 
                    v-dialogDrag title="上报记录" 
                    :visible.sync="dialogReportedRecord" 
                    width="800px" 
                    append-to-body 
                    :close-on-click-modal="false"
                >
                    查询：<el-select v-model="changestatus.type" size="mini" @change="reportedRecordFun">
                        <el-option label="最近三十条记录" :value="1"></el-option>
                        <el-option label="最近一天记录" :value="2"></el-option>
                    </el-select>
                    <!-- 内容表格 -->
                    <el-table :data="reportedRecordArr" highlight-current-row style="width:100%;">
                        <el-table-column prop="seDeviceSn" label="SN码" min-width="100px"></el-table-column>
                        <el-table-column prop="seLumen" label="流明值" min-width="80px"></el-table-column>
                        <el-table-column prop="seLowerLimitLumen" label="最小流明" min-width="80px"></el-table-column>
                        <el-table-column prop="seUpperLimitLumen" label="最大流明" min-width="80px"></el-table-column>
                        <el-table-column prop="seLumenSpacing" label="间隔流明" min-width="80px"></el-table-column>
                        <el-table-column prop="seTime" label="上报时间" min-width="120px"></el-table-column>
                    </el-table>
                </el-dialog>
                <!-- 光感传感器=>绑定设备 -->
                <el-dialog 
                    v-dialogDrag 
                    title="绑定设备" 
                    :visible.sync="dialogBindingEquipment" 
                    width="900px" 
                    append-to-body 
                    :close-on-click-modal="false"
                >
                    <el-row class="transferStyle">
                        <!--穿梭框-->
                        <el-col>
                            <el-transfer 
                                v-model="equipmentJourDeviceId" 
                                :props="{key: 'jourDeviceId',label: 'devNickname'}" 
                                :data="equipmentShuttleBox">
                            </el-transfer>
                        </el-col>
                    </el-row>
                    <div slot="footer">
                        <el-button @click="dialogBindingEquipment = false">取 消</el-button>
                        <el-button type="primary" @click="BindingEquipmentSave">保 存</el-button>
                    </div>
                </el-dialog>
                <!-- 光感流明曲线图 -->
                <el-dialog 
                    v-dialogDrag 
                    title="" 
                    :visible.sync="dialogDiagram" 
                    width="1000px" 
                    append-to-body 
                    :close-on-click-modal="false"
                >
                    <div id="myChart" style="width: 100%; height: 400px"></div>
                </el-dialog>
            </el-form>
            <!-- 红外报警器 -->
            <el-form v-else-if="panelForm.lnfraredAlarm.indexOf(panelForm.devFirstType)>-1">
                <el-button @click="infraredReport" type="primary">上报记录</el-button>
                <el-dialog 
                    v-dialogDrag 
                    title="上报记录" 
                    :visible.sync="infraredReportDialog"
                    width="800px" 
                    append-to-body 
                    :close-on-click-modal="false"
                >
                    <el-table :data="infraredReportArr" highlight-current-row style="width:100%;">
                        <el-table-column prop="saDeviceSn" label="SN码" min-width="100px"></el-table-column>
                        <el-table-column prop="saDeviceStatus" label="设备状态" min-width="80px"></el-table-column>
                        <el-table-column prop="saWarningStatus" label="报警状态" min-width="80px"></el-table-column>
                        <el-table-column prop="saReportingTime" label="上报时间" min-width="80px"></el-table-column>
                    </el-table>
                </el-dialog>
            </el-form>
            <!-- 电子门牌 -->
            <el-form v-else-if="panelForm.doorPlate.indexOf(panelForm.devFirstType)>-1">.
                <el-row style="margin-bottom:15px">
                    <el-button type="primary" @click="doorPlateControl(1, '开')">请勿打扰（开）</el-button>
                    <el-button type="primary" @click="doorPlateControl(1, '关')">请勿打扰（关）</el-button>
                    <el-button type="primary" v-if="panelForm.devSecondType == 53" @click="doorPlateControl(2, '开')">预约退房（开）</el-button>
                    <el-button type="primary" v-if="panelForm.devSecondType == 53" @click="doorPlateControl(2, '关')">预约退房（关）</el-button>
                    <el-button type="primary" v-if="panelForm.devSecondType == 19" @click="doorPlateControl(2, '开')">请稍后（开）</el-button>
                    <el-button type="primary" v-if="panelForm.devSecondType == 19" @click="doorPlateControl(2, '关')">请稍后（关）</el-button>
                </el-row>
                <el-row>
                    <el-button type="primary" @click="doorPlateControl(3, '开')">请即清理（开）</el-button>
                    <el-button type="primary" @click="doorPlateControl(3, '关')">请即清理（关）</el-button>
                </el-row>
            </el-form>
            <!-- 人脸抓拍 -->
            <Facecapture
                v-else-if="panelForm.faceSnapId.includes(panelForm.devFirstType)"
                :panelForm="panelForm"
                :enabled="enabled"
                :form="form"
            >
            </Facecapture>
            <!--背景音乐主机-->
            <el-form v-else-if="panelForm.backgroundMusic.includes(panelForm.devSecondType)">
                <BackgroundMusic
                    v-if="dialogCaoZuoSheBei" 
                    :isShow="dialogCaoZuoSheBei" 
                    :isRow="form.clickrow" 
                    :isState="this.changestatus.nowStates" 
                    >
                </BackgroundMusic>
            </el-form>
            <!-- 本地音乐播放器 -->
            <el-form v-else-if="panelForm.localhostMusic.includes(panelForm.devSecondType)">
                <LocalhostMusic v-if="dialogCaoZuoSheBei" :row="form.clickrow"/>
            </el-form>
            <!-- 插卡面板 -->
            <el-form inline v-else-if="panelForm.cardPanel.indexOf(panelForm.devSecondType) > -1">
                <el-button type="primary" @click="cardSet">插卡设置</el-button>
                <el-button type="primary">插卡（关）</el-button>
                <el-button type="primary">测试插卡</el-button>
                <el-button type="primary">拔出插卡</el-button>
                <el-dialog
                    title="单个设置情景模式"
                    :close-on-click-modal="false"
                    append-to-body
                    :visible.sync="cardPanelData.cardSetDialog"
                >
                    <el-tabs v-model="cardPanelData.cardSetTabPane" @tab-click="cardSetTabPaneClick">
                        <el-tab-pane label="情景模式" name="first">
                            情景模式
                        </el-tab-pane>
                        <el-tab-pane label="情景联动" name="second">
                            情景联动
                        </el-tab-pane>
                    </el-tabs>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="cardPanelData.cardSetDialog = false">取 消</el-button>
                        <el-button type="primary" @click="cardPanelData.cardSetDialog = false">提交</el-button>
                    </span>
                </el-dialog>
            </el-form>
            <!-- 6200光线传感器 -->
            <el-form v-else-if="panelForm.lightSensor1.indexOf(panelForm.devSecondType) > -1">
                <el-row>
                    <el-button @click="lightSensationBtns('下限流明')" type="primary">最小流明</el-button>
                    <el-button @click="lightSensationBtns('上限流明')" type="primary">最大流明</el-button>
                    <el-button @click="lightSensationBtns('流明间隔')" type="primary">间隔流明</el-button>
                    <el-button @click="reportedRecordFun('2')" type="primary">上报记录</el-button>
                    <el-button @click="bindingEquipment('2'),dialogBindingEquipment2=true" type="primary">绑定设备</el-button>
                    <el-dialog 
                        v-dialogDrag title="上报记录" 
                        :visible.sync="dialogReportedRecord2" 
                        width="800px" 
                        append-to-body 
                        :close-on-click-modal="false"
                    >
                        查询：<el-select v-model="changestatus.type" size="mini" @change="reportedRecordFun('2')">
                            <el-option label="最近三十条记录" :value="1"></el-option>
                            <el-option label="最近一天记录" :value="2"></el-option>
                        </el-select>
                        <!-- 内容表格 -->
                        <el-table :data="reportedRecordArr" highlight-current-row style="width:100%;">
                            <el-table-column prop="seDeviceSn" label="SN码" min-width="100px"></el-table-column>
                            <el-table-column prop="seLumen" label="流明值" min-width="80px"></el-table-column>
                            <el-table-column prop="seLowerLimitLumen" label="最小流明" min-width="80px"></el-table-column>
                            <el-table-column prop="seUpperLimitLumen" label="最大流明" min-width="80px"></el-table-column>
                            <el-table-column prop="seLumenSpacing" label="间隔流明" min-width="80px"></el-table-column>
                            <el-table-column prop="seTime" label="上报时间" min-width="120px"></el-table-column>
                        </el-table>
                    </el-dialog>
                    <!-- 光感传感器=>绑定设备 -->
                    <el-dialog 
                        v-dialogDrag title="绑定设备" 
                        :visible.sync="dialogBindingEquipment2" 
                        width="900px" 
                        append-to-body 
                        :close-on-click-modal="false"
                    >
                        <el-row class="transferStyle">
                            <!--穿梭框-->
                            <el-col>
                                <el-transfer 
                                    v-model="equipmentJourDeviceId" 
                                    :props="{ key: 'jourDeviceId', label: 'devNickname' }" 
                                    :data="equipmentShuttleBox">
                                </el-transfer>
                            </el-col>
                        </el-row>
                        <div slot="footer">
                            <el-button @click="dialogBindingEquipment2 = false">取 消</el-button>
                            <el-button type="primary" @click="BindingEquipmentSave">保 存</el-button>
                        </div>
                    </el-dialog>
                </el-row>
                <el-row style="margin-top: 10px;">
                    <el-button @click="lightSensationBtns('调光上限')" type="primary">调光上限</el-button>
                    <el-button @click="lightSensationBtns('调光下限')" type="primary">调光下限</el-button>
                    <el-button @click="lightSensationBtns('调光步长')" type="primary">调光步长</el-button>
                    <!-- <el-button type="primary">周期</el-button> -->
                    <el-button type="primary" @click="dialogEnabled=true">使能位</el-button>
                </el-row>
                <el-dialog 
                    v-dialogDrag 
                    title="使能位与周期" 
                    :visible.sync="dialogEnabled" 
                    width="710px" 
                    append-to-body 
                    :close-on-click-modal="false"
                >
                    <el-form class="enabled">
                        <el-row style="margin-top: 10px;margin-top: 10px;">
                            <el-row class="enabled" style=" padding: 10px 5px;">
                                <el-col :span="6" style="text-align: center">工作使能</el-col>
                                <el-col :span="6" style="text-align: center">本地调光使能</el-col>
                                <el-col :span="6" style="text-align: center">上传流明参数使能</el-col>
                                <el-col :span="6" style="text-align: center">周期 / 单位</el-col>
                            </el-row>
                            <el-row style="padding: 10px 5px">
                                <el-col :span="6" style="text-align: center">
                                    <el-switch 
                                        active-value="1" 
                                        inactive-value="0" 
                                        v-model="enabled.jobEnabled" 
                                        active-color="#13ce66" 
                                        inactive-color="#ff4949">
                                    </el-switch>
                                </el-col>
                                <el-col :span="6" style="text-align: center">
                                    <el-switch 
                                        active-value="1" 
                                        inactive-value="0" 
                                        v-model="enabled.digitalDimming" 
                                        active-color="#13ce66" 
                                        inactive-color="#ff4949">
                                    </el-switch>
                                </el-col>
                                <el-col :span="6" style="text-align: center">
                                    <el-switch 
                                        active-value="1" 
                                        inactive-value="0" 
                                        v-model="enabled.uploadingEnabled" 
                                        active-color="#13ce66" 
                                        inactive-color="#ff4949">
                                    </el-switch>
                                </el-col>
                                <el-col :span="6" style="text-align: center;display: flex;">
                                    <el-input-number 
                                        style="width: 90px;" 
                                        size="mini" 
                                        v-model="enabled.params.period" 
                                        controls-position="right" 
                                        :min="0" 
                                        :max="60">
                                    </el-input-number>
                                    <el-select 
                                        style="width: 90px;" 
                                        size="mini" 
                                        v-model="enabled.hourlyBasis" 
                                        clearable 
                                        placeholder="请选择" 
                                        @change="periodUnit()"
                                    >
                                        <el-option value="秒"></el-option>
                                        <el-option value="分"></el-option>
                                        <el-option value="时"></el-option>
                                    </el-select>
                                </el-col>
                            </el-row>
                        </el-row>
                    </el-form>
                    <div slot="footer">
                        <el-button @click="dialogEnabled = false">取消</el-button>
                        <el-button type="primary" @click="enabledReq">确定</el-button>
                    </div>
                </el-dialog>
                <SensorModule :datas="enabled" :SensorModuleShow="sensorModuleShow">
                </SensorModule>
            </el-form>
            <!-- 电箱 -->
            <electric-box 
                v-else-if="panelForm.electricBox.includes(panelForm.devSecondType) && dialogCaoZuoSheBei" 
                :isShow="panelForm.electricBox.includes(panelForm.devSecondType)" 
                :panelForm="form.clickrow">
            </electric-box>
            <!-- 6200电表 -->
            <el-form v-else-if="panelForm.ammeter2.indexOf(panelForm.devSecondType) > -1">
				<el-card class="box-card">
                    <div class="text item">
                        <el-row>
                            <el-col :span="12" style="font-size:15px;margin-bottom:10px">
                                <b>最小计量单位：</b> 
                                <el-tag 
                                    type="success" 
                                    style="min-width:100px;text-align:center"
                                >{{ammeterState.minUnitOfMeasurement}}
                                </el-tag>
                            </el-col>
                            <el-col :span="12" style="font-size:15px;margin-bottom:10px">
                                <b>电量计量总数：</b> 
                                <el-tag 
                                    type="success" 
                                    style="min-width:100px;text-align:center"
                                >{{ammeterState.totalElecPower}}
                            </el-tag>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
			</el-form>
            <!-- 6200墙面插座 -->
			<el-form v-else-if="panelForm.wallSocket.indexOf(panelForm.devSecondType) > -1">
                <div class='airConditioner'>
                   <div class="airHeader" style="text-align:center">
                        <p>设备已开启</p>
                        <div v-if="issocketShow" style="text-align:center;background:#63cf50;width: 100px;height: 100px;border-radius: 50%;margin: 0px auto;">
                            <svg width="100" height="100" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"/><path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" fill="#63cf50" stroke="#63cf50" stroke-width="4" stroke-linejoin="round"/><path d="M15 26L17 32" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M33 26L31 32" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M24 13V19" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div v-else style="text-align:center;background:#9b9c9d;width: 100px;height: 100px;border-radius: 50%;margin: 0px auto;">
                            <svg width="100" height="100" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"/><path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" fill="#9b9c9d" stroke="#9b9c9d" stroke-width="4" stroke-linejoin="round"/><path d="M15 26L17 32" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M33 26L31 32" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M24 13V19" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                   </div>
                    <div class="swButton">
                        <el-button size="mini" type="primary" @click="openLight">通电</el-button>
                        <el-button size="mini" type="primary" @click="closeLight">断电</el-button>
                    </div>
                    <div class="SWSelect">
                        <el-button size="mini" type="primary" @click="pairEq">配对</el-button>
                        <el-button size="mini" type="primary" @click="positioning">定位</el-button>
                    </div>
                </div>

				<!-- <el-button type="primary" @click="openLight">打开</el-button>
				<el-button type="primary" @click="closeLight">关闭</el-button>
                <el-button type="primary" @click="pairEq">配对</el-button> -->
			</el-form>
            <!-- 警戒机监测 -->
			<el-form v-else-if="panelForm.warningMachineMonitoring.indexOf(panelForm.devSecondType) > -1">
				<el-button type="primary" @click="modifyPushAddress">服务器地址设置</el-button>
                <el-button type="danger" @click="deleteFaceDevice">删除设备</el-button>
			</el-form>
            <!-- 电表 -功率计量盒 -->
            <meter-power-box v-else-if="panelForm.ammeter.indexOf(panelForm.devSecondType) > -1 || ammeterObj.isContinue"
                :ammeterObj ="ammeterObj"
                :es="dialogCaoZuoSheBei"
                :id ="form.clickrow.jourDeviceId"
                :devSecondType ="panelForm.devSecondType"
            ></meter-power-box>
            <!-- 4.12 新增检测仪 2233 -->
            <el-form v-else-if="panelForm.detector.indexOf(panelForm.devSecondType) > -1">
                <!-- 自定义事件 -->
                <Detector v-if="detectorCpnsFlag" :detectorData="detectorData" @FchangeDetectorData="changeDetectorData"/>
            </el-form>
            <!-- 单路隔离控制盒 -->
            <!-- 222 -->
            <el-form v-else-if="panelForm.controlBox.indexOf(panelForm.devSecondType) > -1">
                <ControlBox ref="ControlBox" :id="form.clickrow.jourDeviceId" :controlObj="controlObj" :pairEq="pairEq" :controlObjStting="controlObjStting" :positioning="positioning" ></ControlBox>
            </el-form>
            <!-- 梯控 -->
            <el-form v-else-if="panelForm.LadderControl.indexOf(panelForm.devSecondType) > -1">
                <el-form-item :rules="controlBoxRules" prop="">
                    当前电梯: <el-input size="mini" v-model="currentElevator" style="width:150px;margin-right:100px" :disabled="true"></el-input>
                    设备 Id: <el-input size="mini" v-model="deviceId" style="width:150px" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item :rules="controlBoxRules" prop="">
                    楼层板数: <el-input-number @change="keysNumChange" v-model="slabNum" size="mini" :min="1" :max="8" style="margin-right:100px;width:150px"></el-input-number>
                    开关按键: <el-select size="mini" style="width:150px" v-model="lightKeys" placeholder="请选择" @change="keysNumChange">
                        <el-option label="开/关门" value="2"></el-option>
                        <el-option label="开/关/常开门" value="3"></el-option>
                        <el-option label="不启用" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :rules="controlBoxRules" prop="">
                    地下楼层: <el-input-number size="mini" v-model="unFloor" style="width:150px;margin-right:100px" @change="keysNumChange" :min="0"></el-input-number>
                    楼层方式: <el-select size="mini" style="width:150px;" v-model="floorMode" placeholder="请选择" @change="IntervalClick">
                            <el-option label="单层" value="0"></el-option>
                            <el-option label="双层" value="1"></el-option>
                            <el-option label="顺层" value="2"></el-option>
                            <el-option label="间隔" value="3"></el-option>
                            <el-option label="层段" value="4"></el-option>
                        </el-select>
                    <!-- 点击层段 -->
                    <el-form-item  v-show="isInterval" style="width:220px;margin-left:380px">
                        <el-cascader
                            @change="keysNumChange"
                            v-show="isInterval"
                            v-model="interNum1"
                            :options="interNumList1"
                            :props="{ expandTrigger: 'hover'}"
                            size="mini" 
                            style="width:100px;margin-right:10px"
                            placeholder="开始"
                            >
                        </el-cascader>
                        <el-cascader
                            @change="keysNumChange"
                            v-model="interNum2"
                            v-show="isInterval"
                            :options="interNumList2"
                            :props="{ expandTrigger: 'hover'}"
                            size="mini" 
                            style="width:100px;margin-right:10px"
                            placeholder="结束"
                            >
                        </el-cascader>
                    </el-form-item>
                    <!-- 点击间隔 -->
                    <el-input v-show="isInterval2" size="mini" v-model="jiangeNum" style="margin-left:10px;width:120px" placeholder="请输入间隔数"></el-input>
                </el-form-item>
                <el-form-item :rules="controlBoxRules" prop="">
                    地面楼层: <el-input size="mini" v-model="groundFloor" style="width:150px;margin-right:100px" @change="keysNumChange" :disabled="true"></el-input>
                    键位数量: <el-input size="mini" v-model="keysNum" style="width:150px" :disabled="true"></el-input>
                </el-form-item>
                <el-dialog
                    width="500px"
                    title="预览信息"
                    :visible.sync="ispreview"
                    append-to-body>
                    <el-table @row-dblclick="dblPreview" :data="previewList.slice((currentPage-1)*pageSizePreview,currentPage*pageSizePreview)" border style="width: 100%">
                        <el-table-column
                          prop="keyId"
                          label="键位编号">
                        </el-table-column>
                        <el-table-column
                          prop="keyFunction"
                          label="键位楼层">
                        </el-table-column>
                        <el-table-column
                          prop="floorName"
                          label="楼层名称">
                        </el-table-column>
                    </el-table>
                    <el-pagination
                       @current-change="handleCurrentChange"
                       :current-page="currentPage" 
                       @size-change="handleSizeChange"
                       :page-sizes="[8,10,20]"
                       :page-size="pageSizePreview"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="totalPreview">
                    </el-pagination>
                    <el-button type="primary" @click="ladderSave=true">保存</el-button>
                    <el-dialog
                        title="是否打印"
                        :visible.sync="ladderSave"
                        width="30%"
                        append-to-body>
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="ladderSave = false">取 消</el-button>
                            <el-button type="primary" @click="ladderClick">确 定</el-button>
                        </span>
                    </el-dialog>
                    <el-dialog
                        title="编辑预览信息"
                        :visible.sync="updateLook"
                        width="30%"
                        append-to-body>
                        <!-- v-for="u in updateList" :key="u.id" -->
                        <el-form-item label="键位编号:">
                            <!-- v-model="u.keyId" -->
                            <el-input  v-model="upKeyId" size="mini" style="width:120px" :disabled="true"></el-input>
                        </el-form-item>
                        <el-form-item label="键位楼层:">
                            <el-input  v-model="upKeyFu" size="mini" style="width:120px" :disabled="keyFuDis"></el-input>
                        </el-form-item>
                        <el-form-item label="键位名称:">
                            <el-input  v-model="upKeyName" size="mini" style="width:120px"></el-input>
                        </el-form-item>
                        <el-button type="primary" @click="updateLook=false">取 消</el-button>
                        <el-button type="primary" @click="updateClick">确 定</el-button>
                    </el-dialog>
                </el-dialog> 
                <el-button type="primary" @click="previewClick" plain>预览</el-button>
            </el-form>
            <!-- 门锁 -->
            <el-form v-else-if="panelForm.longRangeDoorLock.indexOf(panelForm.devSecondType) > -1">
                <el-button type="primary" @click="longRangeDoorLockClick">远程开锁</el-button>
                <el-button type="primary" @click="unlockingRecordClick">事件记录</el-button>
                <el-dialog
                    title="事件记录"
                    :visible.sync="unlockingRecord"
                    width="60%"
                    append-to-body
                    >
                    门锁事件: 
                    <el-select size="mini" v-model="RecordsValue" placeholder="请选择" @change='recordsValueChange'>
                        <el-option
                            v-for="item in recordsValueList"
                            :key="item.id"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    事件时间: 
                    <el-date-picker
                        @change="recordsTimeChange"
                        v-model="recordsTime"
                        type="datetimerange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd HH:mm:ss"			
                        size="mini">
                    </el-date-picker>
                    <!-- unlockingRecordList.slice((RecordPage-1)*RecordSizePreview,RecordPage*RecordSizePreview) -->
                    <el-table
                        v-loading="loading"
                       :data="unlockingRecordList"
                       style="width: 100%">
                        <el-table-column
                         prop="recordsHsAddCommunity"
                         label="安装位置">
                       </el-table-column>
                        <el-table-column
                         prop="recordsName"
                         label="设备名称">
                       </el-table-column>
                       <el-table-column
                         prop="recordSN"
                         label="SN码">
                       </el-table-column>
                       <el-table-column
                         prop="dsrParsingState"
                         label="门锁事件">
                       </el-table-column>
                       <el-table-column
                         prop="dsrReportingTime"
                         label="操作时间">
                       </el-table-column>
                    </el-table>
                    <el-pagination
                        @size-change="RecordSizeChange"
                        @current-change="handleRecordChange"
                        :current-page="RecordPage"
                        :page-sizes="[5,10,20,50,100]"
                        :page-size="RecordSizePreview"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalPreviewRecord">
                    </el-pagination>
                </el-dialog>
            </el-form>
            <!-- 透传模块-->
            <el-form  v-else-if="panelForm.thoroughlyModule.indexOf(panelForm.devSecondType)>-1" >
                <ThoroughlyModule v-if="dialogCaoZuoSheBei" :sn="this.form.clickrow.devSn" :mac="this.form.clickrow.devAuthSecret" ></ThoroughlyModule>
            </el-form>
            <!-- 无操作 -->
            <el-form v-else>
                <p style="text-align:center">没有相关操作</p>
            </el-form>
        </el-dialog>
        <!--梯控--已配置楼层:楼层信息 -->
         <el-dialog
                title="楼层信息"
                :visible.sync="isfloorInfo"
                width="50%"
                append-to-body>
                <div style="">
                    <el-form>
                        <el-form-item>
                            楼层: <el-input  v-model="floorStateList.nowFloor" size="mini" style="width:80px" :disabled="true"></el-input>
                            前门: <el-input v-model="floorStateList.qianState" size="mini" style="width:80px" :disabled="true"></el-input>
                        </el-form-item>
                        <el-form-item>
                            后门: <el-input v-model="floorStateList.houState" size="mini" style="width:80px" :disabled="true"></el-input>
                            方向: <el-input v-model="floorStateList.operation" size="mini" style="width:80px" :disabled="true"></el-input>
                        </el-form-item>
                    </el-form>
                    <el-button @click.native="elevatorClick($event)" id='chengti'>乘 梯</el-button>
                    <el-button @click.native="elevatorClick($event)" id='kaifang'>开 放</el-button>
                    <!-- 点击乘梯开放按钮 -->
                    <el-dialog
                        :title="floorTitle"
                        :visible.sync="isElevator"
                        width="50%"
                        append-to-body>
                        <el-button @click="frontDoor($event)" id='qianmeng'>前门</el-button>
                        <el-button  @click="backDoor($event)" id='houmeng'>后门</el-button>
                        <div v-show="isOpenRight">
                            请选择秒数(默认不启用) : <el-cascader
                            v-model="openTime"
                            :options="openTimeList"
                            :props="{ expandTrigger: 'hover'}"
                            size="mini" 
                            style="width:100px"
                            placeholder="1-120之间"
                            >
                        </el-cascader>
                        </div>
                        <div><el-button v-show='isFloorEleList' @click="floorListClick(item)" style="margin:10px" round v-for="item in floorInfoList" :key="item.id">{{item}}</el-button></div>
                        <div><el-button v-show='isFloorOpenList' @click="floorOpenListClick(i)" style="margin:10px;" round v-for="i in floorInfoList" :key="i.id">{{i}}</el-button></div>
                        <el-button type="primary" @click="floorOpenClick" v-show="isOpenRight">确 定</el-button>
                    </el-dialog>
                    <div style="margin:10px 0px">
                        <el-button type="primary" @click="floorState">状 态</el-button>
                        <el-button type="primary" @click="floorInfoClick">修 改</el-button>
                        <el-button type="primary" @click="floorSetUpClick">设 置</el-button>
                        <el-button type="primary" @click="floorOutboundClick">外 呼</el-button>
                    </div>
                </div>  
                <!-- 设置按钮 -->
                 <el-dialog
                    title="设置"
                    :visible.sync="isfloorSetUp"
                    width="40%"
                    append-to-body>
                    <!-- <el-button @click.native="setUpClick($event)" id='kaifang'>开 放</el-button>
                    <el-button @click.native="setUpClick($event)" id='quanxian'>权 限</el-button>
                    <el-button @click.native="setUpClick($event)" id='jinyon'>禁 用</el-button> -->
                    <el-select v-model="selectControlModel" placeholder="选择控制模式">
                        <el-option
                            v-for="item in this.controlModel"
                            :key="item.value"
                            :label="item.value"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <div class="controlModel">
                        <div v-if='this.selectControlModel == "梯控模式"'><b>梯控模式 :</b> 进入梯控状态，刷卡、刷脸等可以使用电梯.</div>
                        <div v-if='this.selectControlModel == "开放模式"'><b>开放模式 :</b> 退出梯控状态，不刷卡，不输人脸也可以使用电梯.</div>
                        <div v-if='this.selectControlModel == "系统控制"'><b>系统控制 :</b> 强制接管，进制刷卡和按键.</div>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button type="primary" size='medium' style="margin-top:5px;" @click='setUpClick()'>保存</el-button>
                    </span>
                   
                 </el-dialog>   
                  <!--外呼按钮  -->
                 <el-dialog
                    title="外呼"
                    :visible.sync="isfloorOutbound"
                    width="500px"
                    class='controlEl'
                    append-to-body>
                    <div>
                        <el-button @click="frontDoor($event)" id='qianmeng'>前门</el-button>
                        <el-button  @click="backDoor($event)" id='houmeng'>后门</el-button>
                    </div>
                        <el-select v-model="currentFloor"  size="mini" placeholder="选择您所在的当前层数">
                            <el-option
                                v-for="item in this.floorNumberList"
                                :key="item.value"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                    <div>
                        <el-button size="mini" type="primary" @click="controlElevator(0)" icon="el-icon-top" round></el-button>
                        <el-button size="mini" type="primary" @click="controlElevator(1)" icon="el-icon-bottom" round></el-button>
                    </div>
                 </el-dialog>       
        </el-dialog>

        <!-- 地暖温控器 -->
        <el-dialog v-dialogDrag title="操作窗口" v-loading="floorHeating.loading" :visible.sync="dialogFloorHeating" :close-on-click-modal="false" width="450px" append-to-body>
            <el-row style="text-align:center">
                <el-radio-group v-model="floorHeating.modeButton" size="medium">
                    <el-radio-button label="室温模式">室温模式</el-radio-button>
                    <el-radio-button label="地面模式">地面模式</el-radio-button>
                    <el-radio-button label="时间模式">时间模式</el-radio-button>
                </el-radio-group>
            </el-row>
            <el-form v-if="floorHeating.modeButton == '室温模式'">
                <el-row style="text-align:center">
                    <p style="font-size:12px;color:#4BA24B">设定温度（℃）</p>
                    <div style="font-size:48px;font-weight:500;color:#4BA24B">{{floorHeating.floorHeatingState.roomTemp}}</div>
                    <hr width="20%" />
                    <p style="font-size:12px;color:#4BA24B">检测温度： {{floorHeating.floorHeatingState.detectionRoomTemp}} ℃</p>
                </el-row>
                <el-row style="text-align:center">
                    <el-button @click="floorHeatingControl('workingMode')" type="primary" style="width:120px" size="medium" round>启 动</el-button>
                </el-row>
            </el-form>
            <el-form v-else-if="floorHeating.modeButton == '地面模式'">
                <el-row style="text-align:center">
                    <p style="font-size:12px;color:#4BA24B">设定温度（℃）</p>
                    <div style="font-size:48px;font-weight:500;color:#4BA24B">{{floorHeating.floorHeatingState.landTemp}}</div>
                    <hr width="20%" />
                    <p style="font-size:12px;color:#4BA24B">检测温度： {{floorHeating.floorHeatingState.detectionLandTemp}} ℃</p>
                </el-row>
                <el-row style="text-align:center">
                    <el-button @click="floorHeatingControl('workingMode')" type="primary" style="width:120px" size="medium" round>启 动</el-button>
                </el-row>
            </el-form>
            <el-form v-else>
                <el-row style="text-align:center">
                    <p style="font-size:12px;color:#4BA24B">工作档位（T）</p>
                    <div style="font-size:48px;font-weight:500;color:#4BA24B">{{floorHeating.floorHeatingState.currentTimeGear}}</div>
                    <hr width="20%" />
                    <p style="font-size:12px;color:#4BA24B">限定档位： {{floorHeating.floorHeatingState.limitGear}} 档</p>
                </el-row>
            </el-form>
            <el-row style="text-align:center; margin-top:15px">
                <el-col :span="8">
                    <div style="font-size:19px;font-weight:200">{{floorHeating.floorHeatingState.heatingState}}</div>
                    <div style="color: #909399">加热开关</div>
                </el-col>
                <el-col :span="8">
                    <div style="font-size:19px;font-weight:200">{{floorHeating.floorHeatingState.workingMode}}</div>
                    <div style="color: #909399">当前运行模式</div>
                </el-col>
                <el-col :span="8">
                    <div style="font-size:19px;font-weight:200">{{floorHeating.floorHeatingState.alarmStatus}}</div>
                    <div style="color: #909399">当前报警模式</div>
                </el-col>
            </el-row>
            <el-row style="text-align: center;margin-top: 15px">
                <el-button :type="floorHeating.switchButtonType" style="width:120px" size="medium" @click="floorHeatingControl('switch')">开关（{{floorHeating.floorHeatingState.switchingState}}）</el-button>
                <el-button :type="floorHeating.lockButtonType" style="width:120px" size="medium" @click="floorHeatingControl('lock')">设备锁（{{floorHeating.floorHeatingState.deviceLock}}）</el-button>
                <el-button type="info" style="width:120px" size="medium" @click="dialogFloorHeatingSetUp=true">设 置</el-button>
                <el-dialog v-dialogDrag title="设置" :visible.sync="dialogFloorHeatingSetUp" :close-on-click-modal="false" width="520px" append-to-body>
                    <el-form label-width="170px" class="floorHeatingForm">
                        <span style="font-size: 17px;font-weight: 200">模式</span>
                        <el-divider content-position="left"></el-divider>
                        <el-form-item label="室内温度">
                            <el-col :span="17">
                                <el-input v-model="floorHeating.floorHeatingState.roomTemp" size="small" clearable style="width: 50%;"></el-input>（℃）<span style="color: #909399">5~30</span>
                            </el-col>
                            <el-button type="primary" size="small" @click="floorHeatingControl('roomTemp')">保 存</el-button>
                        </el-form-item>
                        <el-form-item label="地面温度">
                            <el-col :span="17">
                                <el-input v-model="floorHeating.floorHeatingState.landTemp" size="small" clearable style="width: 50%"></el-input>（℃）<span style="color: #909399">5~80</span>
                            </el-col>
                            <el-button type="primary" size="small" @click="floorHeatingControl('landTemp')">保 存</el-button>
                        </el-form-item>
                        <el-form-item label="时间档位">
                            <el-col :span="17">
                                <el-input v-model="floorHeating.floorHeatingState.currentTimeGear" size="small" clearable style="width: 50%"></el-input>（T）<span style="color: #909399">{{floorHeating.floorHeatingState.timeGearRange}}</span>
                            </el-col>
                            <el-button type="primary" size="small" @click="floorHeatingControl('currentTimeGear')">保 存</el-button>
                        </el-form-item>
                        <span style="font-size: 17px;font-weight: 200">通用</span>
                        <el-divider content-position="left"></el-divider>
                        <el-form-item label="设备防冻">
                            <el-col :span="17">
                                <el-switch v-model="floorHeating.floorHeatingState.antifreezeFunctionSwitch" active-color="#13ce66" inactive-color="#ccc" style="margin-left: 35px;"></el-switch>
                            </el-col>
                            <el-button type="primary" size="small" @click="floorHeatingControl('antifreezeFunctionSwitch')">保 存</el-button>
                        </el-form-item>
                        <el-form-item label="地面温度上限值">
                            <el-col :span="17">
                                <el-input v-model="floorHeating.floorHeatingState.temperatureUpperLimit" size="small" clearable style="width: 50%;"></el-input>（℃）<span style="color: #909399">21~80</span>
                            </el-col>
                            <el-button type="primary" size="small" @click="floorHeatingControl('temperatureUpperLimit')">保 存</el-button>
                        </el-form-item>
                        <el-form-item label="室内和地面温度下限值">
                            <el-col :span="17">
                                <el-input v-model="floorHeating.floorHeatingState.temperatureLowerLimit" size="small" clearable style="width: 50%;"></el-input>（℃）<span style="color: #909399">-9~22</span>
                            </el-col>
                            <el-button type="primary" size="small" @click="floorHeatingControl('temperatureLowerLimit')">保 存</el-button>
                        </el-form-item>
                        <el-form-item label="限制档位">
                            <el-col :span="17">
                                <el-select v-model="floorHeating.floorHeatingState.limitRange" size="small" clearable style="width: 50%;">
                                    <el-option label="1档" :value="0"></el-option>
                                    <el-option label="2档" :value="1"></el-option>
                                    <el-option label="3档" :value="2"></el-option>
                                </el-select>
                            </el-col>
                            <el-button type="primary" size="small" @click="floorHeatingControl('limitRange')">保 存</el-button>
                        </el-form-item>
                        <el-form-item label="开与停温差">
                            <el-col :span="17">
                                <el-select v-model="floorHeating.floorHeatingState.openAndStopTemperatureDifference" size="small" clearable style="width: 50%;">
                                    <el-option label="2" value="2"></el-option>
                                    <el-option label="3" value="3"></el-option>
                                    <el-option label="4" value="4"></el-option>
                                    <el-option label="5" value="5"></el-option>
                                </el-select>（℃）
                            </el-col>
                            <el-button type="primary" size="small" @click="floorHeatingControl('openAndStopTemperatureDifference')">保 存</el-button>
                        </el-form-item>
                        <el-form-item label="温度保护探头">
                            <el-col :span="17">
                                <el-input v-model="floorHeating.floorHeatingState.temperatureProtectionProbe" size="small" clearable style="width: 50%;"></el-input>（℃）<span style="color: #909399">60~80</span>
                            </el-col>
                            <el-button type="primary" size="small" @click="floorHeatingControl('temperatureProtectionProbe')">保 存</el-button>
                        </el-form-item>
                        <el-form-item label="温度探头误差">
                            <el-col :span="17">
                                <el-select v-model="floorHeating.floorHeatingState.temperatureProbeError" size="small" clearable style="width: 50%;">
                                    <el-option v-for="(item,i) in temperatureError" :key="i" :label="item" :value="item"></el-option>
                                </el-select>（℃）
                            </el-col>
                            <el-button type="primary" size="small" @click="floorHeatingControl('temperatureProbeError')">保 存</el-button>
                        </el-form-item>
                        <el-form-item label="继电器开到闭合最短时间">
                            <el-col :span="17">
                                <el-input v-model="floorHeating.floorHeatingState.minimumTimeForRelayToOpen" size="small" clearable style="width: 50%;"></el-input>（秒）<span style="color: #909399">0~60</span>
                            </el-col>
                            <el-button type="primary" size="small" @click="floorHeatingControl('minimumTimeForRelayToOpen')">保 存</el-button>
                        </el-form-item>
                    </el-form>
                    <div slot="footer">
                        <el-button type="primary" size="small" @click="floorHeatingControl('resumeFactory')">恢复出厂设置</el-button>
                    </div>
                </el-dialog>
            </el-row>
        </el-dialog>

        <!-- 插卡面板 -->
        <el-dialog v-dialogDrag title="插卡记录" :visible.sync="dialogPlugInCard" @close="closeDialogPlugInCard" :close-on-click-modal="false" width="1050px" append-to-body>
            <el-table :data="plugInCardRecords.dataList" height="403px" highlight-current-row>
                <el-table-column prop="popName" label="人员" min-width="60px"></el-table-column>
                <el-table-column prop="devNickname" label="设备名称" min-width="80px"></el-table-column>
                <el-table-column prop="devAddress" label="设备安装地址" min-width="100px"></el-table-column>
                <el-table-column prop="jpicRecordType" label="插卡类型" min-width="50px"></el-table-column>
                <el-table-column prop="jpicType" label="取电类型" min-width="60px"></el-table-column>
                <el-table-column prop="jpicResult" label="取电结果" min-width="60px"></el-table-column>
                <el-table-column prop="jpicBeginTime" label="插卡时间" width="157px"></el-table-column>
                <el-table-column prop="jpicEndTime" label="拔卡时间" width="157px"></el-table-column>
            </el-table>
            <el-pagination layout="total, sizes, prev, pager, next, jumper" :total="plugInCardRecords.total" :page-size="plugInCardRecords.limit" :page-count="plugInCardRecords.pages" :current-page="plugInCardRecords.cursor" @current-change="plugInCardCurrent" @size-change="plugInCardSize" :page-sizes="[5,10,20,40,100]" background align='center'>
            </el-pagination>
        </el-dialog>
        <!-- 门锁历史记录 -->
        <el-dialog v-dialogDrag title="门锁历史记录" :visible.sync="dialogGateLockRecords" @close="closeDialogGateLockRecords" :close-on-click-modal="false" width="850px" append-to-body>
            <el-table :data="gateLockRecords.dataList" height="403px" highlight-current-row>
                <el-table-column prop="unlockPeople" label="人员" min-width="60px"></el-table-column>
                <el-table-column prop="devNickname" label="设备名称" min-width="60px"></el-table-column>
                <el-table-column prop="devAddress" label="设备安装地址" min-width="100px"></el-table-column>
                <el-table-column prop="jglrRecordType" label="上报信息类型" min-width="70px"></el-table-column>
                <el-table-column prop="jglrTime" label="时间" width="160px"></el-table-column>
            </el-table>
            <el-pagination layout="total, sizes, prev, pager, next, jumper" :total="gateLockRecords.total" :page-size="gateLockRecords.limit" :page-count="gateLockRecords.pages" :current-page="gateLockRecords.cursor" @current-change="gateLockCurrent" @size-change="gateLockSize" :page-sizes="[5,10,20,40,100]" background align='center'>
            </el-pagination>
        </el-dialog>

        <!-- 服务器地址设置 -->
        <el-dialog v-dialogDrag :visible.sync="modifyPush.dialog" v-loading="loading5" :close-on-click-modal="false" title="服务器地址设置" width="480px" @close="closeModifyPushDialog" append-to-body>
            <el-form ref="modifyPush" :model="modifyPush" label-width="90px">
                <el-form-item label="服务器地址" prop="url" style="margin-bottom:20px" :rules="{required: true, message: '服务器地址不能为空', trigger: 'blur'}">
                    <el-input v-model="modifyPush.url" style="width:300px;"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="saveServerAddress">保存</el-button>
                    <el-button @click="modifyPush.dialog = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import debounce from 'lodash/debounce'
import * as face from "@/assets/interface";
import { dateFtt } from "@/assets/utils"
import { Power } from '@icon-park/vue'
const WSocket = () => ({component: import('./components/socket'),})
const BackgroundMusic = () => ({component: import('./components/musicControl/backgroundMusic.vue'),})
const LocalhostMusic = () => ({component: import('./components/musicControl/localhostMusic.vue'),})
const SensorModule = () => ({component: import('./components/sensorModule'),})
const ElectricBox = () => ({component: import('./components/electricBox'),})
const meterPowerBox = () => ({component: import('./components/meterPowerBox'),})
const Detector = () => ({component: import('./components/detector'),})
const Facecapture = () => ({component: import('./components/facecapture'),})
const WRacks = () => ({component: import('./components/Racks'),})
const selectAddress = () => ({component: import('../../components/selectAddress/selectAddress.vue'),})
const InstallationAddress = () => ({component: import('../../components/equipmentChild/installationAddress.vue'),})
const PairTable = () => ({component: import('./components/pairTable'),})
const ControlBox = () => ({component: import('./components/controlBox/controlBox.vue'),})
const ThoroughlyModule = () => ({component: import('./components/thoroughlyModule/thoroughlyModule.vue'),})
const ChamberlainScene = () => ({component: import('./components/chamberlainScene/chamberlainScene.vue'),})
const AirConditioner = () => ({component: import('./components/airConditioner/airConditioner.vue'),})
const RadarSensor = () => ({component: import('./components/radarSensor/index.vue'),})
const DoorLock = () => ({component: import('./components/doorLock/index.vue'),})
const WaterMeter = () => ({component: import('./components/WaterMeter/index.vue'),})
const Historys = () => ({component: import('./components/Hydropower/historys'),})
const Sections = () => ({component: import('./components/Hydropower/sections'),})
const DeviceEarlWarning = () => ({component: import('./components/Hydropower/deviceEarlWarning'),})
import { getAllSceneCategory,sceneLinkageInfo,sceneLinkageSave,sceneModeControl,sceneLinkageAddSceneCode,linkageGetLinkageScene,locationControl } from '@/api/scene.js'
import { autheEquipmentListMixin } from '@/mixins/autheMixins'
import boxItemVue from './components/electricBox/ElectricControl/boxItem.vue';
var obj = face.embed_reader.getOBJ(face.READER_TYPE._reader_type_contactLess);
var icdev = -1;
export default {
    name: 'EquipmentTable',
    mixins: [autheEquipmentListMixin],
    components: {
        WSocket,
        BackgroundMusic,
        LocalhostMusic,
        SensorModule,
        ElectricBox,
        meterPowerBox,
        Detector,
        Facecapture,
        WRacks,
        selectAddress,
        InstallationAddress,
        PairTable,
        Power,
        ControlBox,
        ThoroughlyModule,
        ChamberlainScene,
        AirConditioner,
        RadarSensor,
        DoorLock,
        WaterMeter,
        Historys,
        Sections,
        DeviceEarlWarning
    },
    props: {
        form: {
            type: Object
        },
    },
    directives: {
        xr: {
        // 指令的定义
        inserted: function (el) {
          el.firstChild.firstChild.firstChild.style.stroke="red"
        }
    }
  },
    data() {
        return {
            ecDayData:[],
            ecMonthData:[],
            ecYearData:[],
            deviceDatas:[],
            devicesId:"",
            radarStatus:"",
            hydropowers:"",
            latsReading:"",
            thisReading:"",
            dialogHistorysVisible: false,
            dialogSectionsVisible: false,
            dialogDeviceVisible:false,
            // =====================
            setIntervalAir:'',
            isOpenOrClose:false,//空调控制面板开/关
            isSWShow:false,//开关
            issocketShow:false,//插座
            time:null,
            recordsValueList:[],//开锁记录-门锁事件数据
            recordsTime:[],//开锁记录-事件时间 筛选
            RecordsValue:'',//开锁记录-门锁事件 筛选
            recordsHsAddCommunity:'',//开锁记录-安装位置
            recordsName:'',//开锁记录-设备名称
            RecordPage:1,//开锁记录当前页数
            RecordSizePreview:5,//开锁记录每页条数
            totalPreviewRecord:0,//开锁记录总条数
            floorJson:{},//
            openTime:'',//开放按钮选择秒数
            unlockingRecordList:[],//门锁 开锁记录
            floorOpenNum:[],//已编辑楼层-开放按钮-按键num
            floorStateList:{},//已编辑楼层-状态按钮-数据
            floorTitle:'',//已编辑楼层-点击乘梯或者开放按钮-标题
            upKeyId:'',//编辑预览-键位编号
            upKeyFu:'',//编辑预览-键位楼层
            upKeyName:'',//编辑预览-键位名称
            frontDoorNum:'前门',
            jiangeNum:0,//点击间隔
            interNum1:1,
            interNum2:1,
            interNumList1:[],//层段数1
            interNumList2:[],//层段数2
            previewList:[],//预览数据
            floorInfoList:[],//已配置楼层:楼层信息List
            floorNumberList:[],//已配置楼层:楼层数信息
            openTimeList:[],//开放按钮选择秒数
            //interlist:1,//点击层段的加减按钮
            slabNum:1,//楼层板数量
            lightKeys:"0",//可用开关键
            floorMode:"2",//楼层方式
            isInterval:false,//点击层段是否显示
            unFloor:0,//地下总楼层
            groundFloor:8,//地面总楼层
            keysNum:8,//可用键位数
            currentElevator:'',//当前电梯
            deviceId:'',//设备id
            isfloorSetUp:false,//已配置楼层:楼层信息-设置按钮弹出框
            ispreview:false,//预览弹出层显示
            isInterval2:false,//点击间隔是否显示
            ladderSave:false,//预览中点击保存
            updateLook:false,//编辑预览信息
            isfloorInfo:false,//配置楼层信息显示
            isElevator:false,//点击乘梯或者开放显示
            isOpenRight:false,//点击开放确定按钮显示
            isFloorEleList:false,//乘梯按键显示
            isFloorOpenList:false,//开放按键显示
            isfloorOutbound:false,//已配置楼层:楼层信息-外呼按钮弹出框
            keyFuDis:true,//编辑预览信息键位功能是否禁用
            unlockingRecord:false,//门锁 - 点击开锁记录
            totalPreview:0,//预览总条数
            pageSizePreview:8,//梯控-预览页数
            currentPage:1,//梯控-当前页数
            ws:null,
            powerStateset:'',
            // 控制检测仪组件 4.12
            controlModel:[{value:'梯控模式'},{value:'开放模式'},{value:'系统控制'}],
            selectControlModel:'',
            detectorCpnsFlag:false,
            detectorData:{},
            loading1: false,
            loading5: false,
            currentFloor:'',
            changestatus: { //修改设备状态数据 devAirTemperature
                nowStates: {},
                devAirPattern: "",
                devAirSweepingWind: "",
                devAirTemperature: "26",
                devAirWindSpeed: "",
                devBrightness: "1",
                devColorTemperature: "1",
                devId: "",
                devLowerLimitLumen: "",
                devLumenSpacing: "",
                devNumber: "",
                devPercentage: "",
                devState: "",
                devUpperLimitLumen: "",
                temperature: '',
                devAirFactors: '',
                devAirCurrent: '',
                devAirVoltage: '',
                devAirPower: '',
                devAirElectricity: '',
                devAirTemperature2: '',
                devAirProtectTemperature: '',
                devSn: '',
                jourDeviceId: '',
                authorizeType: '',
                jdcCardId: '',
                houseAddress: '',
                jdcPassword: '',
                jdcDeadlineTime: '',
                jdcMaxUnlockingTimes: null,
                jdcHsId: null,
                jdcUserId: null,
                encryptMode: null,
                startTime: '',
                sendMessage: 0,
                csRoad: '',
                csRoad2: '',
                csRoad3: '',
                whiteBrightness: 0, //白光亮度
                colorTemperature: 0, //白光色温
                whiteMode: 0, //模式
                rgbSpeed: 0, //彩光速度
                rgbMode: 0, //彩光模式
                rgbBrightness: 0, //彩光亮度
                rcolorValue: '',
                gcolorValue: '',
                bcolorValue: '',
                percentCurtain: 0,
                type: 1,
            },
            img: {
              url1: require('../../assets/icon-1.png'),
              url2: require('../../assets/icon-2.png'),
              url3: require('../../assets/icon-3.png'),
              url4: require('../../assets/icon-4.png'),
              url5: require('../../assets/icon-5.png'),
            },
            // 222
            panelForm: { //操作相关 ightSwitch2
                // devSecondType
                devSecondType: '',
                devFirstType: '',
                devBrandId: '',
                // ===
                radar:[176], // 雷达
                intelligentDoorLock:[167], // 智能门锁
                waterMeter:[109], // 水表
                // ===
                CentralizedScenePanel:[17, 96],//集中情景面板
                chamberlainScene:[17,27],//小管家智能情景
				records: [], // 情景面板按钮
                switch: [1, 47, 51, 75, 89, 92, 127], //开关
                gatewayArr: [7, 8, 32, 71], //网关
                socket: [25], //增强型带定时单路插座
                socket2: [37], //2.4G插座
                socket3: [138, 139], //带温控计量插座
                doorLock: [3], //门锁通用
                doorcard: [22, 24], //刷卡门锁
                doorPsd: [23, 40, 58, 87, 60, 24], //密码门锁
                curtain: [6, 38, 73, 107, 108,164], //百分比窗帘
                curtain1: [117,163], //开合窗帘 222
                curtain2: [6,143],//智能窗帘控制器
                curtain3: [6,147],
                clothesHorse: [34, 130], //智能晾衣架
                lnfraredAlarm: [10, 13], //红外报警器
                lnfraredAlarm1: [93, 10, 95, 106], //红外报警器
                lightSensor: [50, 54], //光照传感器
                lightSensor1: [26, 94, 135], //6200光线传感器
                lightSwitch: [48], //灯=>开关
                lightSwitch2: [36, 59], //灯=>亮度
                lightSwitch3: [31, 44, 56], //灯=>色温
                lightSwitch4: [68, 69, 70], //灯=>RGB
                lightSwitch5: [23, 76], //CW灯
                lightSwitch6: [31, 97, 111], //6200调光灯
                lightSwitch7: [133], //2.4GRGB灯带
                electricBox: [52], //电箱//16
                ammeter: [136], //电表 功率计量盒
                ammeter2: [110], //电表 6200电表
                airConditioner: [4, 42, 43, 49, 137], //空调=>中央空调
                airConditioner1: [4, 43], //空调=>漏电保护开关
                airConditioner2: [4], //空调
                airConditioner3: [43], //空调=>漏电保护开关+设备温度
                airConditioner4: [137], //酒店系统温控器（6200）
                faceId: [24], //人脸识别
                faceSnapId: [33, 38], //人脸抓拍
                faceSnapId1: [72, 82, 83, 84, 85, 90, 91], //人脸抓拍
                faceSnapId2: [120, 121, 122, 123, 124, 125, 126], //人脸抓拍
                doorPlate: [19, 53], //电子门牌
                floorHeating: [57], //地暖
                backgroundMusic: [29,55], //背景音乐主机(小亿)
                localhostMusic: [29,162], //本地音乐播放器
                cardPanel: [115], //插卡面板
                centralizedControl:[37], //集控开关
                centralAirConditioning:[36,141],//中央空调
                wallSocket: [119], //墙面插座
                warningMachineMonitoring: [128], //警戒机监测
                detector: [140], //检测仪
                controlBox:[144],
                LadderControl:[146],//梯控
                longRangeDoorLock:[148],//门锁()
                scenePanel:[161,145],
                thoroughlyModule:[74],
            },
            // 智能控制盒++++++++++++++++
            controlObj:{ // 智能控制盒状态23333333333
                dialog:false,
                state:'',
                delayTime:'',
                actionTime:'',
                isChangeState:false,
            },
            controlBoxRules:[// 表单验证
                { validator(rule, value, callback){
                    if (value==='') {
                        // console.log(value)
                      return callback(new Error('内容不能为空'));
                    }
                    if (!Number.isInteger(value)) {
                        // console.log(value)
                      callback(new Error('请输入整数值'));
                    } else {
                      if (value>15||value<0) {
                        //   console.log(value)
                        callback(new Error('时间必须为0~15秒'));
                      } else {
                        callback();
                      }
                    }
                }, trigger: 'blur' }
            ],
            // +++++++++++++++++++++++++
            selCity:{
                bdclickrow:{},
            },
            //电表配对
            ammeterObj: {
                devId:'',
                jourDeviceId:'',
                timer:null,
                dataList: [],
                isContinue:false,
                ammeterDevices: '',
                dialogAmmeter: false,
            },
            enabled: {
                //lx
                devSn: '',
                dialogVisible: false,
                jobEnabled: 0,
                digitalDimming: 0,
                uploadingEnabled: 0,
                hourlyBasis: '秒',
                dialogTitle: '',
                params: {
                    devId: 0,
                    order: 0,
                    enable: '',
                    period: 1,
                    devState: 0,
                    periodUnit: '01',
                    upperDimming: 0,
                    lowerDimming: 0,
                    dimmingStride: 0,
                }
            },
            //红外按钮
            infrareds: {
                params: {
                    switchs: 2,
                    devId: 40,
                    spacing: 5,
                    threshold: 3,
                    sensitivity: 1
                },
                periodVaule: 1,
                thresholdVaule: 50,
                sensitivityVaule: 0
            },
            dialogFloorHeating: false,
            dialogCaoZuoSheBei: false,
            dialogPairing: false,
            dialogpairedDev:false,
            infraredReportDialog: false,
            dialogWenDuSheZhi: false,
            dialogChaZuo: false,
            dialogShangDian: false,
            dialogGateLockRecords: false,
            dialogIC: false,
            dialogAddPassword: false,
            dialogDoorLock: false,
            dialogAddIC: false,
            dialogMoShi: false,
            dialogFengSu: false,
            dialogSaoFeng: false,
            dialogGateWayHangDevice: false,
            dialogMagnetic: false,
            dialogIdentify: false,
            dialogImg: false,
            dialogLightFeel: false, //光感弹框
            dialogReportedRecord: false, //上报记录弹框
            dialogReportedRecord2: false, //上报记录弹框2
            dialogBindingEquipment: false,
            dialogDiagram: false, //光感曲线图弹框
            dialogBindingEquipment2: false,
            dialogEnabled: false, //使能位弹框
            dialogFloorHeatingSetUp: false,
            installationAddress:false,
            pairTable:false,
            floorHeating: {
                loading: false,
                switchButtonType: 'info',
                lockButtonType: 'info',
                modeButton: "室温模式",
                floorHeatingState: {
                    workingMode: "室温模式",
                    roomTemp: 0,
                    detectionRoomTemp: 0,
                    landTemp: 0,
                    detectionLandTemp: 0,
                    currentTimeGear: 0,
                    limitGear: 1,
                    heatingState: "关",
                    alarmStatus: "禁用",
                    switchingState: "关",
                    deviceLock: "关",
                    timeGearRange: "1~30",
                    antifreezeFunctionSwitch: false,
                    temperatureLowerLimit: 0,
                    temperatureUpperLimit: 0,
                    limitRange: 2,
                    openAndStopTemperatureDifference: 0,
                    temperatureProtectionProbe: 0,
                    temperatureProbeError: 0,
                    minimumTimeForRelayToOpen: 0
                }
            },
            color3: '',
            predefineColors: [
                '#ff4500',
                '#ff8c00',
                '#ffd700',
                '#90ee90',
                '#00ced1',
                '#1e90ff',
                '#c71585',
                'rgb(255, 120, 0)',
                'hsv(51, 100, 98)',
                'hsl(181, 100%, 37%)',
            ],
            powerState: {
                switchingStatus: '',
                totalElecPower: '- -',
                electricityRemaining: '- -',
                voltage: '- -',
                power: '- -',
                electricCurrent: '- -',
                thresholdVoltage: '- -',
                thresholdPower: '- -',
                limitingCurrent: '- -',
                deviceTemperature: '- -',
                thresholdTemp: '- -',
                powerOnStatus: '- -',
                equipmentType: '- -',
                subEquipNum: '- -',
                residualOperatCurrent: '- -',
                id: '- - ',
                switchingState: '- -',
                limitPowerWarningValue:'- -',
            },
            getStateTiming: '',
            voltageVisible: false,
            thresholdVoltage: '',
            currentVisible: false,
            limitingCurrent: '',
            residualOperatCurrent: '',
            limitPowerWarningValue: '',
            ammeterState: {
                minUnitOfMeasurement: '',
                totalElecPower: '',
            },
            plugInCardRecords: {
                dataList: [],
                cursor: 1, //当前页面
                limit: 5, //每页个数
                total: 0, //总条数
                pages: 0, //总页数
            },
            dialogPlugInCard: false,
            //配对
            pairingObj: {
                dataList: [],
                pairedDevices: [],
                title: ['可配对设备', '已配对设备']
            },
            infraredReportArr: [],
            value10: 0,
            gateLockRecords: {
                dataList: [],
                cursor: 1, //当前页面
                limit: 5, //每页个数
                total: 0, //总条数
                pages: 0, //总页数
            },
            card: {
                rules: { //验证规则
                    doorCardId: [{
                        required: true,
                        message: '卡号不能为空',
                        trigger: 'blur',
                    }, ]
                },
                doorCardId: '',
                ICTitle: '',
                ICLabel: '',
                ICButton: '',
                state: '',
                loading: false,
                user: '',
                userId: ''
            },
            offlinePassword: '',
            password: {
                rules: { //验证规则
                    user: [{
                        required: true,
                        message: '用户不能为空',
                        trigger: 'change',
                    }, ],
                    mima: [{
                            required: true,
                            message: '密码不能为空',
                            trigger: 'blur',
                        },
                        {
                            min: 6,
                            max: 10,
                            message: '密码不能为空长度为 6 到 10 个字符',
                            trigger: 'blur',
                        }
                    ],
                    offlineDate: [{
                        required: true,
                        message: '生效时间不能为空',
                        trigger: 'change',
                    }, ],
                    offlineUnlockTimes: [{
                            required: true,
                            message: '次数不能为空',
                            trigger: 'blur',
                        },
                        {
                            min: 1,
                            max: 10,
                            message: '次数不能为空长度为 6 到 10 个字符',
                            trigger: 'blur',
                        }
                    ],
                    onlineDeadline: [{
                        type: 'array',
                        required: true,
                        message: '期限不能为空',
                        trigger: 'change',
                    }, ],
                    offlineTimeArr: [{
                        type: 'array',
                        required: true,
                        message: '期限不能为空',
                        trigger: 'change',
                    }, ],
                    offlineHours: [{
                        required: true,
                        message: '小时不能为空',
                        trigger: 'change',
                    }, ],
                    offlineMinutes: [{
                        required: true,
                        message: '分钟不能为空',
                        trigger: 'change',
                    }, ],
                    failureHours: [{
                        required: true,
                        message: '小时不能为空',
                        trigger: 'change',
                    }, ],
                    failureMinutes: [{
                        required: true,
                        message: '分钟不能为空',
                        trigger: 'change',
                    }, ],
                    failureDate: [{
                        required: true,
                        message: '失效时间不能为空',
                        trigger: 'change',
                    }, ],
                },
                firstType: '在线密码',
                user: '',
                userId: '',
                onlineSecondType: '限次密码',
                mima: '',
                onlineUnlockTimes: '',
                onlineDeadline: '',
                offlineSecondType: 0,
                offlineBeginTime: '',
                offlineUnlockTimes: '',
                offlineTimeArr: [],
                offlineDate: '',
                offlineHours: '',
                offlineMinutes: '',
                failureDate: '',
                failureHours: '',
                failureMinutes: '',
                failureShowDisabled: false,
                loading: false
            },
            passwordTitle: '',
            scenePanelObj:{
                hsId:'',
                sceneCategory:[],
                scenePaneBut:[],
                scenePaneList:[],
                pairDialog:false,
                codeDialog:false,
            },
            optionsHours: [{
                    value: '00',
                    label: '00',
                    disabled: false
                }, {
                    value: '01',
                    label: '01',
                    disabled: false
                },
                {
                    value: '02',
                    label: '02',
                    disabled: false
                },
                {
                    value: '03',
                    label: '03',
                    disabled: false
                },
                {
                    value: '04',
                    label: '04',
                    disabled: false
                },
                {
                    value: '05',
                    label: '05',
                    disabled: false
                },
                {
                    value: '06',
                    label: '06',
                    disabled: false
                },
                {
                    value: '07',
                    label: '07',
                    disabled: false
                },
                {
                    value: '08',
                    label: '08',
                    disabled: false
                },
                {
                    value: '09',
                    label: '09',
                    disabled: false
                },
                {
                    value: '10',
                    label: '10',
                    disabled: false
                },
                {
                    value: '11',
                    label: '11',
                    disabled: false
                },
                {
                    value: '12',
                    label: '12',
                    disabled: false
                },
                {
                    value: '13',
                    label: '13',
                    disabled: false
                },
                {
                    value: '14',
                    label: '14',
                    disabled: false
                },
                {
                    value: '15',
                    label: '15',
                    disabled: false
                },
                {
                    value: '16',
                    label: '16',
                    disabled: false
                },
                {
                    value: '17',
                    label: '17',
                    disabled: false
                },
                {
                    value: '18',
                    label: '18',
                    disabled: false
                },
                {
                    value: '19',
                    label: '19',
                    disabled: false
                },
                {
                    value: '20',
                    label: '20',
                    disabled: false
                },
                {
                    value: '21',
                    label: '21',
                    disabled: false
                },
                {
                    value: '22',
                    label: '22',
                    disabled: false
                },
                {
                    value: '23',
                    label: '23',
                    disabled: false
                },
            ],
            indefinitely: '次数',
            effectTimeShow: true,
            showMinutes: true,
            initializePwd: '',
            //门锁授权记录
            authorizationRecord: {
                dialogAuthorizationRecord: false,
                dataList: [],
                cursor: 1, //当前页面
                limit: 5, //每页个数
                total: 0, //总条数
                pages: 0, //总页数
            },
            optionsMinutes: [{
                    value: '00',
                    label: '00',
                    disabled: false
                },
                {
                    value: '20',
                    label: '20',
                    disabled: false
                },
                {
                    value: '40',
                    label: '40',
                    disabled: false
                },
            ],
            dateLimit: { //日期限制
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                },
            },
            failureoptionsHours: [{
                    value: '00',
                    label: '00',
                    disabled: false
                }, {
                    value: '01',
                    label: '01',
                    disabled: false
                },
                {
                    value: '02',
                    label: '02',
                    disabled: false
                },
                {
                    value: '03',
                    label: '03',
                    disabled: false
                },
                {
                    value: '04',
                    label: '04',
                    disabled: false
                },
                {
                    value: '05',
                    label: '05',
                    disabled: false
                },
                {
                    value: '06',
                    label: '06',
                    disabled: false
                },
                {
                    value: '07',
                    label: '07',
                    disabled: false
                },
                {
                    value: '08',
                    label: '08',
                    disabled: false
                },
                {
                    value: '09',
                    label: '09',
                    disabled: false
                },
                {
                    value: '10',
                    label: '10',
                    disabled: false
                },
                {
                    value: '11',
                    label: '11',
                    disabled: false
                },
                {
                    value: '12',
                    label: '12',
                    disabled: false
                },
                {
                    value: '13',
                    label: '13',
                    disabled: false
                },
                {
                    value: '14',
                    label: '14',
                    disabled: false
                },
                {
                    value: '15',
                    label: '15',
                    disabled: false
                },
                {
                    value: '16',
                    label: '16',
                    disabled: false
                },
                {
                    value: '17',
                    label: '17',
                    disabled: false
                },
                {
                    value: '18',
                    label: '18',
                    disabled: false
                },
                {
                    value: '19',
                    label: '19',
                    disabled: false
                },
                {
                    value: '20',
                    label: '20',
                    disabled: false
                },
                {
                    value: '21',
                    label: '21',
                    disabled: false
                },
                {
                    value: '22',
                    label: '22',
                    disabled: false
                },
                {
                    value: '23',
                    label: '23',
                    disabled: false
                },
            ],
            //网关
            gateWayHangDevice: {
                dataList: [],
                cursor: 1, //当前页面
                limit: 5, //每页个数
                total: 0, //总条数
                pages: 0, //总页数
            },
            GRBName: 'first',
            
            centralVRV:{
              time:null,
              timer:null,
              state:'关',
              buttonTest:'一键编辑',
              buttonType:'primary',
              childTable:[],
              gatherLoading:false,
              childVRVState:{},
              percentage:16,
              childDialog:false,
              childLoading:false,
              indoorTemperature:'--',
              temperature:'',
              childTableRowData:{},
              customColor:'#409eff',
              selectRoomDialog:false, //选择房间
              installationAddress:false,
              windSpeedPercentage:0,
              colors: [
                {color: '#f56c6c', windSpeedPercentage: 0},
                {color: '#e6a23c', windSpeedPercentage: 33.33},
                {color: '#5cb87a', windSpeedPercentage: 66.66},
                {color: '#1989fa', windSpeedPercentage: 99.99},
              ]
            },
            faceForm: { //人脸开门
                faceIdList: [],
                magneticList: [],
                cursor: 1,
                total: 0,
                pages: 0,
                limit: 5,
                endTime: '',
                startTime: '',
                id: '',
                jftiPersonName: '',
                dfdnDoorSn: '',
                jftiUserId: '',
                jourDeviceIds: [],
                result: '1',

                time: [dateFtt('yyyy-MM-dd', new Date()), dateFtt('yyyy-MM-dd', new Date())],
            },
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            dialogImageUrl: '',
            maxlumen: '', //最大流明
            minlumen: '', //最小流明
            lightFeelTitle: '', //光感标题
            reportedRecordArr: [],
            equipmentShuttleBox: [], //绑定设备穿梭框
            equipmentJourDeviceId: [],
            intervalLumen: '', //间隔流明
            modifyPush: { // 人脸枪击服务器地址修改
                dialog: false,
                url: '',
            },
            cardPanelData: {
                cardSetDialog: false,
                cardSetTabPane: 'first',
            },
            //使能位
            sensorModuleShow: false,
            temperatureError: [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5],
            cardsDialog: false,
            addCardsData: {
                list: '',
                user:'',
                rules: {
                    list: { required: true, message: 'IC卡号不能为空', trigger: 'blur', },
                    user: { required: true, message: '用户不能为空', trigger: 'blur', }
                },
            },
            cancelCardsData: {
                list: '',
                user:'',
                rules: {
                    list: { required: true, message: 'IC卡号不能为空', trigger: 'blur', },
                    user: { required: true, message: '用户不能为空', trigger: 'blur', }
                },
            },
        }
    },
    destroyed() {
    if(this.ws){
      this.ws.close();
    }
      //   URL.revokeObjectURL();
    },
    created() {
        // console.log(this.form,'111')
    },
    computed: {
        ...mapState([ 'userNameList']),
    },
    // 222
    methods: {
       async queryEnergyConsumptions() {
       let result =  await this.$axios({
        url: "/common2/energyConsumption/getEcData",
        method: "GET",
        params: {
          type: 2,
          deviceId: this.devicesId
        },
       })
       let {ecDayData, ecMonthData, ecYearData} =result.data.data;
        this.ecDayData = ecDayData;
        this.ecMonthData = ecMonthData;
        this.ecYearData =ecYearData;
      },
       async axDeviceWarningInfo() {
        let data = await this.$axios({
                url: `/common2/deviceWarning/deviceWarningInfo`,
                method: "GET",
                params: {
                id:this.devicesId,
                // id: 82,
                },
            })
            console.log(data.data.result)
            return this.deviceDatas = data.data.result;
            // .then((res) => {
                
            //     this.deviceDatas = res.data.result;
            //     console.log(this.deviceDatas,'8888')
            // });
        },
        
        async btnDialogHistorys() {
           this.dialogHistorysVisible = true;
           await this.queryEnergyConsumptions();
        },
        btnDialogSection() {
           this.dialogSectionsVisible = true;
        },
       async btnDialogDevice(){
          await this.axDeviceWarningInfo();
            console.log('3333',this.devicesId,this.deviceDatas,'9999') // this.deviceDatas
            this.dialogDeviceVisible=true;
        },
        chamberlainSceneLoading5(data){
            this.loading5 = data
        },
        airConditionerControl(data,value){
            let fengSuControl = {
                '低速风':this.airWindSpeedLow,    
                '中速风':this.airWindSpeedMiddle, 
                '高速风':this.airWindSpeedHeight, 
                '自动风':this.airWindSpeedSelf, 
            }
            let moShiControl = {
                '制冷模式':this.airCold,
                '制热模式':this.airHot, 
                '送风模式':this.airWind,
                '自动模式':this.airSelf,
            }
            let switchControl = {
                '开':this.openLight,
                '关':this.closeLight,
            }
            let minusAddControl = {
                '加':this.airTemperatureAdd,
                '减':this.airTemperatureMinus,
            }
            if(fengSuControl[data]){
                fengSuControl[data]()
            }else if(moShiControl[data]){
                moShiControl[data]()
            }else if(switchControl[data]){
                switchControl[data]()
            }else if(minusAddControl[data]){
                this.changestatus.devAirTemperature = value
                minusAddControl[data]()
            }
        },
        airTemperatureMinus() {
            const num = this.panelForm.airConditioner4.indexOf(this.panelForm.devSecondType) > -1 ? 0.5 : 1
            if (this.changestatus.devAirTemperature > 16) {
                this.$set(this.changestatus,'devAirTemperature',(parseInt(this.changestatus.devAirTemperature)))
                // this.changestatus.devAirTemperature = parseInt(this.changestatus.devAirTemperature) - num
                this.changeStatus()
            }
        },
        airTemperatureAdd() {
            const num = this.panelForm.airConditioner4.indexOf(this.panelForm.devSecondType) > -1 ? 0.5 : 1
            if (this.changestatus.devAirTemperature < 30) {
                console.log(this.changestatus.devAirTemperature)
                this.$set(this.changestatus,'devAirTemperature',(parseInt(this.changestatus.devAirTemperature)))
                // this.changestatus.devAirTemperature = parseInt(this.changestatus.devAirTemperature) + num;
                this.changeStatus();
            }
        },
        // 低速风
        airWindSpeedLow() {
            this.changestatus.devAirWindSpeed =
                this.panelForm.airConditioner4.indexOf(this.panelForm.devSecondType) >
                    -1 ? '低速' : '低';
            this.changeStatus()
            this.$emit('airConditionerControl',"airWindSpeedLow")
        },
        // 中速风
        airWindSpeedMiddle() {
            this.changestatus.devAirWindSpeed =
                this.panelForm.airConditioner4.indexOf(this.panelForm.devSecondType) >
                    -1 ? '中速' : '中';
            this.changeStatus()
        },
        // 高速风
        airWindSpeedHeight() {
            this.changestatus.devAirWindSpeed =
                this.panelForm.airConditioner4.indexOf(this.panelForm.devSecondType) >
                    -1 ? '高速' : '高';
            this.changeStatus()
        },
        // 自动风
        airWindSpeedSelf() {
            this.changestatus.devAirWindSpeed = '自动'
            this.changeStatus()
        },
        // 制冷模式
        airCold() {
            this.changestatus.devAirPattern = '制冷'
            this.changeStatus()
        },
        // 制热模式
        airHot() {
            this.changestatus.devAirPattern = '制热'
            this.changeStatus()
        },
        // 送风模式
        airWind() {
            this.changestatus.devAirPattern = '送风'
            this.changeStatus()
        },
        // 自动模式
        airSelf() {
            this.changestatus.devAirPattern = '自动'
            this.changeStatus()
        },
        positioning(index,row){
          locationControl({devId:this.form.clickrow.jourDeviceId}).then((res)=>{
            console.log(res)
            if(res.data.status == 200){this.$message.success("定位成功！")}
          }).catch((err)=>{
            this.isError(err,this)
          })
        },
        controlBoxOpen(){
            this.controlHandle('开')
        },
        controlBoxClose(){
            this.controlHandle('关')
        },
        controlHandle( state ){
        let {delayTime,actionTime} =this.controlObj
        delayTime=parseInt(delayTime)
        actionTime=parseInt(actionTime)
        console.log(delayTime,actionTime);
            this.$axios({
                method: "post",
                url: '/common2/devices/operate',
                data: {
                    devId:this.form.clickrow.jourDeviceId,
                    devState: state,
                    delayTime,actionTime
                }
            }).then((res)=>{
                let result =  JSON.parse(res.data.result)
                console.log(result)
                let { status } = result.data[0]
                if(result.data[0].resultCode){
                    this.$message.warning(result.data[0].resultMsg)
                    return
                }
                this.$message.success('控制成功')
                this.controlObj.state = status.slice(6,8)
            }).catch(err=>{
                // console.log(err.response)
                this.isError(err, this)
            })
        
        },
        controlObjStting(){
            this.controlObj.dialog = true
        },
        scenePaneCodeInput(value,item){
            console.log(value.length)
            if(value.length == 10 && value == "0000000000"){item.code="000000000"; this.$message.warning("不能输入全为0的字符");return }
        },
        scenePaneCheckbox(value,item){
            value?item.code = "FFFFFFFFFF":item.code=""
        },
        sceneCodeSave(formName){
            new Promise ((resolve,reject)=>{
                this.$refs[formName].validate((valid) => {if (valid) return resolve()})
            }).then(()=>{
                let arr = []
                for (let i = 0; i < this.scenePanelObj.scenePaneList.length; i++) {
                    let item = this.scenePanelObj.scenePaneList[i]
                    if(item.code){
                        let obj ={};
                        obj.jscName = item.name
                        obj.jslAisle = item.cpKeyValue
                        obj.jslHouseId = this.scenePanelObj.hsId
                        obj.jslSceneCategory = item.spdId
                        obj.jslTriggerDevice = this.deviceId
                        obj.jslMac = this.form.clickrow.devAuthSecret           // 所挂网关
                        obj.jslDeviceSn = this.form.clickrow.devAuthId     // 设备sn
                        obj.jslSceneCode = this.upperCase(item.code)     // 情景码
                        arr.push(obj)
                    }
                }
                if(arr.length<1){return this.$message.warning('请设置最少一个情景码')}
                sceneLinkageAddSceneCode(arr).then(res=>{
                    this.$message.success("配对成功")
                    this.getAllSceneCategory()
                    this.checkSceneLinkageInfo()
                }).catch(err=>{
                    this.isError(err,this)
                })
            })
        },
        upperCase(str) {
            let arr = str.split("");
            let newStr = "";
            // 通过数组的forEach方法来遍历数组
            arr.forEach(function (value) {
                if (value >= 'a' && value <= 'z')
                    newStr += value.toUpperCase();
                else
                    newStr += value;
            });
            return newStr;
        },
        scenePaneSave(){
            let arr = []
            for (let i = 0; i < this.scenePanelObj.scenePaneList.length; i++) {
                let item = this.scenePanelObj.scenePaneList[i]
                // if(item.spdId){
                    let obj ={};
                    obj.jscName = item.name
                    obj.jslAisle = item.cpKeyValue
                    obj.jslHouseId = this.scenePanelObj.hsId
                    obj.jslSceneCategory = item.spdId?item.spdId:null
                    obj.jslTriggerDevice = this.deviceId
                    arr.push(obj)
                // }
            }
            sceneLinkageSave(arr).then(res =>{
                this.$message.success("配对成功")
                this.getAllSceneCategory()
                this.checkSceneLinkageInfo()
            }).catch(err =>{
                this.isError(err,this)
            })
        },
        sceneCodeSetting(){
            this.scenePanelObj.codeDialog = true
        },
        scenePanelClick(){
            this.scenePanelObj.pairDialog = true
        },
        getAllSceneCategory(){
            linkageGetLinkageScene({
                sceneName:'',
                sceneAddress:'',
                hsIds:[this.form.clickrow.hsId],
                })
            .then((res) => {
                this.scenePanelObj.sceneCategory = res.data.result
            }).catch((err) => {
                this.isError(err, this);
            });
            // getAllSceneCategory().then(res =>{
            // }).catch(err =>{
            //     this.isError(err,this)
            // })
        },
        checkSceneLinkageInfo(){
            sceneLinkageInfo({
                hsId:this.scenePanelObj.hsId,
                deviceId:this.deviceId
            }).then(res=>{
                this.scenePanelObj.scenePaneBut = []
                res.data.result.forEach(item=>{
                    this.scenePanelObj.scenePaneList.forEach(item1=>{
                        if(item1.cpKeyValue == item.jslAisle){
                            this.scenePanelObj.scenePaneBut.push({
                                jscName:item.jscName,
                                jsId:item.jsId,
                            })
                            item1.spdId = item.jslSceneCategory
                            item1.jsId = item.jsId
                            
                        }
                    })
                })
            }).catch(err=>{
                this.isError(err,this)
            })
        },
        
        executeBtn(data){
            console.log(data)
            sceneModeControl({jsId:data.jsId}).then(res=>{
                this.$message.success("执行成功");
            }).catch(err=>{
                this.isError(err,this)
            })
        },
        //远程开锁 按钮  
        longRangeDoorLockClick(){
            this.changestatus.devState = "开锁"
            this.changeStatus()
        },
        //开锁记录 按钮
        unlockingRecordClick(){
            var arr=["远程开锁成功","门卡开锁成功","指纹开锁成功","密码开锁成功","微信开锁成功","门锁关锁成功","时间同步成功","门卡注册成功","指纹注册成功","密码注册成功","门卡注销成功","指纹注销成功","密码注销成功","门锁欠压报警","门锁被撬报警","挟持紧急报警","连续试错报警","门铃触发记录","门锁唤醒记录","恢复出厂设置"]
            for(var i=0;i<arr.length;i++){
                var obj={}
                obj.lable=arr[i]
                obj.value=arr[i]
                this.recordsValueList.push(obj)
            }
            // console.log('recordsValueList',this.recordsValueList)
            if(this.recordsTime.length==0){//点击事件记录是否有日期,如果无就默认当天日期
                let date = new Date()
                let y = date.getFullYear()
                let m = date.getMonth() + 1
                let d = date.getDate()
                let time = y + '-' + m + '-' + d+' 00:00:00'
                let time1 = y + '-' + m + '-' + d+' 23:59:59'
                this.recordsTime[0] =  time
                this.recordsTime[1] =  time1
            }
            this.unlockingRecord=true
            this.loading=true        
            this.$axios({
                method:'get',
                url:'/common2/deviceStatusRecord/getStatusRecord',
                params:{
                    devSn:this.enabled.devSn,
                    cursor:parseInt(this.RecordPage),
                    limit:parseInt(this.RecordSizePreview),
                    startTime:this.recordsTime[0],
                    endTime:this.recordsTime[1],
                    dataType:this.RecordsValue
                }
            }).then(res => {
                // console.log('开锁记录:',res.data.result.records) 
                this.unlockingRecordList=res.data.result.records
                for(var i=0;i<this.unlockingRecordList.length;i++){
                    this.unlockingRecordList[i].recordSN=this.enabled.devSn
                    this.unlockingRecordList[i].recordsName=this.recordsName
                    this.unlockingRecordList[i].recordsHsAddCommunity=this.recordsHsAddCommunity
                }
                this.totalPreviewRecord=res.data.result.total
                this.loading=false
            }).catch(err=>{
                this.isError(err,this)
                this.loading=false
            })
        },
        //事件时间change事件
        recordsTimeChange(){
            function a(str){//日期补0
                return str.toString().length==1?'0'+str:str
            }
            var d = new Date(this.recordsTime[0]);
            var datetime=d.getFullYear() + '-' + a((d.getMonth() + 1)) + '-' + a(d.getDate()) + ' ' + a(d.getHours()) + ':' + a(d.getMinutes()) + ':' + a(d.getSeconds());
            var d1 = new Date(this.recordsTime[1]);
            var datetime1=d1.getFullYear() + '-' + a((d1.getMonth() + 1)) + '-' + a(d1.getDate()) + ' ' + a(d1.getHours()) + ':' + a(d1.getMinutes()) + ':' + a(d1.getSeconds());
            this.recordsTime[0]=datetime
            this.recordsTime[1]=datetime1
            this.loading=true
            this.$axios({
                method:'get',
                url:'/common2/deviceStatusRecord/getStatusRecord',
                params:{
                    devSn:this.enabled.devSn,
                    cursor:parseInt(this.RecordPage),
                    limit:parseInt(this.RecordSizePreview),
                    startTime:this.recordsTime[0],
                    endTime:this.recordsTime[1],
                    dataType:this.RecordsValue
                }
            }).then(res => {
            //    console.log('开锁记录:',res.data.result.records) 
                this.unlockingRecordList=res.data.result.records
                for(var i=0;i<this.unlockingRecordList.length;i++){
                    this.unlockingRecordList[i].recordSN=this.enabled.devSn
                    this.unlockingRecordList[i].recordsName=this.recordsName
                    this.unlockingRecordList[i].recordsHsAddCommunity=this.recordsHsAddCommunity
                }
                this.totalPreviewRecord=res.data.result.total
                // console.log("this.recordsValueList:",this.recordsValueList);
                this.loading=false
            }).catch(err=>{
                this.isError(err,this)
                this.loading=false
            })
        },
        //门锁事件change事件
        recordsValueChange(){
            this.recordsTimeChange()
        },
        //开锁记录 当前页改变时
        handleRecordChange(val){
            this.RecordPage=val
            this.recordsTimeChange()
        },
        //开锁记录 每页显示条数
        RecordSizeChange(val){
            this.RecordSizePreview=val
            this.recordsTimeChange()
        },
        IntervalClick(e){//点击层段显示选择1-100
            if(e==4){//层段
                this.isInterval=true
                this.isInterval2=false
                var x=[]
                for(var i=2;i<=64;i++){
                    var obj={}
                    obj.label=i
                    obj.value=i
                    x.push(obj)
                }
                this.interNumList1=x
            }else if(e==3){//间隔
                this.isInterval=false
                this.isInterval2=true
            }else{
                this.isInterval=false
                this.isInterval2=false
            }
        },
        keysNumChange(){//可用键位数量
            this.keysNum=this.slabNum*8-this.unFloor//默认8个可用键值位
            this.lightKeys=="0"?this.keysNum=this.slabNum*8-this.unFloor:
            this.lightKeys=="2"?this.keysNum=this.slabNum*8-2-this.unFloor:
            this.lightKeys=="3"?this.keysNum=this.slabNum*8-3-this.unFloor:this.keysNum=this.slabNum*8-this.unFloor
            if(this.unFloor<0){this.unFloor=0;this.$message.error("地下楼层不能写负数和空值")}
            if(this.keysNum<0){//判断如果可用键位值低于或等于0
                this.ispreview=false
                this.keysNum=this.slabNum*8
                this.unFloor=0
                this.$message.error("可用键位数量不能等于和低于0")
            }
            this.groundFloor=this.keysNum//地面总楼层
            var v=[]
            for(var j=this.interNum1[0]+1;j<=64;j++){
                var list={}
                list.label=j
                list.value=j
                v.push(list)
            }
            this.interNumList2=v
            // console.log("List2:",this.interNum1, this.interNumList2)
        },
        previewClick(){//预览按钮
            this.ispreview=true
            this.currentPage=1

            var box=""
            var b=[]
            let lightKeys = ["开","关","常开"]
            for(var i=1;i<=this.slabNum;i++){
                switch(i){
                    case 1:
                        box="A"
                    break;
                    case 2:
                         box="B"
                    break;
                    case 3:
                         box="C"
                    break;
                    case 4:
                         box="D"
                    break;
                    case 5:
                         box="E"
                    break;
                    case 6:
                         box="F"
                    break;
                    case 7:
                         box="G"
                    break;
                    case 8:
                         box="H"
                    break;
                }
                for(var j=1;j<=8;j++){
                    var obj={}
                    var floor = (i-1)*8+j;//每条的值 xx楼 例:8楼 -2楼
                    // console.log("floor:",2*floor-1)
                    obj.keyId=box+j //每条的键 A-H/1-8 例:A5 D3
                    // 开关按键
                    // console.log(num,'666666');
                    var sum=0
                    //判断楼层方式:单层,双层,顺层,层段,间隔
                    if(this.floorMode=="0"){
                        sum=(2*floor-1)-this.lightKeys-this.unFloor
                    }else if(this.floorMode=="1"){
                        sum=(2*floor)-this.lightKeys-this.unFloor
                    }else if(this.floorMode=="2"){
                        sum=floor
                    }else if(this.floorMode=="3"){
                        //-parseInt(this.jiangeNum)-parseInt(this.lightKeys)
                        //floor-this.lightKeys-this.unFloor==1?1+parseInt(this.lightKeys)+this.unFloor
                        sum=floor-this.lightKeys-this.unFloor==1?1+parseInt(this.lightKeys)+this.unFloor:
                        ( this.jiangeNum * floor - ( this.jiangeNum - 1 ) )

                    console.log("间隔:",sum,floor,)

                    }else if(this.floorMode=="4"){ 
                        // console.log(floor,this.lightKeys,this.unFloor,floor-this.lightKeys-this.unFloor==1);
                        sum=floor-this.lightKeys-this.unFloor==1?1+parseInt(this.lightKeys)+this.unFloor:this.interNum1[0]+floor-2 
                        // console.log(sum,parseInt(this.interNum2[0]));
                        if(sum>this.interNum2[0]+parseInt(this.lightKeys)+this.unFloor) break
                    }
                    switch(this.lightKeys){//是否选择开关,开关常,不启用
                        case "3":
                            obj.keyFunction=(floor<4)?(lightKeys[floor-1]):((this.unFloor!=0)?((this.unFloor>floor-4)?`${-this.unFloor+floor-4}楼`:`${-this.unFloor+sum-3}楼`):`${sum-3}楼`)
                            break
                        case "2":
                            obj.keyFunction=floor<3?lightKeys[floor-1]:((this.unFloor!=0)?((this.unFloor>floor-3)?`${-this.unFloor+floor-3}楼`:`${-this.unFloor+sum-2}楼`):`${sum-2}楼`)
                            break
                        case "0":
                            obj.keyFunction=this.unFloor<1?`${sum}楼`:((this.unFloor!=0)?((this.unFloor>floor-1)?`${-this.unFloor+floor-1}楼`:`${-this.unFloor+sum}楼`):`${sum}楼`)
                            break
                    }
                    obj.floorName=''
                    b.push(obj)
                }
                this.previewList=b  
            } 
            console.log("previewList:",this.previewList)
            this.totalPreview=this.previewList.length//总条数
        },
        dblPreview(row, column, event){//预览列表双击事件
            this.updateLook=true;
            this.upKeyId=row.keyId
            this.upKeyFu=row.keyFunction
            this.upKeyName=row.floorName
            let lightKeys = ["开","关","常开"]
            let index = this.previewList.findIndex(e=>{
                return e.keyId==this.upKeyId
            })
            console.log("aa",this.previewList[index].keyFunction.slice(0,1))
            //判断是否有开关常或者负数,有就不让修改
            if(this.previewList[index].keyFunction==lightKeys[index] || this.previewList[index].keyFunction.slice(0,1)=='-'){
                this.keyFuDis = true;
            }else{
                this.keyFuDis = false;
            }
        },
        updateClick(row){//编辑预览信息--确定按钮
            this.updateLook=false
            let index = this.previewList.findIndex(e=>{
                return e.keyId==this.upKeyId
            })
            this.previewList[index].keyFunction=this.upKeyFu
            this.previewList[index].floorName=this.upKeyName
            console.log("update:",this.previewList,row)
        },
        ladderClick(){//预览信息--点击是否打印按钮
            this.ladderSave=false
            var arr=this.previewList.map(e=>{       
               var str = e.keyFunction.match(/(\S*)楼/);
               return str?str[1]:e.keyFunction
            })
            console.log(arr);
             this.$axios({
                method:'post',
                url:'/common2/elevators/floorConfigure',
                data:{
                    id:this.deviceId,
                    controlType:'配置楼层',
                    sn:this.enabled.devSn,
                    floor:arr
                }
            }).then(res=>{
                console.log("梯控res:",res.data)
                this.$message.success("配置成功")
                this.dialogCaoZuoSheBei=false
                this.updateLook=false
                this.ispreview=false
                this.isfloorInfo=false
            })
        },
        //已配置楼层:楼层信息--点击修改按钮
        floorInfoClick(){
            // this.ladderClick();
            this.dialogCaoZuoSheBei=true

        },
        //已配置楼层:楼层信息--点击状态按钮
        floorState(){
            this.$axios({
                method:'post',
                url:'/common2/elevators/elevatorControl',
                data:{
                    id:this.deviceId,
                    devsn:this.enabled.devSn,
                    controlType:'梯控状态'
                }
            }).then(res=>{
                this.floorStateList.houState=res.data.result['后门状态']
                this.floorStateList.qianState=res.data.result['前门状态']
                this.floorStateList.nowFloor=res.data.result['当前楼层']
                this.floorStateList.operation=res.data.result['运行方向']
                this.nowFloor=res.data.result['当前楼层']
                console.log('状态:',this.floorStateList.houState)
            })
        },
        //已配置楼层:楼层信息,电梯按键--点击乘梯按钮--接口
        floorListClick(i){
            console.log(this.floorJson.frontOrBackDoor)
            if(!this.floorJson.frontOrBackDoor){
                this.floorJson.frontOrBackDoor = '前门'
            }
            this.floorJson.frontOrBackDoor=this.floorJson.frontOrBackDoor
            this.floorJson.specificFloor= i
            console.log("是什么"+i)
            this.$axios({
                method:'post',
                url:'/common2/elevators/elevatorControl',
                data:{
                    id:this.deviceId,
                    devsn:this.enabled.devSn,
                    controlType:this.floorTitle=='选择乘梯'?'单层自动按键':'多层手动按键',
                    floor:this.floorJson
                }
            }).then(res=>{
                console.log('乘梯按钮:',res)
                this.$message.success("乘梯成功")
            })
        },
        //已配置楼层:楼层信息,电梯按键 按钮 开放
        floorOpenListClick(i){
            this.floorOpenNum.push(i)
            console.log('btn:',i);
        },
        //已配置楼层:楼层信息 点击设置按钮
        floorSetUpClick(){
            this.isfloorSetUp=true
        },
        //已配置楼层:楼层信息 点击外呼按钮
        floorOutboundClick(){
            this.isfloorOutbound=true
            this.floorJson.frontOrBackDoor =''
            this.currentFloor = ''
        },
        //已配置楼层:楼层信息 点击设置按钮--接口
        setUpClick(){
            if(!this.selectControlModel){
                this.$message.warning('请选择一个控制模式')
                return
            }
            this.$axios({
                method:'post',
                url:'/common2/elevators/elevatorControl',
                data:{
                    id:this.deviceId,
                    devsn:this.enabled.devSn,
                    controlType:'设置梯控状态',
                    status:this.selectControlModel
                }
            }).then(res=>{
               if(res.status == "200"){
                   this.$message.success('设置成功')
                   this.isfloorSetUp= false
               }
            })
        },
        //已配置楼层:楼层信息 点击开放按钮-接口
        floorOpenClick(){
            console.log("开放按键:",this.floorOpenNum);
            if(!this.floorJson.frontOrBackDoor){
                this.floorJson.frontOrBackDoor = '前门'
            }
            if(this.openTime=="" || this.openTime=="不启用"){
                this.openTime="不启用"
                this.floorJson.openTime=this.openTime
            }else{
                this.floorJson.openTime=this.openTime.join()
            }
            this.floorJson.openFloor=this.floorOpenNum.join()
            if(this.floorOpenNum!=''){
                this.$axios({
                    method:'post',
                    url:'/common2/elevators/elevatorControl',
                    data:{
                        id:this.deviceId,
                        devsn:this.enabled.devSn,
                        controlType:this.floorTitle=='选择乘梯'?'单层-自动按键':'多层手动按键',
                        allowFloor:this.floorJson
                    }
                }).then(res=>{
                    console.log('开放按钮:',res)
                    this.$message.success("开放成功")
                })
            }else{
                this.$message.error("请选择要开放的楼层")
            }

        },
        //判断点击乘梯按钮或者开放按钮
        elevatorClick(event){
            this.isElevator=true
            if(event.currentTarget.id=='chengti'){
                this.isOpenRight=false
                this.isFloorEleList=true
                this.isFloorOpenList=false
                this.floorTitle='选择乘梯'
                this.floorJson.frontOrBackDoor =''
            }else if(event.currentTarget.id=='kaifang'){
                this.isOpenRight=true
                this.isFloorOpenList=true
                this.isFloorEleList=false
                this.floorTitle='选择开放'
                var x=[]
                for(var i=0;i<1;i++){
                    var obj={} 
                    obj.label="不启用"
                    obj.value="不启用"
                    x.push(obj)
                }
                for(var i=1;i<=120;i++){
                    var obj={} 
                    obj.label=i
                    obj.value=i
                    x.push(obj)
                }
                this.openTimeList=x
            }
        },
        //点击前门
        frontDoor(){
            this.frontDoorNum=document.getElementById('qianmeng').innerText
            this.floorJson.frontOrBackDoor=this.frontDoorNum
            console.log("this.floorJson:",this.floorJson.frontOrBackDoor);
        },
        //点击后门
        backDoor(){
            this.frontDoorNum=document.getElementById('houmeng').innerText
            this.floorJson.frontOrBackDoor=this.frontDoorNum
            console.log("this.floorJson:",this.floorJson.frontOrBackDoor);
        },
        //电梯外呼控制
        controlElevator(i){
            if(!this.currentFloor){
                this.$message.warning("请选择您当前所在楼层")
                return
            }
            let direction = i == 0 ? "上楼":"下楼"
            if(this.floorJson.frontOrBackDoor == ''){
                this.floorJson.frontOrBackDoor = '前门'
            }
            this.floorJson.specificFloor= this.currentFloor
            this.$axios({
                method: "post",
                url: `/common2/elevators/elevatorControl`,
                data:{
                    id:this.deviceId,
                    devsn:this.enabled.devSn,
                    outType:direction,
                    controlType:"外呼自动按键",
                    floor:this.floorJson
                }
            }).then(res=>{
                this.$message.success("操作成功")
            }).catch(err=>{
                this.isError(err ,this)
            })
        },
        //每页显示条数
        handleSizeChange(val){
            this.pageSizePreview=val
        },
        //当前页改变时
        handleCurrentChange(val){
            this.currentPage=val
        },
        VRVPositioning(row){
            this.changestatus.devState = '测试定位';
            this.locationControl(row);
        },
        turnOnVRV(row){
            this.changestatus.devState = '开';
            this.locationControl(row);
        },
        turnOffVRV(row){
            this.changestatus.devState = '关';
            this.locationControl(row);
        },
        async locationControl(row){
            this.centralVRV.childLoading = true
          await this.$axios({
            method:'post',
            url:`/common2/devices/locationControl`,
            data:{
                devState:this.changestatus.devState,//  测试定位
                firstType:row.firstType,
                secondType: row.secondType,
                extMhNum:row.extMhNum,  //外机编号
                intMhNum:row.IntMhNum,//  内机编号
                mac:this.form.clickrow.devAuthSecret,//  网关
                sn:row.sn,//  sn码
            },
          }).then((res)=>{
                this.centralVRV.childLoading = false
            if(res.data.status == 200){this.$message.success("控制成功！")}
          }).catch((err)=>{
                this.centralVRV.childLoading = false
            this.isError(err,this);
          })
        },  
        childVRVSave(){
          let arr = []
          for(let i in this.centralVRV.childTable){
            let obj ={};
            if(this.centralVRV.childTable[i].name!=this.centralVRV.childTable[i].nameNew || this.centralVRV.childTable[i].roomName!=this.centralVRV.childTable[i].roomNameNew){
              obj.hsId = this.centralVRV.childTable[i].hsId;
              obj.sn = this.centralVRV.childTable[i].sn;
              obj.name = this.centralVRV.childTable[i].name;
              obj.extMhNum =this.centralVRV.childTable[i].extMhNum;
              obj.intMhNum =this.centralVRV.childTable[i].IntMhNum;
              arr.push(obj)
            }
          }
          console.log(arr)
          this.$axios({
            method: 'post',
            url: `/common2/devices/bindingAdd/${this.form.clickrow.jourDeviceId}`,
            data:arr,
          })
            .then((res) => {
              console.log(res.data.result.msg);
              let msg = res.data.result.msg;
              if (res) {this.$message({ message:msg,type: 'success',});}
            })
            .catch((err) => {
              this.isError(err, this);
            });
          // this.centralVRV.childDialog = false
        },
        editVRV() {
          if(this.centralVRV.childTable.length<1){return}
          if(this.centralVRV.buttonTest=="一键编辑"){
            this.centralVRV.buttonTest = "取消编辑";
            this.centralVRV.buttonType = "warning";
            this.centralVRV.childTable.forEach((item,index)=>{
              this.$set(item,"editing",true)
              this.$set(item,"nameNew",item.name)
              this.$set(item,"roomNameNew",item.roomName)
            })
          }else{
            for(let i in this.centralVRV.childTable){
              if(this.centralVRV.childTable[i].name!=this.centralVRV.childTable[i].nameNew || this.centralVRV.childTable[i].roomName!=this.centralVRV.childTable[i].roomNameNew){
                if (confirm("监测到数据有改动，确定退出编辑？")) {break}else{return}
              }
            }
            this.centralVRV.buttonTest = "一键编辑";
            this.centralVRV.buttonType = "primary";
            this.centralVRV.childTable.forEach((item,index)=>{
              this.$set(item,"editing",false)
            })
          }

          console.log(this.centralVRV.childTable)
        },
        addConfirm(){
          console.log(this.selCity.bdclickrow)
          if (!this.selCity.bdclickrow) {
            this.$message.warning('请选择一个房间绑定');
            return;
          }
          this.centralVRV.childTable.forEach((item, index)=>{
            let row = this.centralVRV.childTableRowData
            if(item.extMhNum == row.extMhNum && item.IntMhNum == row.IntMhNum){
              item.roomNameNew = this.selCity.bdclickrow.hsAddCommunity
              item.hsId = this.selCity.bdclickrow.hsId
            }
          })

          this.centralVRV.selectRoomDialog = false;
          this.centralVRV.installationAddress = false;
        },
        selectRoom(row){
          this.centralVRV.childTableRowData = row
          this.centralVRV.selectRoomDialog = true;
          this.centralVRV.installationAddress = true;
        },
        //模式
        pointRefrigeration(){
        //   if(this.centralVRV.childVRVState.switchState == "关"){this.$message({message: '请打开空调',type: 'warning',});return}
          this.changestatus.devState = '';
          this.changestatus.devAirTemperature = '';
          this.changestatus.devAirWindSpeed = '';
          this.changestatus.devAirPattern = '制冷';
          this.isOpenOrClose=false
          this.changeStatus();
        //   this.defaultState();
        //   this.$refs.pointRefrigeration.style.backgroundColor = '#53b552';
        },
        pointHeating(){
        //   if(this.centralVRV.childVRVState.switchState == "关"){this.$message({message: '请打开空调',type: 'warning',});return}
          this.changestatus.devState = '';
          this.changestatus.devAirTemperature = '';
          this.changestatus.devAirWindSpeed = '';
          this.changestatus.devAirPattern = '制热';
          this.changeStatus();
        //   this.defaultState();
        //   this.$refs.pointHeating.style.backgroundColor = '#53b552';
        },
        pointArefaction(){
        //   if(this.centralVRV.childVRVState.switchState == "关"){this.$message({message: '请打开空调',type: 'warning',});return}
          this.changestatus.devState = '';
          this.changestatus.devAirTemperature = '';
          this.changestatus.devAirWindSpeed = '';
          this.changestatus.devAirPattern = '除湿';
          this.changeStatus();
        //   this.defaultState();
        //   this.$refs.pointArefaction.style.backgroundColor = '#53b552';
        },
        pointAeration(){
        //   if(this.centralVRV.childVRVState.switchState == "关"){this.$message({message: '请打开空调',type: 'warning',});return}
          this.changestatus.devState = '';
          this.changestatus.devAirTemperature = '';
          this.changestatus.devAirWindSpeed = '';
          this.changestatus.devAirPattern = '送风';
          this.changeStatus();
        //   this.defaultState();
        //   this.$refs.pointAeration.style.backgroundColor = '#53b552';
        },
        getMsgFormSon(data) {
          //子组件传过来的值
          this.selCity.bdclickrow = data;
          console.log(this.selCity.bdclickrow)
        },
        //集控空调状态
        defaultState() {
          this.$refs.pointRefrigeration.style.backgroundColor = '#9e9f9f';
          this.$refs.pointHeating.style.backgroundColor = '#9e9f9f';
          this.$refs.pointArefaction.style.backgroundColor = '#9e9f9f';
          this.$refs.pointAeration.style.backgroundColor = '#9e9f9f';
        },
            currentChange(cursor) { //当前页面
                this.form.cursor = cursor
                this.$emit('update')
            },
            sizeChange(size) { //页面显示条数改变
                this.form.limit = size
                this.$emit('update')
            },
            // 333
            getAllStateB(ids, dataList) { //获取所有设备状态
                // console.log("dataList",dataList,'ids:',ids)
                this.isOpenOrClose=false
                this.form.dataList.length && this.$axios({
                    method: 'get',
                    url: `/common2/devices/getDevicesState`,
                    params: {
                        ids: ids
                    }
                }).then(res => {
                    let list=[]
                    // 设备列表 状态 
                    this.form.dataList.forEach(item=>{
                        for (let i in res.data.result) {
                            // console.log(res.data.result[i].stateInformation,'7787')
                            if(res.data.result[i].id == item.jourDeviceId){
                               item.stateInformation = res.data.result[i].stateInformation;
                              break;
                            }
                        }
                        // console.log(item,'7787')
                       list.push(item)
                    })
                    this.form.dataList=list
                    // // 设备列表 状态 
                    // this.form.dataList.forEach(item=>{
                    //     if(item.hasOwnProperty('stateInformation')){
                    //         return;
                    //     }else{
                    //       for (let i in res.data.result) {
                    //         this.$set(item, "stateInformation", res.data.result[i].stateInformation)
                    //       }
                    //     }
                    // })
                }).catch(err => {
                    this.isError(err, this)
                })
            },
            rowClick(row) { //选中一行
                this.form.clickrow = row
                this.changestatus.devSn = row.devSn
                this.changestatus.jourDeviceId = row.jourDeviceId
                this.panelForm.devSecondType = row.devSecondType
                this.panelForm.devFirstType = row.devFirstType
                this.panelForm.devBrandId = row.devBrandId
                console.log(this.form.clickrow,'单击row');
            },
            async caoZuoClick(row) { //操作设备按钮和表格双击
                console.log(row,'双击row');
                this.devicesId =row.jourDeviceId
                this.ammeterObj.isContinue = false
                this.enabled.devSn = row.devSn
                this.enabled.params.devId =  row.jourDeviceId
                this.infrareds.params.devId = row.jourDeviceId
                this.ammeterObj.devId = row.jourDeviceId
                this.currentElevator=row.devNickname+"-"+row.hsAddCommunity
                this.deviceId=row.jourDeviceId
                this.recordsName=row.devNickname
                this.recordsHsAddCommunity=row.hsAddCommunity
                this.scenePanelObj.hsId = row.hsId
                if(this.panelForm.waterMeter.indexOf(this.panelForm.devSecondType) > -1){
                 await this.axDeviceWarningInfo();
                   console.log(this.deviceDatas,'99999');
                }
                if (this.panelForm.CentralizedScenePanel.indexOf(this.panelForm.devSecondType) > -1) {
	    			this.$axios({
	    				method: 'get',
	    				url: `common2/scenarioPatternTables/getScenarioModeByHsId`,
	    				params: {
	    					hsId: row.hsId,
	    				},
	    			})
	    				.then((res) => {
	    					console.log(res)
	    					this.panelForm.records = res.data.result
	    				})
	    				.catch((err) => {
	    					this.isError(err, this)
	    				})
	    		}
                if (!this.form.clickrow) {
                    this.$message.warning('请先选中一条记录')
                 } else {
                     //混入的方法 autheEquipmentListMixin
                    if (typeof this.autheMixin('权限操作') == 'undefined') {
                        this.$message.warning('无权限操作')
                        return
                    }
                    if ( this.panelForm.devSecondType === 138 || this.panelForm.devSecondType === 139 ) {
                        this.showPowerState(this.form.clickrow.jourDeviceId)
                    } else if (this.panelForm.devSecondType === 110) {
                        this.showAmmeterState(this.form.clickrow.jourDeviceId)
                    } else if(this.panelForm.devFirstType == 36 && this.form.clickrow.devRoad ===0 && !this.form.clickrow.devSubsidiary){
                        this.VRVWebSocket()
                    } else if(this.panelForm.devFirstType == 36 && this.form.clickrow.devRoad !==0){
                        console.log("这是子空调")
                        this.getVRVStatus()
                    }else if(this.panelForm.scenePanel.indexOf(this.panelForm.devSecondType)>-1){
                        this.getAllSceneCategory()
                        this.checkSceneLinkageInfo()
                        this.scenePaneTable(this.panelForm.devSecondType)
                    } else if (this.panelForm.devFirstType != 33 || this.panelForm.devFirstType != 29) {
                        await this.getOneState(row)
                    }
                    if (this.panelForm.devSecondType == 18) {
                        this.getPlugInCardRecords();
                    } else if (this.panelForm.devSecondType == 57) {
                        this.dialogFloorHeating = true
                    } else if (this.panelForm.devSecondType == 146) {//梯控操作
                        this.getElevatorsFloorConfigure(row)
                    //空调
                    } else if (this.panelForm.devSecondType==137?true:this.panelForm.devSecondType==4?true:this.panelForm.devSecondType==42?true:this.panelForm.devSecondType==43?true:this.panelForm.devSecondType==49?true:false){
                        console.log("插座ing",this.form.clickrow.stateInformation)
                        this.dialogCaoZuoSheBei = true
                        
                        this.setIntervalAir=setInterval(() => {//每个5秒刷新一次
                            this.getOneState()
                        }, 5000);
                        if(this.form.clickrow.stateInformation!=undefined){//判断状态是否开跟关,没有就return
                            if(this.form.clickrow.stateInformation.indexOf("关")>-1){
                                this.isOpenOrClose=false
                            }else if(this.form.clickrow.stateInformation.indexOf("开")>-1){
                                this.isOpenOrClose=true
                            }
                        }else{
                            return
                        }
                    } else {
                        this.dialogCaoZuoSheBei = true
                    }
                    if([23,31,37].indexOf(this.panelForm.devFirstType) > -1){
                        console.log(this.changestatus.nowStates.colorTemperature,this.changestatus.nowStates.brightness)
                        this.changestatus.nowStates.colorTemperature = 0
                        this.changestatus.nowStates.brightness = 0
                        this.$nextTick(() => {
                            this.$set(this.changestatus.nowStates,"colorTemperature",50)
                            this.$set(this.changestatus.nowStates,"brightness",50)
                    })
                }
                    if(this.panelForm.devSecondType == 140){
                        // 4.12
                        this.detectorCpnsFlag = true
                    }
                }
                this.getAttachedQuery()
            },
            scenePaneTable(value){
                if(value == 161){
                    this.scenePanelObj.scenePaneList =[
                      { name: '1号 按键:', spdId: '',code: '', cpKeyValue: 1,disabled:false},
                      { name: '2号 按键:', spdId: '',code: '', cpKeyValue: 2,disabled:false},
                      { name: '3号 按键:', spdId: '',code: '', cpKeyValue: 3,disabled:false},
                      { name: '4号 按键:', spdId: '',code: '', cpKeyValue: 4,disabled:false},
                      { name: '5号 按键:', spdId: '',code: '', cpKeyValue: 5,disabled:false},
                      { name: '6号 按键:', spdId: '',code: '', cpKeyValue: 6,disabled:false},
                      { name: '7号 按键:', spdId: '',code: '', cpKeyValue: 7,disabled:false},
                      { name: '8号 按键:', spdId: '',code: '', cpKeyValue: 8,disabled:false},
                    ]
                }else if(value == 145){
                    this.scenePanelObj.scenePaneList =[
                      { name: '连通:', spdId: '',code: '', cpKeyValue: 1,disabled:false},
                      { name: '断开:', spdId: '',code: '', cpKeyValue: 2,disabled:false},
                    ]
                }
            },
            getAttachedQuery() {
          this.$axios({
            method: 'get',
            url: `/common2/devices/attached/query/${this.enabled.params.devId}`,
          })
            .then((res) => {
              console.log(res);
              if (res.data.result) {
                console.log(this.panelForm.devSecondType);
                if (this.panelForm.devSecondType != 136) {
                  this.ammeterObj.devId = res.data.result.jourDeviceId;
                }
                this.ammeterObj.isContinue = true;
              }
            })
            .catch((err) => {
              this.isError(err, this);
            });
        },
        //双击时查询梯控是否配置过
        getElevatorsFloorConfigure(row){
          const url = `ws://172.18.88.112:8112/camera/connectWebSocket/001`
          let thit = this
          let ws = thit.ws==null ? new WebSocket(url) : thit.ws;
          thit.ws = ws;
          console.log(ws)
          // 连接成功建立的回调方法
          ws.onopen = function(event){  
            console.log('WebSocket连接成功')
          }
          //接收到消息的回调方法
          ws.onmessage = (event) => {
            // console.log("后端发来消息了！",JSON.parse(event.data),JSON.parse(event.data)['当前楼层'])
            // this.floorInfoList[parseInt(JSON.parse(event.data)['当前楼层'])-1]
             this.$set(this.floorStateList,'nowFloor',this.floorInfoList[parseInt(JSON.parse(event.data)['当前楼层'])-1]+'楼')
             this.$set(this.floorStateList,'houState',JSON.parse(event.data)['后门状态'])
             this.$set(this.floorStateList,'qianState',JSON.parse(event.data)['前门状态'])
             this.$set(this.floorStateList,'operation',JSON.parse(event.data)['运行方向'])
          }
          //连接发生错误的回调方法
          ws.onerror = function(){
              console.log("WebSocket连接失败")
          };
            this.$axios({
                method:'post',
                url:'/common2/elevators/floorConfigure',
                data:{
                    id:row.jourDeviceId,
                    controlType:'查询楼层',
                    sn:row.devSn,
                    floor:[]
                }
            }).then(res=>{
                // console.log("梯控res:",res.data.result.msg)
                //判断楼层是否配置过
                if(res.data.result.msg=="未配置楼层"){
                    this.dialogCaoZuoSheBei = true
                }else{
                    this.dialogCaoZuoSheBei = false
                    this.isfloorInfo=true
                    this.floorInfoList=res.data.result.msg
                    console.log(parseInt(this.floorInfoList[2]))
                    this.floorNumberList =[]
                    for(let i = 0; i<=this.floorInfoList.length;i++){
                        if(parseInt(this.floorInfoList[i])){
                            this.floorNumberList.push(parseInt(this.floorInfoList[i],10))
                        }
                    }
                    console.log(this.floorNumberList)
                }
            })
        },
        // 获取中央空调状态 
        getVRVStatus() {
          let that=this;
          function VRVStatus(){
            that.changestatus.devState = '读指空';
            that.$axios({
              method: 'post',
              url: '/common2/devices/operate',
              data: {
                devId: that.form.clickrow.jourDeviceId,
                devState: that.changestatus.devState,
              },
            })
            .then((res) => {
              console.log("获取中央空调状态",res)
              if(that.ws){return}
              that.childVRVWebSocket()
            })
            .catch((err) => {
              that.isError(err, that);
            });
          };
          VRVStatus()
          clearInterval(this.centralVRV.time);
          // 每隔10秒刷新状态
          this.centralVRV.time = setInterval(VRVStatus, 5000);
        },
        //母空调建立长连接获取子空调数据
        VRVWebSocket(){
          const url = `wss://api.86xc.net/wss/channel?token=${sessionStorage.getItem('token')}`
        //   const url = `ws://${location.host}/wss/channel?token=${sessionStorage.getItem('token')}`
          let thit = this
          let ws = thit.ws==null ? new WebSocket(url) : thit.ws;
          thit.ws = ws;
          console.log(ws)
          // 连接成功建立的回调方法
          ws.onopen = function(event){
            console.log('WebSocket连接成功')
          }
          //接收到消息的回调方法
          ws.onmessage = (event) => {
            console.log("后端发来消息了！")
            let data = JSON.parse(JSON.parse(event.data).body)
            if(data){
                data.sort((a,b)=>{return Number(a.extMhNum)-Number(b.extMhNum)})    
                data.sort((a,b)=>{return Number(a.IntMhNum)-Number(b.IntMhNum)})
            }
            if(thit.centralVRV.buttonTest == "取消编辑"){return}
            thit.centralVRV.childTable = data
            console.log(data)
          }
          console.log(ws.readyState)
        },
        //长链接获取子空调状态
        childVRVWebSocket(){
          const url = `wss://api.86xc.net/wss/channel?token=${sessionStorage.getItem('token')}`
        //   const url = `ws://${location.host}/wss/channel?token=${sessionStorage.getItem('token')}`
          let thit = this
          let ws = thit.ws==null ? new WebSocket(url) : thit.ws;
          thit.ws = ws;
          console.log(ws)
          // 连接成功建立的回调方法
          ws.onopen = function(event){
            console.log('WebSocket连接成功')
          }
          //接收到消息的回调方法
          ws.onmessage = (event) => {
            console.log("后端发来消息了！")
            let body = JSON.parse(event.data).body
            let data = JSON.parse(body)
            console.log(data,this.form.clickrow,'来了来了')
            let { envirTemp, devTemp, switchState, pattern, windSpeed,intMhNum,extMhNum } = data;
            if(intMhNum!=this.form.clickrow.devRoad && extMhNum != this.form.clickrow.devSubsidiary){return}
            this.$set(this.centralVRV.childVRVState,"switchState",switchState?switchState:'关')
            if(envirTemp!=this.centralVRV.childVRVState.envirTemp){
              console.log("室温")
              this.centralVRV.childVRVState.envirTemp = data.envirTemp//室温
            };
            if(devTemp!=this.centralVRV.childVRVState.devTemp){
              console.log("空调温度")
              this.centralVRV.childVRVState.devTemp = Number(data.devTemp)//空调温度
              this.centralVRV.percentage = Number(data.devTemp)
              this.centralVRV.indoorTemperature = data.devTemp?data.devTemp:"--"
            };
            if(pattern!=this.centralVRV.childVRVState.pattern){
              //集控空调状态
              console.log("集控空调状态")
                this.centralVRV.childVRVState.pattern = data.pattern
              if(data.pattern == '制冷'){ 
                this.defaultState();
                this.$refs.pointRefrigeration.style.backgroundColor = '#53b552';
              }else if(data.pattern == '制热'){
                this.defaultState();
                this.$refs.pointHeating.style.backgroundColor = '#53b552';
              }else if(data.pattern == '除湿'){
                this.defaultState();
                this.$refs.pointArefaction.style.backgroundColor = '#53b552';
              }else if(data.pattern == '送风'){
                this.defaultState();
                this.$refs.pointAeration.style.backgroundColor = '#53b552';
              }
            };
            if(windSpeed!=this.centralVRV.childVRVState.windSpeed){
              //集控速度调节
              console.log("集控速度调节")
                this.centralVRV.childVRVState.windSpeed = data.windSpeed
              if(data.windSpeed == '高速'){
                this.$set(this.centralVRV,"windSpeedPercentage",99.99)
              }else if(data.windSpeed == '中速'){
                this.$set(this.centralVRV,"windSpeedPercentage",66.66)
              }else if(data.windSpeed == '低速'){
                this.$set(this.centralVRV,"windSpeedPercentage",33.33)
              }
            };
            // this.centralVRV.childVRVState = data
            // this.$refs.
            console.log(ws.readyState)
          }
        },
        isObjEqual(o1,o2){
          var props1 = Object.getOwnPropertyNames(o1);
          var props2 = Object.getOwnPropertyNames(o2);
          if (props1.length != props2.length) {
            return false;
          }
          for (var i = 0,max = props1.length; i < max; i++) {
            var propName = props1[i];
            if (o1[propName] !== o2[propName]) {
              return false;
            }
          }
          return true;
        },
        async getOneState(row) { //获取单条设备状态
            if (this.panelForm.devFirstType == 3) {
                this.changestatus.houseAddress = row.hsAddCommunity
                this.changestatus.jdcHsId = row.hsId
            }
            return this.$axios({
                method: 'get',
                url: `/common2/devices/getDevicesState`,
                params: {
                    ids: this.form.clickrow.jourDeviceId,
                }
            }).then(res => {
                this.radarStatus = res.data.result[0]
                this.hydropowers = res.data.result[0]
                let {lastMonthReading,thisMonthReading} = res.data.result[0]
                this.latsReading =lastMonthReading?lastMonthReading:""
                this.thisReading =thisMonthReading?thisMonthReading:""
                // =========================
                res.data.result[0].brightness = parseInt(res.data.result[0].brightness)
                this.changestatus.nowStates = res.data.result[0]
                this.changestatus.percentCurtain = res.data.result[0].devPercentage
                this.changestatus.devAirTemperature = this.changestatus.nowStates.temperature==undefined?'26':this.changestatus.nowStates.temperature
                this.changestatus.devAirPattern = this.changestatus.nowStates.mode
                this.changestatus.devState = this.changestatus.nowStates.switchingState
                this.changestatus.devAirWindSpeed = this.changestatus.nowStates.speed
                this.changestatus.devAirFactors = this.changestatus.nowStates.powerFactor
                this.changestatus.devAirCurrent = this.changestatus.nowStates.electricCurrent
                this.changestatus.devAirVoltage = this.changestatus.nowStates.voltage
                this.changestatus.devAirPower = this.changestatus.nowStates.power
                this.changestatus.devAirElectricity = this.changestatus.nowStates.electricityConsumption
                this.changestatus.devAirSweepingWind = this.changestatus.nowStates.airSpeedWind
                this.changestatus.devAirTemperature2 = this.changestatus.nowStates.deviceTemperature
                this.changestatus.devAirProtectTemperature = this.changestatus.nowStates.protectionTemperature
                // 检测仪获取数据 4.12
                if(this.panelForm.devSecondType == 140){
                    this.detectorData = res.data.result[0]
                }
                if (this.panelForm.devSecondType == 57) {
                    if (this.changestatus.nowStates.floorHeatingState != undefined) {
                        this.floorHeating.floorHeatingState = this.changestatus.nowStates.floorHeatingState
                        this.floorHeating.lockButtonType = this.floorHeating.floorHeatingState.deviceLock == '关' ? 'info' : 'primary'
                        this.floorHeating.switchButtonType = this.floorHeating.floorHeatingState.switchingState == '关' ? 'info' : 'primary'
                    }
                }
                if (this.panelForm.devSecondType == 68 || this.panelForm.devSecondType == 69 || this.panelForm.devSecondType == 70) {
                    let result = res.data.result[0]
                    //白光状态赋值
                    this.changestatus.whiteBrightness = result.whiteBrightness
                    this.changestatus.colorTemperature = result.colorTemperature
                    this.changestatus.whiteMode = result.whiteMode
                    //彩光状态赋值
                    this.changestatus.rgbBrightness = result.rgbBrightness
                    this.changestatus.rgbMode = result.rgbMode
                    this.changestatus.rgbSpeed = result.rgbSpeed
                    this.color3 = `rgb(${result.rcolorValue}, ${result.gcolorValue}, ${result.bcolorValue})`
                }
                if(this.panelForm.airConditioner[4]==137){
                    // console.log('单条',res.data.result)
                    this.form.result=res.data.result
                }
                if (!res.data.result[0].online && this.panelForm.devFirstType != 29) {
                    this.$message.warning('设备不在线！')
                }
                if(this.panelForm.controlBox.indexOf(this.panelForm.devSecondType) > -1){
                    let {actionTime,delayTime,status } = res.data.result[0]
                    this.controlObj.actionTime = actionTime
                    this.controlObj.delayTime = delayTime
                    this.controlObj.state = status.slice(6,8)
                    console.log( this.controlObj)
                }
                return
            }).catch(err => {
                this.isError(err, this)
            })
        },
        // 222
        // radarControl(){
        //     this.$axios({
        //         method: "post",
        //         url: '/common2/devices/operate',
        //         data:{
        //             devId: this.form.clickrow.jourDeviceId,
        //             fallSwitch: this.changestatus.fallSwitch,
        //             fallAlarmTime:this.changestatus.fallAlarmTime,
        //         }
        //     }).then(res=>{
        //         console.log(res,'123')
        //     })
        // },
        // 222
        changeStatus(id) { //改变设备状态
            console.log(id,'111111')
            this.loading5 = true
            this.$axios({
                method: "post",
                url: '/common2/devices/operate',
                data: {
                    devAirPattern: this.changestatus.devAirPattern,
                    devAirSweepingWind: this.changestatus.devAirSweepingWind,
                    devAirTemperature: this.changestatus.devAirTemperature,
                    devAirWindSpeed: this.changestatus.devAirWindSpeed,
                    devBrightness: this.changestatus.nowStates.brightness,
                    devColorTemperature: this.changestatus.nowStates.colorTemperature,
                    devId: this.form.clickrow.jourDeviceId,
                    devLowerLimitLumen: this.changestatus.devLowerLimitLumen,
                    devLumenSpacing: this.changestatus.devLumenSpacing,
                    devNumber: this.changestatus.devNumber,
                    devState: this.changestatus.devState,
                    devUpperLimitLumen: this.changestatus.devUpperLimitLumen,
                    devPercentage: (id!==108 && this.panelForm.devSecondType == 108)? null :this.changestatus.percentCurtain
                }
            }).then((res) => {
                console.log(res,'6666')
                this.loading5 = false
                let arr = [this.form.clickrow]
                setTimeout(() => {
                    //this.getAllStateB(this.form.clickrow.jourDeviceId, arr)
                }, 600)
                let result =  JSON.parse(res.data.result)
                if(result.data[0].resultCode){
                    this.$message.warning(result.data[0].resultMsg)
                    return
                }
                this.$message.success('控制成功')
            }).catch(err => {
                this.loading5 = false
                this.isError(err, this)
            })
        },
        sceneControl(row) {
			this.$axios({
				method: 'post',
				url: `/common2/scenarioPatternTables/modeControl`,
				params: {
					jsroId: row.jsroId,
				},
			})
				.then((res) => {
					console.log(res)
					if (res.data.status == 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
						})
					}
				})
				.catch((err) => {
					this.isError(err, this)
				})
		},
        openRoad() {
            this.changestatus.devState = "开"
            this.changestatus.devNumber = this.form.clickrow.devRoad
            this.isSWShow=true
            this.changeStatus()
        },
        closeRoad() {
            this.changestatus.devState = "关"
            this.changestatus.devNumber = this.form.clickrow.devRoad
            this.isSWShow=false
            this.changeStatus()
        },
        //匹配设备
        pairEq() {
            this.$axios({
                method: 'get',
                url: `/common2/devices/deviceMatching/${this.changestatus.jourDeviceId}`,
            })
                .then((res) => {
                    if (res.data.status == 200) {
                        this.$message({
                            message: '配对成功',
                            type: 'success',
                        })
                    } else {
                        this.$message({
                            message: '配对失败',
                            type: 'error',
                        })
                    }
                    console.log(res)
                })
                .catch((err) => {
                    this.isError(err, this)
                })
        },
        pairedClick() {//查询配对全部设备
            console.log("hhh")
            this.dialogpairedDev = true
            this.pairTable = true
            return
        },
        closeDialogpairedDev(){
            this.pairTable = false
        },
        seleRoomNext(){
            console.log(this.selCity.bdclickrow)
            if (!this.selCity.bdclickrow) {
                this.$message.warning('请选择一个房间绑定');
                return;
            }
            this.dialogpairedDev = false
            this.pairTable = false
            this.dialogPairing = true;
            this.loading1 = true;
            this.$axios({
                method: "get",
                url: `/common2/devices/pairableDevice/${this.form.clickrow.jourDeviceId}`,
                params: {
                    hsId:this.selCity.bdclickrow.hsId
                }
            })
            .then((res) => {
                for (let item of res.data.result) {
                    item.key = item.jourDeviceId
                    item.label = `${item.address.substring(0,10)}-${item.devNickname}`
                }
                //查询已配对
                this.$axios({
                    method: "get",
                    url: `/common2/jourDevicePairRecords/pairedRecords`,
                    params: {
                        deviceId: this.form.clickrow.jourDeviceId
                    }
                })
                .then((res2) => {
                    this.loading1 = false;
                    let result = res2.data.result;
                    console.log(result)
                    this.pairingObj.pairedDevices = result
                    res.data.result.map(item => {
                        let back = this.pairingObj.pairedDevices.indexOf(item.jourDeviceId)
                        if(back !== -1 && (item.devSecondType == 92 || item.devSecondType == 89)) {
                            item.disabled = true
                        }
                    })
                    this.$axios({
                        method: "get",
                        url: `/common2/devices/house4stores`,
                    }).then((res3) => {
                        let data = res3.data.result
                        for (let item of data) {
                            for (let item2 of res.data.result) {
                                if (item.jourDeviceId == item2.jourDeviceId) {
                                    item2.hsAddDoorplateno = item.hsAddDoorplateno
                                }
                            }
                        }
                        this.pairingObj.dataList = res.data.result;
                    }).catch((err) => {
                        this.isError(err, this);
                    });
                })
                .catch((err) => {
                    this.loading1 = false;
                    this.isError(err, this);
                });
            })
            .catch((err) => {
                this.loading1 = false;
                this.isError(err, this);
            });
        },
        clearBinding() {
            this.loading1 = true
            this.$axios({
                method: 'POST',
                url: `/common2/devices/clearBinding/${this.form.clickrow.jourDeviceId}`,
            }).then(res => {
                this.$message({
                    message: '清除解绑成功',
                    type: 'success'
                });
                this.loading1 = false
            }).catch(err => {
                this.loading1 = false
                this.isError(err, this)
            })
        },
        pairingClose() { //配对关闭清空
            this.pairingObj.pairedDevices = []
            this.pairingObj.dataList = []
        },
        pairingSave() {
            this.loading1 = true
            for (let item of this.pairingObj.pairedDevices) {
                for (let i in this.pairingObj.dataList) {
                    if (item == this.pairingObj.dataList[i].jourDeviceId) {
                        this.pairingObj.dataList.splice(i, 1)
                    }
                }
            }
            let unpairedDevice = []
            this.pairingObj.dataList.forEach(item => {
                unpairedDevice.push(item.jourDeviceId)
            })
            this.$axios({
                method: 'post',
                url: `/common2/devices/batchPairAndRelieve/${this.form.clickrow.jourDeviceId}`,
                data: this.pairingObj.pairedDevices,
            }).then(res => {
                this.loading1 = false
                this.dialogPairing = false
                this.pairingObj.errList = res.data.result
                console.log(res.data.result.unsupportedType)
                let unsupportedType = res.data.result.unsupportedType
                if (unsupportedType.length) {
                    for (let item of unsupportedType) {
                        this.$message.warning(`${item}暂不支持解除配对，请到该类型的设备控制清除绑定`)
                    }
                }
            }).catch(err => {
                this.loading1 = false
                this.isError(err, this)
            })
        },
        
    //操作设备
    closeVRV(){
    //   if(this.centralVRV.childVRVState.switchState == "关"){this.$message.warning('空调已关闭！');;return}
    //   this.centralVRV.state = "关"
    //   this.centralVRV.percentage = 16;
      this.changestatus.devState = '关';
      this.changestatus.devAirTemperature = '';
      this.changestatus.devAirPattern = '';
      this.changestatus.devAirWindSpeed = '';
      this.changeStatus();
      // this.windSpeed()
    //   this.defaultState()
    },
    openVRV(){
    //   if(this.centralVRV.childVRVState.switchState == "开"){this.$message.warning('空调已开启！');return}
    //   this.centralVRV.state = "开"
      this.changestatus.devState = '开';
      this.changestatus.devAirTemperature = '';
      this.changestatus.devAirPattern = '';
      this.changestatus.devAirWindSpeed = '';
      this.changeStatus();
    },
    queryAirFacility(){
        this.centralVRV.gatherLoading = true
        this.loading5 = true
        console.log(this.panelForm.devSecondType)
        this.$axios({
            method: "post",
            url: '/common2/devices/operate',
            data: {
                devId: this.form.clickrow.jourDeviceId,
                devState: "读外",
            }
        }).then((_) => {
            this.loading5 = false
            //WebSocket
            console.log(this.ws)
            if(this.ws &&this.panelForm.devFirstType == 36 && this.form.clickrow.devRoad ===0 && !this.form.clickrow.devSubsidiary){this.centralVRV.childDialog = true;return}
            this.$message.success('控制成功')
            
        }).catch(err => {
            this.loading5 = false
            this.isError(err, this)
        })
        },
        openLight() {
            this.changestatus.devState = "开"
            this.changeStatus()
            this.isOpenOrClose=true
            this.issocketShow=true
        },
        closeLight() {
            this.changestatus.devState = "关"
            this.changeStatus()
            this.isOpenOrClose=false
            this.issocketShow=false
        },
        
        decrease(){
        //   if(this.centralVRV.childVRVState.switchState == "关"){this.$message({message: '请打开空调',type: 'warning',});return}
          if(this.centralVRV.percentage<=16){this.$message({message: '已是最小温度',type: 'warning',});return}
          this.centralVRV.percentage--;
          this.centralVRV.indoorTemperature = this.centralVRV.percentage;
          if (this.centralVRV.timer) {
              clearTimeout(this.centralVRV.timer)
          }
          let that = this
          this.centralVRV.timer = setTimeout(function () {
            that.changestatus.devState = '';
            that.changestatus.devAirPattern = '';
            that.changestatus.devAirWindSpeed = ""
            that.changestatus.devAirTemperature = that.centralVRV.percentage
            that.changeStatus();
          }, 1000)
          console.log(this.centralVRV.percentage)
        },
        increase(){
        //   if(this.centralVRV.childVRVState.switchState == "关"){this.$message({message: '请打开空调',type: 'warning',});return}
          if(this.centralVRV.percentage>=30){this.$message({message: '已是最大温度',type: 'warning',});return}
          this.centralVRV.percentage++
          this.centralVRV.indoorTemperature = this.centralVRV.percentage
          if (this.centralVRV.timer) {
              clearTimeout(this.centralVRV.timer)
          }
          let that = this
          this.centralVRV.timer = setTimeout(function () {
            that.changestatus.devState = '';
            that.changestatus.devAirPattern = '';
            that.changestatus.devAirWindSpeed = ""
            that.changestatus.devAirTemperature = that.centralVRV.percentage
            that.changeStatus();
          }, 1000)
          console.log(this.centralVRV.percentage)
        },
        windSpeedDIncrease(){
        //   if(this.centralVRV.childVRVState.switchState == "关"){this.$message({message: '请打开空调',type: 'warning',});return}
          if(this.centralVRV.windSpeedPercentage >= 99.99) {this.$message({message: '已是最大风速',type: 'warning',});return}
            this.centralVRV.windSpeedPercentage += 33.33;
          if(this.centralVRV.windSpeedPercentage == 33.33){
            this.changestatus.devAirWindSpeed = "低速"
          }else if(this.centralVRV.windSpeedPercentage == 66.66){
            this.changestatus.devAirWindSpeed = "中速"
          }else if(this.centralVRV.windSpeedPercentage == 99.99){
            this.changestatus.devAirWindSpeed = "高速"
          }
          let that = this
          if (this.centralVRV.timer) {clearTimeout(this.centralVRV.timer)}
          this.centralVRV.timer = setTimeout(function () {
            that.changestatus.devState = '';
            that.changestatus.devAirTemperature = '';
            that.changestatus.devAirPattern = '';
            that.changeStatus();
          }, 1000)
        },
        windSpeedDecrease(){
        //   if(this.centralVRV.childVRVState.switchState == "关"){this.$message({message: '请打开空调',type: 'warning',});return}
          if(this.centralVRV.windSpeedPercentage <= 33.33){this.$message({message: '已是最小风速',type: 'warning',});return}
            this.centralVRV.windSpeedPercentage -= 33.33;
          if(this.centralVRV.windSpeedPercentage == 33.33){
            this.changestatus.devAirWindSpeed = "低速"
          }else if(this.centralVRV.windSpeedPercentage == 66.66){
            this.changestatus.devAirWindSpeed = "中速"
          }else if(this.centralVRV.windSpeedPercentage == 99.99){
            this.changestatus.devAirWindSpeed = "高速"
          }
          let that = this
          if (this.centralVRV.timer) {clearTimeout(this.centralVRV.timer)}
          this.centralVRV.timer = setTimeout(function () {
            that.changestatus.devState = '';
            that.changestatus.devAirTemperature = '';
            that.changestatus.devAirPattern = '';
            that.changeStatus();
          }, 1000)
          console.log(this.centralVRV.windSpeedPercentage)
        },
        VRVformat(){
            if (this.centralVRV.windSpeedPercentage == 33.33) {
            return '低速'
          }else if(this.centralVRV.windSpeedPercentage == 66.66){
            return '中速'
          }else if(this.centralVRV.windSpeedPercentage == 99.99){
            return '高速'
          }
        },
        // 红外开关
        infraredSwitch(type, value) {
            let data = this.infrareds.params
            if (type == 'switchs') {
                data.switchs = value
            } else if (type == 'spacing') {
                data.spacing = Number(value)
            } else if (type = 'threshold') {
                data.threshold = Number(value)
            } else if (type == 'sensitivity') {
                data.sensitivity = Number(value)
            }
            this.$axios({
                method: 'post',
                url: `/common2/devices/operate`,
                data
            }).then(res => {
                this.$message.success('操作成功')
            }).catch(err => {
                this.isError(err, this);
            })
        },
        infraredReport() { //红外报警器
        console.log('9999999')
        console.log(this.form.clickrow,'888888')
            this.loading5 = true
            this.$axios({
                method: 'get',
                url: `/common2/smokeAlertEvents`,
                params: {
                    sn: this.form.clickrow.devSn
                }
            }).then(res => {
                this.loading5 = false
                this.infraredReportArr = res.data.result
                this.infraredReportDialog = true
            }).catch(err => {
                this.isError(err, this)
                this.loading5 = false
            })
        },
        // 获取带温控计量插座状态
        showPowerState(deviceId) {
            let that = this
            function getPowerState() {
                that.$axios({
                    method: 'get',
                    url: `/common2/devices/getDevicesState`,
                    params: {
                        ids: deviceId
                    },
                })
                .then((res) => {
                    let data = res.data.result[0]
                    for (let item in data) {
                        that.powerState[item] = data[item]
                    }
                    that.powerState.thresholdPower = parseFloat(that.powerState.thresholdPower)
                })
                .catch((err) => {
                    that.isError(err, that);
                })
            }
            getPowerState()
            clearInterval(this.getStateTiming)
            //每隔3秒刷新状态
            this.getStateTiming = setInterval(getPowerState,3000);
        },
        // 清除定时器
        hidePowerState() {
            clearInterval(this.getStateTiming)
        },
        // 操作带温控计量插座
        setPowerState: debounce(function(data) {
            this.$axios({
                method: 'post',
                url: `/common2/devices/operate`,
                data: data
            }).then((_) => {
                this.$message.success('操作成功,稍后生效');
            }).catch((err) => {
                this.isError(err, this);
            });
        }, 500),
        // 操作带温控计量插座的电流电压
        setCurrentOrVoltage(data) {
            console.log(data,'6666')
            if(data.devSecondType == 138){
              let limitedCurrent = data.limitedCurrent && data.limitedCurrent.toFixed(2); // 电流
              let limitedVoltage = data.limitedVoltage && data.limitedVoltage.toFixed(2); // 电压
              this.$axios({
                method: 'put',
                url: `/common2/deviceInformation/${data.sn}`,
                data: {
                  limitedCurrent: limitedCurrent, 
                  limitedVoltage: limitedVoltage,
                },
              })
              .then((res) => {
                this.$message.success('操作成功,稍后生效');
              })
              .catch((err) => {
                this.isError(err, this);
              });
            }else if(data.devSecondType == 139){
              let limitedCurrent = data.limitedCurrent && data.limitedCurrent.toFixed(2); // 电流
              let limitedVoltage = data.limitedVoltage && data.limitedVoltage.toFixed(2);// 电压
              let type = '';
              let value = '';
              if(limitedCurrent){
                type ="limitedCurrent";
                value = limitedCurrent;
              }else if(limitedVoltage){
                type ="limitedVoltage";
                value = limitedVoltage;
              };
              this.$axios({
                method: 'put',
                url: `/common2/devices/modify/customParam/${data.id}`,
                params: {
                  type: type,
                  value: value, 
                },
              })
              .then((_) => {
                this.$message.success('操作成功,稍后生效');
              })
              .catch((err) => {
                this.isError(err, this);
              });
            }
        },
        showVisible(visible) {
            visible = !visible
            this.thresholdVoltage = JSON.parse(JSON.stringify(this.powerState.thresholdVoltage))
            this.limitingCurrent = JSON.parse(JSON.stringify(this.powerState.limitingCurrent))
            this.residualOperatCurrent = JSON.parse(JSON.stringify(this.powerState.residualOperatCurrent))
            this.limitPowerWarningValue = JSON.parse(JSON.stringify(this.powerState.limitPowerWarningValue));
        },
        closeCaoZuoSheBei() {
            let arr=[this.form.clickrow]
            this.hidePowerState()
            this.detectorCpnsFlag = false
            let powerState = {
                switchingStatus: '',
                totalElecPower: '- -',
                electricityRemaining: '- -',
                voltage: '- -',
                power: '- -',
                electricCurrent: '- -',
                thresholdVoltage: '- -',
                thresholdPower: '- -',
                limitingCurrent: '- -',
                deviceTemperature: '- -',
                thresholdTemp: '- -',
                powerOnStatus: '- -',
                equipmentType: '- -',
                subEquipNum: '- -',
                residualOperatCurrent: '- -',
                id: '- -',
                switchingState: '- -',
                limitPowerWarningValue: '- -',
            };
            this.powerState = Object.assign({}, powerState)
            if(this.ws){
              this.ws.close();
              this.ws=null
            }
            if(this.centralVRV.time){
              clearInterval(this.centralVRV.time);
              this.centralVRV.time=null;
            }
            clearInterval(this.setIntervalAir)
        },
        // 操作带温控计量插座
        handleCommand(command) {
            this.setPowerState({devId: this.powerState.id, ...command})
            console.log("command:",this.powerState.id)
        },
        showAmmeterState(deviceId) {
            let that = this
            function getAmmeterState() {
                that.$axios({
                    method: 'get',
                    url: `/common2/devices/getDevicesState`,
                    params: {
                        ids: deviceId
                    },
                })
                .then((res) => {
                    let data = res.data.result[0]
                    for (let item in data) {
                        that.ammeterState[item] = data[item]
                    }
                })
                .catch((err) => {
                    that.isError(err, that);
                })
            }
            getAmmeterState()
            clearInterval(this.getStateTiming)
            // 每隔10秒刷新状态
            this.getStateTiming = setInterval(getAmmeterState,10000);
        },
        getPlugInCardRecords() {
            this.$axios({
                method: 'get',
                url: `/common2/plugInCards`,
                params: {
                    sn: this.changestatus.devSn,
                    cursor: this.plugInCardRecords.cursor,
                    limit: this.plugInCardRecords.limit
                }
            }).then(res => {
                this.plugInCardRecords.dataList = res.data.result.records
                for (let i of this.plugInCardRecords.dataList) {
                    i.jpicRecordType = "客户卡"
                }
                this.plugInCardRecords.total = res.data.result.total
                this.dialogPlugInCard = true
            }).catch(err => {
                this.dialogPlugInCard = false
                this.isError(err, this)
            })
        },
        getGateLockRecords() {
            this.changestatus.devSn = this.form.clickrow.devSn
            this.$axios({
                method: 'get',
                url: `/common2/GateLockRecord/${this.changestatus.devSn}/getLockRecords`,
                params: {
                    cursor: this.gateLockRecords.cursor,
                    limit: this.gateLockRecords.limit
                }
            }).then(res => {
                this.gateLockRecords.dataList = res.data.result.records
                this.gateLockRecords.total = res.data.result.total
                this.dialogGateLockRecords = true
            }).catch(err => {
                this.dialogGateLockRecords = false
                this.isError(err, this)
            })
        },
        setUpIC(state) { //添加注销IC卡
            this.card.ICTitle = state ? '添加在线门卡' : '注销在线授权'
            this.card.ICLabel = state ? '授权卡' : '注销卡'
            this.card.ICButton = state ? '注册' : '注销'
            this.card.state = state ? '注册IC卡' : '注销IC卡'
            this.dialogIC = true
        },
        lockControl(devState) {
            this.changestatus.devState = devState
            this.changestatus.jdcPassword = ""
            this.doControlDevice()
        },
        //单个设备控制
        doControlDevice() {
            return new Promise((resolve, reject) => {
                this.loading5 = true
                this.changestatus.devId = this.form.clickrow.jourDeviceId
                this.$axios({
                    method: "post",
                    url: '/common2/devices/operate',
                    data: this.changestatus,
                }).then(res => {
                    this.offlinePassword = res.data.result
                    this.dialogIC = false
                    this.loading5 = false
                    this.password.loading = false
                    this.dialogAddPassword = false
                    this.$message.success('控制成功')
                    let arr = [this.form.clickrow]
                    setTimeout(() => {
                        //this.getAllStateB(this.form.clickrow.jourDeviceId, arr)
                    }, 600)
                    resolve(this.offlinePassword)
                }).catch(err => {
                    this.password.loading = false
                    this.loading5 = false
                    this.isError(err, this)
                })
            })
        },
        getAttachedQuery() {
            this.$axios({
                method: "get",
                url: `/common2/devices/attached/query/${this.enabled.params.devId}`,
            }).then((res) => {
                if(res.data.result){
                    if(this.panelForm.devSecondType!=136){
                        this.ammeterObj.devId = res.data.result.jourDeviceId
                    }
                    this.ammeterObj.isContinue = true
                }
            }).catch((err) => {
                this.isError(err, this);
            });
        },
        addPassword() {
            this.passwordTitle = '添加在线密码'
            this.dialogAddPassword = true
            this.password.firstType = "在线密码"
            // this.panelForm.devSecondType = 58
            this.clearPasswordDialog(0)
        },
        clearPasswordDialog(type) {
            for (let i in this.changestatus) { //修改设备状态数据
                if (i == 'devSn') {
                    continue
                } else if (i == 'devId') {
                    continue
                } else if (i == 'encryptMode') {
                    continue
                } else {
                    this.changestatus[i] = ''
                }
            }
            this.password.rules.mima = []
            this.password.rules.offlineBeginTime = []
            this.password.rules.offlineUnlockTimes = []
            this.password.rules.onlineDeadline = []
            this.password.rules.offlineTimeArr = []
            if (type == 0) {
                if (this.password.firstType == "在线密码") {
                    this.password.rules.mima = [{
                        required: true,
                        message: '密码不能为空',
                        trigger: 'blur'
                    }, {
                        min: 6,
                        max: 10,
                        message: '密码不能为空长度为 6 到 10 个字符',
                        trigger: 'blur'
                    }]
                    if (this.panelForm.devSecondType == 58) {
                        this.password.offlineSecondType = 0
                        this.password.offlineBeginTime = ""
                        this.password.offlineUnlockTimes = ""
                        this.password.offlineTimeArr = []
                    }
                } else if (this.password.firstType == "离线密码") {
                    if (this.panelForm.devSecondType == 58) {
                        this.password.rules.offlineBeginTime = [{
                            required: true,
                            message: '开始时间不能为空',
                            trigger: 'change',
                        }]
                        this.password.rules.offlineUnlockTimes = [{
                            required: true,
                            message: '次数不能为空',
                            trigger: 'blur'
                        }, {
                            min: 1,
                            max: 50,
                            message: '次数不能为空长度为 6 到 10 个字符',
                            trigger: 'blur'
                        }]
                    }
                    this.password.onlineSecondType = "限次密码"
                    this.password.mima = ""
                    this.password.onlineUnlockTimes = ""
                }
            } else if (type == 1) {
                // if(this.password.offlineSecondType == 0){
                this.password.rules.offlineBeginTime = [{
                    required: true,
                    message: '授权时间不能为空',
                    trigger: 'change',
                }]
                this.password.rules.offlineUnlockTimes = [{
                    required: true,
                    message: '次数不能为空',
                    trigger: 'blur'
                }, {
                    min: 1,
                    max: 50,
                    message: '次数不能为空长度为 6 到 10 个字符',
                    trigger: 'blur'
                }]
                this.password.offlineTimeArr = []
                // } else {
                //     console.log("================")
                //   this.password.rules.offlineTimeArr = [{type: 'array',required: true,message: '期限不能为空',trigger: 'change'}]
                //   this.password.offlineBeginTime = ""
                //   this.password.offlineUnlockTimes = ""
                // }
            } else if (type == 2) {
                this.password.mima = ""
                this.password.rules.mima = [{
                    required: true,
                    message: '密码不能为空',
                    trigger: 'blur'
                }, {
                    min: 6,
                    max: 10,
                    message: '密码不能为空长度为 6 到 10 个字符',
                    trigger: 'blur'
                }]
                if (this.password.onlineSecondType == '限次密码') {
                    this.password.onlineDeadline = ''
                } else {
                    this.password.onlineUnlockTimes = null
                }
            }
            //选择小时不能小于当前时间
            for (let i of this.optionsHours) {
                i.disabled = i.label < dateFtt('hh', new Date()) ? true : false
            }
            if (this.password.offlineSecondType == 0) {
                this.indefinitely = '次数'
            } else if (this.password.offlineSecondType == 1) {
                this.indefinitely = '时数'
            } else if (this.password.offlineSecondType == 2) {
                this.indefinitely = '天数'
            }
            this.password.offlineUnlockTimes = ''
            this.password.offlineDate = ''
            this.password.offlineHours = ''
            this.password.offlineMinutes = ''
            this.password.failureDate = ''
            this.password.failureHours = ''
            this.password.failureMinutes = ''
            this.password.title = ''
            this.effectTimeShow = true
            this.showMinutes = true
            // this.resetForm(['addPassword'],this)
        },
        offlinePasswordBtn() {
            this.passwordTitle = '添加离线密码'
            this.dialogAddPassword = true
            this.password.firstType = "离线密码"
            // this.panelForm.devSecondType = 58
            this.clearPasswordDialog(0)
        },
        addIC() {
            this.card.ICTitle = '添加离线门卡'
            this.password.firstType = '离线密码'
            this.panelForm.devSecondType = 58
            this.dialogAddIC = true
            this.clearPasswordDialog()
        },
        offlinePswfailure() { //离线密码失效
            this.$axios({
                method: "post",
                url: '/common2/devices/operate',
                data: {
                    devId: this.form.clickrow.jourDeviceId,
                    devState: '离线密码失效'
                }
            }).then(res => {
                this.$message.success('操作成功')
            }).catch(err => {
                this.isError(err, this)
            })
        },
        AlwaysOnBtn() { //常开模式
            this.changestatus.devState = "常开模式"
            this.doControlDevice()
        },
        async initializeBtn() {
            this.password.firstType = '离线密码'
            // this.panelForm.devSecondType = 58
            this.clearPasswordDialog()
            this.changestatus.encryptMode = this.password.offlineSecondType
            this.changestatus.jdcMaxUnlockingTimes = '999'
            this.changestatus.startTime = dateFtt('yyyy-MM-dd hh:00:00', new Date())
            this.changestatus.devId = this.form.clickrow.jourDeviceId
            this.changestatus.authorizeType = 2
            this.changestatus.devState = '添加密码'
            let itePwd = await this.authorization()
            let initialize1 = itePwd.slice(0, 3)
            let initialize2 = itePwd.slice(3, 6)
            let initialize3 = itePwd.slice(6, 9)
            this.initializePwd = `${initialize1} ${initialize2} ${initialize3}`
            this.dialogDoorLock = true
        },
        authorization() {
            return this.$axios({
                method: "post",
                url: '/common2/devices/operate',
                data: this.changestatus
            }).then(res => {
                return res.data.result
            }).catch(err => {
                this.loading5 = false
                this.card.loading = false
                this.dialogAddIC = false
                this.isError(err, this)
            })
        },
        getAuthorizationRecord() {
            this.$axios({
                method: 'get',
                url: `/common2/doorCard/getAuthorizationRecord/${this.form.clickrow.jourDeviceId}`,
                params: {
                    cursor: this.authorizationRecord.cursor,
                    limit: this.authorizationRecord.limit
                }
            }).then(res => {
                this.authorizationRecord.dataList = res.data.result.records
                this.authorizationRecord.total = res.data.result.total
                this.authorizationRecord.dialogAuthorizationRecord = true
            }).catch(err => {
                this.isError(err, this)
            })
        },
        closeDialogAddPassword() {
            this.resetForm(['addPassword'], this)
            this.password.authorizeType = ''
            this.password.firstType = '在线密码'
            this.password.user = ''
            this.password.userId = ''
            this.password.onlineSecondType = '限次密码'
            this.password.mima = ''
            this.password.onlineUnlockTimes = ''
            this.password.onlineDeadline = ''
            this.password.offlineSecondType = 0
            this.password.offlineBeginTime = ''
            this.password.offlineUnlockTimes = ''
            this.password.offlineTimeArr = []
            this.password.offlineHours = ''
            this.password.offlineMinutes = ''
            this.password.failureDate = ''
            this.password.failureHours = ''
            this.password.failureMinutes = ''
            this.password.offlineDate = ''
        },
        selectUser(arr) {
            this.password.userId = arr[2]
        },
        indefinitelyChange() {
            this.resetForm(['addPassword'], this)
            this.effectTimeShow = false
            this.showMinutes = false
            let offlineUnlockTimes = this.password.offlineUnlockTimes
            if (this.password.offlineSecondType == '0') {
                if (offlineUnlockTimes > 9 || offlineUnlockTimes < 1 || offlineUnlockTimes % 1 != 0) {
                    this.$message.warning('请输入1-9的次数')
                    this.password.offlineUnlockTimes = ''
                }
            } else if (this.password.offlineSecondType == '1') {
                if (offlineUnlockTimes > 168 || offlineUnlockTimes < 1 || offlineUnlockTimes % 1 != 0) {
                    this.$message.warning('请输入1-168的小时')
                    this.password.offlineUnlockTimes = ''
                }
            } else if (this.password.offlineSecondType == '2') {
                if (offlineUnlockTimes > 750 || offlineUnlockTimes < 1 || offlineUnlockTimes % 1 != 0) {
                    this.$message.warning('请输入1-750的天数')
                    this.password.offlineUnlockTimes = ''
                    this.password.failureDate = ''
                    this.password.failureHours = ''
                    this.password.failureMinutes = ''
                }
            }
            this.password.offlineDate = dateFtt('yyyy-MM-dd', new Date())
            this.password.offlineHours = dateFtt('hh', new Date())
            let minutesSec = dateFtt('mm:ss', new Date())
            this.changeHours()
            if (minutesSec >= '00:00' && minutesSec <= '19:59') {
                this.password.offlineMinutes = '00'
            } else if (minutesSec >= '20:00' && minutesSec <= '39:59') {
                this.password.offlineMinutes = '20'
            } else if (minutesSec >= '40:00' && minutesSec <= '59:59') {
                this.password.offlineMinutes = '40'
            }
            this.changeFailureTime()
        },
        changeHours() {
            for (let i in this.optionsMinutes) {
                let min = Number(this.optionsMinutes[i].label) + 20
                let time = `${this.password.offlineHours}:${min}`
                this.optionsMinutes[i].disabled = time < dateFtt('hh:mm', new Date()) ? true : false
            }
            let time2 = dateFtt('yyyy-MM-dd', new Date())
            if (this.password.offlineDate > time2) {
                for (let i of this.optionsMinutes) {
                    i.disabled = false
                }
            }
            this.changeFailureTime()
        },
        changeFailureTime() {
            if (this.password.offlineSecondType == '0') {
                let failureDate = new Date().getTime() + 24 * 60 * 60 * 1000
                this.password.failureDate = dateFtt('yyyy-MM-dd', new Date(failureDate))
                this.password.failureHours = this.password.offlineHours
                this.password.failureMinutes = this.password.offlineMinutes
            } else if (this.password.offlineSecondType == '1') {
                let number = parseInt((Number(this.password.offlineHours) + Number(this.password.offlineUnlockTimes)) / 24)
                if (number > 0) {
                    let newDate = new Date(`${this.password.offlineDate} ${this.password.offlineHours}:${this.password.offlineMinutes}:00`).getTime() + 60 * 60 * 1000 * Number(this.password.offlineUnlockTimes)
                    this.password.failureDate = dateFtt('yyyy-MM-dd', new Date(newDate))
                    this.password.failureHours = dateFtt('hh', new Date(newDate))
                    this.password.failureMinutes = dateFtt('mm', new Date(newDate))
                } else {
                    this.password.failureDate = this.password.offlineDate
                    this.password.failureHours = Number(this.password.offlineHours) + Number(this.password.offlineUnlockTimes)
                    this.password.failureMinutes = this.password.offlineMinutes
                }
            } else if (this.password.offlineSecondType == '2') {
                this.password.failureHours = this.password.offlineHours
                this.password.failureMinutes = this.password.offlineMinutes
                console.log(this.password.offlineHours + "   " + this.password.offlineMinutes)
                let time = new Date(this.password.offlineDate).getTime() + 24 * 60 * 60 * 1000 * Number(this.password.offlineUnlockTimes)
                let startYear = new Date(time).getFullYear(); //获取新的日期的年份
                let startMonth = new Date(time).getMonth() + 1; //获取月份
                let startDay = new Date(time).getDate(); //获取天
                this.password.failureDate = `${startYear}-${startMonth}-${startDay}`
            }
        },
        effectDateChange() {
            let time = dateFtt('yyyy-MM-dd', new Date())
            if (this.password.offlineDate > time) {
                for (let i of this.optionsHours) {
                    i.disabled = false
                }
                for (let i of this.optionsMinutes) {
                    i.disabled = false
                }
            } else {
                for (let i of this.optionsHours) {
                    i.disabled = i.label < dateFtt('hh', new Date()) ? true : false
                }
            }
            this.changeFailureTime()
        },
        addPasswordSave() {
            return new Promise((resolve, reject) => {
                if (this.submitForm(['addPassword'], this)) {
                    this.changestatus.devState = "添加密码"
                    this.changestatus.jdcPassword = this.password.mima
                    this.changestatus.jdcUserId = this.password.userId
                    this.changestatus.jdcHsId = this.form.clickrow.hsId
                    if (this.password.firstType == '在线密码') {
                        this.changestatus.authorizeType = 1
                        if (this.password.onlineSecondType == '限次密码') {
                            this.changestatus.jdcMaxUnlockingTimes = this.password.onlineUnlockTimes
                        } else {
                            this.changestatus.jdcDeadlineTime = this.password.onlineDeadline
                        }
                    } else if (this.password.firstType == '离线密码') {
                        this.changestatus.authorizeType = 2
                        this.changestatus.sendMessage = 0
                        if (this.panelForm.devSecondType == 58) {
                            this.password.offlineBeginTime = `${this.password.offlineDate} ${this.password.offlineHours}:${this.password.offlineMinutes}:00`
                            this.changestatus.encryptMode = this.password.offlineSecondType
                            this.changestatus.jdcMaxUnlockingTimes = this.password.offlineUnlockTimes
                            this.changestatus.startTime = this.password.offlineBeginTime
                        }
                    }
                    this.password.loading = true
                    this.doControlDevice().then(res => {
                        this.password.loading = false
                        resolve(res)
                    })
                }
            })
        },
        makeCard() {
            let vm = this
            // this.addPasswordSave().then(res => {
            vm.offlinePassword = ''
            obj.onResult(function (rData) {
                switch (rData.FunctionID) {
                    // case face.FUNCIDS._fid_adaptReader:
                    //   alert("Server is ready, you can start now!");
                    //   break;
                    case face.FUNCIDS._fid_initialcom: {
                        var rel = rData.RePara_Int;
                        if (0 == rel) {
                            var hdev = parseInt(rData.RePara_Str);
                            if (hdev != -1) {
                                clearTimeout(_searchTimer);
                                icdev = hdev;
                                if (isComOpen == true) {
                                    break;
                                }
                                isComOpen = true; //设置连接状态
                                console.log("连接成功");
                                Findcard();
                            } else {
                                console.log("连接失败");
                                // _searchTimer = setInterval("Connect()", 3000);
                            }
                        } else
                            alert("连接错误");
                    }
                    break;
                case face.FUNCIDS._fid_exit:
                    alert("连接已关闭");
                    break;
                case face.FUNCIDS._fid_findCardStr:
                //读卡响应
                {
                    if (controlDeviceNumber === 0) {
                        var strcard = rData.RePara_Str;
                        if (strcard != "") {
                            vm.strCard = strcard
                            vm.changestatus.devId = vm.form.clickrow.jourDeviceId
                            vm.changestatus.devState = "添加密码"
                            vm.changestatus.jdcPassword = vm.password.mima
                            vm.changestatus.jdcUserId = vm.password.userId
                            vm.changestatus.jdcHsId = vm.form.clickrow.hsId
                            vm.changestatus.authorizeType = 2
                            vm.password.offlineBeginTime = `${vm.password.offlineDate} ${vm.password.offlineHours}:${vm.password.offlineMinutes}:00`
                            vm.changestatus.encryptMode = vm.password.offlineSecondType
                            vm.changestatus.jdcMaxUnlockingTimes = vm.password.offlineUnlockTimes
                            vm.changestatus.startTime = vm.password.offlineBeginTime
                            vm.changestatus.sendMessage = 0
                            vm.changestatus.jdcCardId = strcard
                            vm.card.loading = true
                            vm.authorization().then(res => {
                                vm.offlinePassword = res
                                vm.card.loading = false
                                obj.loadkey(icdev, 0, 9, "FFFFFFFFFFFF");
                            })
                            //$("#cardID").val(strcard);
                            //obj.halt(icdev);
                            obj.beep(icdev, 10);
                        } else {
                            if (0 == gl_fFailedShow) {
                                gl_fFailedShow = 1; //set the flag whether show fail message or not
                                alert("找不到卡，请在射频区放一张卡");
                            }
                            //Findcard();
                        }
                        controlDeviceNumber++
                    }

                }
                break;
                case face.FUNCIDS._fid_halt:
                    //卡停止操作响应
                    obj.beep(icdev, 10);
                    break;
                case face.FUNCIDS._fid_beep:
                    //设备哔声响应
                    //Findcard();
                    //Disconnect();
                    break;
                case face.FUNCIDS._fid_loadKey:
                    //装载密钥响应
                    if (controlDeviceNumber2 === 0) {
                        if (rData.RePara_Int == 0) {
                            console.log("装载成功");
                            obj.authentication(icdev, 0, 9);
                            vm.cardState = false
                        } else {
                            console.log("装载失败");
                        }
                        controlDeviceNumber2++
                    }
                    break;
                case face.FUNCIDS._fid_authenClass:
                //验证密钥响应
                {
                    if (controlDeviceNumber3 === 0) {
                        var rel = rData.RePara_Int;
                        if (0 == rel) {
                            console.log("验证扇区密钥成功");
                            console.log(vm.offlinePassword)
                            let password = vm.offlinePassword
                            var str = [];
                            for (var i = 0; i < password.length; i++) {
                                str.push((password.charCodeAt(i)));
                            }
                            if (password.length == 9) {
                                str.unshift(9);
                            } else if (password.length == 10) {
                                str.unshift(10);
                            }
                            var hexCharCode = [];
                            for (var i = 0; i < str.length; i++) {
                                hexCharCode.push((str[i]).toString(16));
                            }
                            password = hexCharCode.join("");
                            password = "0" + password;
                            for (var i = password.length; i < 32; i++) {
                                password = password + "0";
                            }
                            console.log(password)
                            obj.write(icdev, 36, password);
                        } else {
                            console.log("验证扇区密钥失败");
                        }
                        controlDeviceNumber3++
                    }
                }
                break;
                case face.FUNCIDS._fid_writeAsHex:
                //写入响应
                {
                    var iRet = rData.RePara_Int;
                    if (iRet == 0) {
                        vm.dialogAddIC = false
                        vm.$message.success("密码写入成功")
                    } else {
                        vm.$message.success("密码写入失败")
                    }
                }
                break;
                case face.FUNCIDS._fid_writeAsStr:
                //写入响应
                {
                    if (controlDeviceNumber4 === 0) {
                        var iRet = rData.RePara_Int;
                        if (iRet == 0) {
                            vm.dialogAddIC = false
                            vm.$message.success("密码写入成功")
                        } else {
                            vm.$message.success("密码写入失败")
                        }
                        controlDeviceNumber4++
                    }
                }
                break;
                }
            });

            //与读卡器进行连接
            function Connect() {
                try {
                    if (isComOpen == false) //判断是否连接失败
                    {
                        obj.initialcom(100, 115200)
                    }
                } catch (e) {
                    alert(e.message);
                }
                return;
            }
            //读卡
            function Findcard() {
                obj.findcardStr(icdev, 0);
            }
            //关闭连接
            function Disconnect() {
                obj.exit(icdev);
                isComOpen = false; //Set unlink status
            }
            var isComOpen = false;

            var gl_fFailedShow = 0;
            var _searchTimer;
            var controlDeviceNumber = 0;
            var controlDeviceNumber2 = 0;
            var controlDeviceNumber3 = 0;
            var controlDeviceNumber4 = 0;
            Connect()
            // })
        },
        formatAuthorizeType(row, column) {
            console.log(row.jdcAuthorizeType)
            return row.jdcAuthorizeType == 0 ? "门卡" : row.jdcAuthorizeType == 1 ? "在线密码" : "离线密码"
        },
        authorizationRecordSize(size) {
            this.authorizationRecord.limit = size
            this.getAuthorizationRecord()
        },
        authorizationRecordCurrent(cursor) {
            this.authorizationRecord.cursor = cursor
            this.getAuthorizationRecord()
        },
        closeDialogIC() {
            this.resetForm(['addCard'], this)
            this.card.doorCardId = ''
            this.card.state = ''
            this.card.ICTitle = ''
            this.card.ICLabel = ''
            this.card.ICButton = ''
            this.card.user = ''
            this.card.userId = ''
        },
        selectICUser(arr) {
            this.card.userId = arr[2]
        },
        getCard() {
             console.log('wwwwww')
            let vm = this;
            // this.addPasswordSave().then(res => {
            vm.offlinePassword = "";
            var _that = this
            // 未安装软件时不进入此函数
            obj.onResult(function (rData) {
                console.log(_that)
                // 根据返回的状态提示
                switch (rData.FunctionID) {
                case face.FUNCIDS._fid_initialcom:
                    {
                    console.log(2323)
                    var rel = rData.RePara_Int;
                    if (0 == rel) {
                        var hdev = parseInt(rData.RePara_Str);
                        if (hdev != -1) {
                            clearTimeout(_searchTimer);
                            icdev = hdev;
                            if (isComOpen == true) {
                                break;
                            }
                            isComOpen = true; //设置连接状态
                            console.log("连接成功");
                            Findcard();
                        } else {
                        // _that.$message.error('连接失败')
                            console.log("连接失败");
                        // _searchTimer = setInterval("Connect()", 3000);
                        }
                    } else alert("连接错误");
                    }
                    break;
                case face.FUNCIDS._fid_exit:
                    _that.$message.error('连接已关闭')
                    // alert("连接已关闭");
                    break;
                case face.FUNCIDS._fid_findCardStr:
                    //读卡响应
                    {
                        if (controlDeviceNumber === 0) {
                            var strcard = rData.RePara_Str;
                            if (strcard != "") {
                                // 卡号赋值
                                vm.card.doorCardId = strcard
                                console.log(strcard)
                                //$("#cardID").val(strcard);
                                //obj.halt(icdev);
                                obj.beep(icdev, 10);
                            } else {
                                if (0 == gl_fFailedShow) {
                                    gl_fFailedShow = 1; //set the flag whether show fail message or not
                                    _that.$message.error('找不到卡，请在射频区放一张卡')
                                    // alert("找不到卡，请在射频区放一张卡");
                                }
                                //Findcard();
                            }
                            controlDeviceNumber++;
                        }
                    }
                    break;
                case face.FUNCIDS._fid_halt:
                    //卡停止操作响应
                    obj.beep(icdev, 10);
                    break;
                case face.FUNCIDS._fid_beep:
                    //设备哔声响应
                    //Findcard();
                    //Disconnect();
                    break;
                case face.FUNCIDS._fid_loadKey:
                    //装载密钥响应
                    if (controlDeviceNumber2 === 0) {
                        if (rData.RePara_Int == 0) {
                            console.log("装载成功");
                            obj.authentication(icdev, 0, 9);
                            vm.cardState = false;
                        } else {
                            console.log("装载失败");
                        }
                        controlDeviceNumber2++;
                    }
                    break;
                case face.FUNCIDS._fid_authenClass:
                    //验证密钥响应
                    {
                        if (controlDeviceNumber3 === 0) {
                            var rel = rData.RePara_Int;
                            if (0 == rel) {
                                console.log("验证扇区密钥成功");
                                console.log(vm.offlinePassword);
                                let password = vm.offlinePassword;
                                var str = [];
                                for (var i = 0; i < password.length; i++) {
                                    str.push(password.charCodeAt(i));
                                }
                                if (password.length == 9) {
                                    str.unshift(9);
                                } else if (password.length == 10) {
                                    str.unshift(10);
                                }
                                var hexCharCode = [];
                                for (var i = 0; i < str.length; i++) {
                                    hexCharCode.push(str[i].toString(16));
                                }
                                password = hexCharCode.join("");
                                password = "0" + password;
                                for (var i = password.length; i < 32; i++) {
                                    password = password + "0";
                                }
                                console.log(password);
                                obj.write(icdev, 36, password);
                            } else {
                                console.log("验证扇区密钥失败");
                            }
                            controlDeviceNumber3++;
                        }
                    }
                    break;
                case face.FUNCIDS._fid_writeAsHex:
                    //写入响应
                    {
                        var iRet = rData.RePara_Int;
                        if (iRet == 0) {
                            vm.dialogAddIC = false;
                            vm.$message.success("密码写入成功");
                        } else {
                            vm.$message.success("密码写入失败");
                        }
                    }
                    break;
                case face.FUNCIDS._fid_writeAsStr:
                    //写入响应
                    {
                    if (controlDeviceNumber4 === 0) {
                        var iRet = rData.RePara_Int;
                        if (iRet == 0) {
                            vm.dialogAddIC = false;
                            vm.$message.success("密码写入成功");
                        } else {
                            vm.$message.success("密码写入失败");
                        }
                        controlDeviceNumber4++;
                    }
                    }
                    break;
                }
            });

            //与读卡器进行连接
            function Connect() {
                try {
                    if (isComOpen == false) {
                        //判断是否连接失败
                        console.log(233333, obj.initialcom)
                        obj.initialcom(100, 115200);
                    }
                } catch (e) {
                    alert(e.message);
                }
                return;
            }
            //读卡
            function Findcard() {
                obj.findcardStr(icdev, 0);
            }
            //关闭连接
            function Disconnect() {
                obj.exit(icdev);
                isComOpen = false; //Set unlink status
            }
            var isComOpen = false;

            var gl_fFailedShow = 0;
            var _searchTimer;
            var controlDeviceNumber = 0;
            var controlDeviceNumber2 = 0;
            var controlDeviceNumber3 = 0;
            var controlDeviceNumber4 = 0;
            Connect();
            console.log(obj.onResult)
            // })
        },
        ICSave() { //添加注销IC卡保存
            if (this.submitForm(['addCard'], this)) {
                this.changestatus.jdcPassword = ''
                this.changestatus.jdcCardId = this.card.doorCardId
                this.changestatus.devState = this.card.state
                this.changestatus.authorizeType = 0
                this.changestatus.jdcUserId = this.card.userId
                this.changestatus.sendMessage = 1
                this.doControlDevice()
            }
        },
        openCurtain() {
            this.changestatus.devState = "开"
            this.changeStatus()
        },
        closeCurtain() {
            this.changestatus.devState = "关"
            this.changeStatus()
        },
        spendCurtain() {
            this.changestatus.devState = "暂停"
            this.changeStatus()
        },
        percentChange() {
            if(this.panelForm.devSecondType == 164){
                this.changestatus.devState = "百分比"
            }else{
                this.changestatus.devState = "开"
            }
            this.changeStatus(108)
        },
        formatTooltip(val) { //百分比窗帘格式
            return `${val}%`;
        },
        reversalcloseCurtain() {
            this.changestatus.devState = '反转';
            this.changeStatus('', 108);
        },
        corotationCurtain() {
            this.changestatus.devState = '正转';
            this.changeStatus('', 108);
        },

        airWet() {
            this.changestatus.devAirPattern = '除湿'
            this.changeStatus()
            this.dialogMoShi = false
        },


        airWindSpeedLow() {
            this.changestatus.devAirWindSpeed =
                this.panelForm.airConditioner4.indexOf(this.panelForm.devSecondType) >
                    -1 ? '低速' : '低';
            this.changeStatus()
            this.dialogFengSu = false
        },
        airSweepingWindSelf() {
            this.dialogSaoFeng = false
            this.changestatus.devAirSweepingWind = '自动'
            this.changeStatus()
        },
        airSweepingWindHand() {
            this.dialogSaoFeng = false
            this.changestatus.devAirSweepingWind = '手动'
            this.changeStatus()
        },
        gateWayControl(state) {
            this.changestatus.devState = state
            this.doControlDevice()
        },
        getGateWayHangDevice() {
            this.$axios({
                method: 'get',
                url: `/common2/devices/getGatewayHangDevice/${this.changestatus.devSn}`
            }).then(res => {
                this.gateWayHangDevice.dataList = res.data.result
                this.gateWayHangDevice.total = res.data.result.total
                this.dialogGateWayHangDevice = true
            }).catch(err => {
                this.isError(err, this)
            })
        },
        searchEquipment() {
            this.$axios({
                method: 'post',
                url: '/common2/devices/operate',
                data: {
                    operateName: 'searchDevice',
                    sn: this.changestatus.devSn,
                    devId: this.form.clickrow.jourDeviceId,
                },
            }).then((res) => {
                if (res.data.status == 200) {
                    this.$message({
                        message: '搜索成功',
                        type: 'success',
                    });
                }
            }).catch((err) => {
                this.isError(err, this);
            });
        },
        stopTheSearch() {
            this.$axios({
                method: 'post',
                url: '/common2/devices/operate',
                data: {
                    operateName: 'stopSearch',
                    sn: this.changestatus.devSn,
                    devId: this.form.clickrow.jourDeviceId,
                },
            }).then((res) => {
                if (res.data.status == 200) {
                    this.$message({
                        message: '中止搜索成功',
                        type: 'success',
                    });
                }
            }).catch((err) => {
                this.isError(err, this);
            });
        },
        pushToReport() {
            if (!this.form.clickrow && !this.form.selrows) {
                this.$message.warning('请先选中一条记录');
            } else {
                this.$axios({
                    method: 'post',
                    url: '/common2/devices/deviceOpenPush',
                    data:[this.changestatus.devSn],
                    params:{
                        openPush: 1,
                    }
                }).then((res) => {
                    console.log(res.data)
                    if (res.data.status == 200 && res.data.result.code == 0) {
                        this.$message.success('推送成功');
                    }else{
                        this.$message.warning('推送失败');
                    }
                }).catch((err) => {
                    this.isError(err, this);
                });
            }
        },
        tabChange() {
            this.GRBName = this.panelForm.devSecondType == 70 ? 'second' : 'first'
        },
        whiteLight() {
            this.changestatus.devState = "白光开"
            this.doControlDevice()
        },
        rbgLight() {
            this.changestatus.devState = "RGB开"
            this.doControlDevice()
        },
        colorChange() { //rgb灯change
            let rgbstr = this.color3.replace(/\s*/g, '')
            let rgb = rgbstr.slice(4, rgbstr.length - 1)
            let str = rgb.split(',')
            this.changestatus.rcolorValue = Number(str[0])
            this.changestatus.gcolorValue = Number(str[1])
            this.changestatus.bcolorValue = Number(str[2])
            this.changestatus.devState = "RGB开"
            this.doControlDevice()
        },
        openTape() {
            this.changestatus.devState = '开';
            this.changeTapeLight();
        },
        closeTape() {
            this.changestatus.devState = '关';
            this.changeTapeLight();
        },
        changeTapeLight(value, id) {
            this.loading5 = true;
            this.$axios({
                method: 'post',
                url: '/common2/devices/operate',
                data: {
                devAirPattern: this.changestatus.devAirPattern,
                devAirSweepingWind: this.changestatus.devAirSweepingWind,
                devAirTemperature: this.changestatus.devAirTemperature,
                devAirWindSpeed: this.changestatus.devAirWindSpeed,
                devColorTemperature: this.changestatus.nowStates.colorTemperature,
                devId: this.form.clickrow.jourDeviceId,
                devState: this.changestatus.devState,
                devPercentage:
                    id !== 108 && this.panelForm.devSecondType == 108
                    ? null
                    : this.changestatus.percentCurtain,
                },
            }).then((res) => {
                this.loading5 = false;
                let arr = [this.form.clickrow];
                setTimeout(() => {
                    //this.getAllStateB(this.form.clickrow.jourDeviceId, arr);
                }, 600);
                this.$message.success('控制成功');
            }).catch((err) => {
                this.loading5 = false;
                this.isError(err, this);
            });
        },
        brightnessOpenTape() {
            this.changestatus.devState = '开';
            this.brightnessTapeLight();
        },
        brightnessTapeLight(value, id) {
            this.loading5 = true;
            this.$axios({
                method: 'post',
                url: '/common2/devices/operate',
                data: {
                devAirPattern: this.changestatus.devAirPattern,
                devAirSweepingWind: this.changestatus.devAirSweepingWind,
                devAirTemperature: this.changestatus.devAirTemperature,
                devAirWindSpeed: this.changestatus.devAirWindSpeed,
                devColorTemperature: this.changestatus.nowStates.colorTemperature,
                devState: this.changestatus.devState,
                devId: this.form.clickrow.jourDeviceId,
                rgbBrightness: this.changestatus.tapeBrightness,
                devPercentage:
                    id !== 108 && this.panelForm.devSecondType == 108
                    ? null
                    : this.changestatus.percentCurtain,
                },
            }).then((res) => {
                this.loading5 = false;
                let arr = [this.form.clickrow];
                setTimeout(() => {
                    //this.getAllStateB(this.form.clickrow.jourDeviceId, arr);
                }, 600);
                this.$message.success('控制成功');
            }).catch((err) => {
                this.loading5 = false;
                this.isError(err, this);
            });
        },
        threeFn(type) {
            this.changestatus.status = true;
            switch (type) {
                case 1:
                    this.changestatus.rcolorValue = 255;
                    this.changestatus.gcolorValue = 255;
                    this.changestatus.bcolorValue = 255;
                    this.tapeChangeStatus();
                    break;
                case 2:
                    this.changestatus.rcolorValue = 255;
                    this.changestatus.gcolorValue = 0;
                    this.changestatus.bcolorValue = 0;
                    this.tapeChangeStatus();
                break;
                case 3:
                    this.changestatus.rcolorValue = 255;
                    this.changestatus.gcolorValue = 225;
                    this.changestatus.bcolorValue = 0;
                    this.tapeChangeStatus();
                break;
                case 4:
                    this.changestatus.rcolorValue = 0;
                    this.changestatus.gcolorValue = 255;
                    this.changestatus.bcolorValue = 0;
                    this.tapeChangeStatus();
                break;
                case 5:
                    this.changestatus.rcolorValue = 0;
                    this.changestatus.gcolorValue = 0;
                    this.changestatus.bcolorValue = 255;
                    this.tapeChangeStatus();
                break;
                case 6:
                    this.changestatus.rcolorValue = 255;
                    this.changestatus.gcolorValue = 0;
                    this.changestatus.bcolorValue = 255;
                    this.tapeChangeStatus();
                break;
                case 7:
                    this.changestatus.rcolorValue = 0;
                    this.changestatus.gcolorValue = 255;
                    this.changestatus.bcolorValue = 255;
                    this.tapeChangeStatus();
                break;
                case 8:
                    this.changestatus.rcolorValue = 255;
                    this.changestatus.gcolorValue = 128;
                    this.changestatus.bcolorValue = 0;
                    this.tapeChangeStatus();
                break;
                case 9:
                    this.changestatus.rcolorValue = 255;
                    this.changestatus.gcolorValue = 25;
                    this.changestatus.bcolorValue = 0;
                    this.tapeChangeStatus();
                break;
                case 10:
                    this.changestatus.rcolorValue = 16;
                    this.changestatus.gcolorValue = 78;
                    this.changestatus.bcolorValue = 139;
                    this.tapeChangeStatus();
                default:
                break;
            }
        },
        tapeChangeStatus(value, id) {
            this.loading5 = true;
            this.$axios({
                method: 'post',
                url: '/common2/devices/operate',
                data: {
                    rcolorValue: this.changestatus.rcolorValue,
                    gcolorValue: this.changestatus.gcolorValue,
                    bcolorValue: this.changestatus.bcolorValue,
                    devAirPattern: this.changestatus.devAirPattern,
                    devAirSweepingWind: this.changestatus.devAirSweepingWind,
                    devAirTemperature: this.changestatus.devAirTemperature,
                    devAirWindSpeed: this.changestatus.devAirWindSpeed,
                    devColorTemperature: this.changestatus.nowStates.colorTemperature,
                    devId: this.form.clickrow.jourDeviceId,
                    devState: this.changestatus.devState,
                    devPercentage:
                        id !== 108 && this.panelForm.devSecondType == 108
                        ? null
                        : this.changestatus.percentCurtain,
                },
            }).then((res) => {
                this.loading5 = false;
                let arr = [this.form.clickrow];
                setTimeout(() => {
                    // this.getAllStateB(this.form.clickrow.jourDeviceId, arr);
                }, 600);
                this.$message.success('控制成功');
            }).catch((err) => {
                this.loading5 = false;
                this.isError(err, this);
            });
        },
        identify(a) { //获取开门记录
            if (this.faceForm.time) {
                var startTime = this.faceForm.time[0]
                var endTime = this.faceForm.time[1]
            }
            this.dialogIdentify = true
            this.$axios({
                method: 'get',
                url: '/common2/faceRecognitionInformations',
                params: {
                    limit: this.faceForm.limit,
                    cursor: this.faceForm.cursor,
                    id: this.form.clickrow.jourDeviceId,
                    jftiPersonName: this.faceForm.jftiPersonName,
                    jftiType: this.faceForm.result,
                    startTime,
                    endTime
                }
            }).then(res => {
                for (let i of res.data.result.records) {
                    if (i.jftiType == 1) {
                        i.jftiType = '成功'
                    } else {
                        i.jftiType = '失败'
                    }
                    if (i.jftiRecMode == 1) {
                        i.jftiRecMode = '刷脸'
                    } else if (i.jftiRecMode == 2) {
                        i.jftiRecMode = '刷卡'
                    } else if (i.jftiRecMode == 3) {
                        i.jftiRecMode = '脸&卡双重认证'
                    } else if (i.jftiRecMode == 4) {
                        i.jftiRecMode = '人证比对'
                    }
                }
                this.faceForm.faceIdList = res.data.result.records
                this.faceForm.total = res.data.result.total
            }).catch(err => {
                this.isError(err, this)
            })
        },
        deleteIdentify() {
            this.$confirm('此操作将永久删除该设备, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios({
                    method: 'delete',
                    url: '/common2/facePersons',
                    params: {
                        deviceKey: this.changestatus.devSn
                    }
                }).then(res => {
                    this.$message.success('删除成功')
                    this.dialogCaoZuoSheBei = false
                    this.$emit('update')
                }).catch(err => {
                    this.isError(err, this)
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        restart() { //重启
            this.$axios({
                method: 'post',
                url: '/common2/facePersons/restart',
                params: {
                    deviceKey: this.changestatus.devSn
                }
            }).then(res => {
                this.$message.success('重启成功')
            }).catch(err => {
                this.isError(err, this)
            })
        },
        openDoor() { //一键开门
            this.$axios({
                method: 'put',
                url: '/common2/facePersons/openDoor',
                params: {
                    deviceKey: this.changestatus.devSn
                }
            }).then(res => {
                this.$message.success('开门成功')
            }).catch(err => {
                this.isError(err, this)
            })
        },
        magneticDoor() { //绑定门磁
            this.$axios({
                method: 'get',
                url: '/common2/faceDoorMagnetic/magnetism'
            }).then(res => {
                this.dialogMagnetic = true
                this.faceForm.magneticList = res.data.result
                this.$axios({ //获取已绑定门磁设备
                    method: 'get',
                    url: '/common2/faceDoorMagnetic/bound',
                    params: {
                        fdmFaceId: this.changestatus.jourDeviceId
                    }
                }).then(res => {
                    console.log(res);
                    let tableSelected = res.data.result
                    for (let i of this.faceForm.magneticList) {
                        for (let j of tableSelected) {
                            if (i.jourDeviceId == j) {
                                this.$refs.multipleTable.toggleRowSelection(i);
                                break;
                            }
                        }
                    }
                }).catch(err => {
                    this.isError(err, this)
                })
            }).catch(err => {
                this.isError(err, this)
            })
        },
        deleteBind() { //解除绑定按钮
            let deviceId = parseInt(this.changestatus.jourDeviceId)
            this.$axios({
                method: 'delete',
                url: '/common2/facePersons/deviceId',
                params: {
                    deviceId
                }
            }).then(res => {
                this.dialogCaoZuoSheBei = false
                this.$message.success('解绑成功')
                this.$emit('update')
            }).catch(err => {
                this.isError(err, this)
            })
        },
        identifyClose() { //识别记录窗口关闭
            this.faceForm.time = [dateFtt('yyyy-MM-dd', new Date()), dateFtt('yyyy-MM-dd', new Date())]
            this.faceForm.jftiPersonName = ''
            this.faceForm.dfdnDoorSn = ''
            this.faceForm.jourDeviceIds = [],
                this.faceForm.result = '1'
        },
        jftiPersonNameChange() { //输入名字查询
            this.faceForm.cursor = 1
            this.identify()
        },
        imgShow(url) {
            // console.log(row)
            this.dialogImageUrl = url
            this.dialogImg = true
        },
        identifyCurrent(cursor) { //分页
            this.faceForm.cursor = cursor
            this.identify()
        },
        doorSelect(selection) {
            this.faceForm.jourDeviceIds = selection
        },
        magneticSave() { //绑定门磁保存按钮
            if (this.faceForm.jourDeviceIds.length != 0) {
                console.log(this.faceForm.jourDeviceIds);
                let dfdmFaceId = this.changestatus.jourDeviceId
                let jourDeviceIds = []
                for (let i of this.faceForm.jourDeviceIds) {
                    jourDeviceIds.push(i.jourDeviceId)
                }
                this.$axios({
                    method: 'post',
                    url: '/common2/faceDoorMagnetic/binding',
                    data: {
                        dfdmFaceId,
                        jourDeviceIds
                    }
                }).then(res => {
                    this.dialogMagnetic = false
                    this.$message.success('绑定成功')
                }).catch(err => {
                    this.isError(err, this)
                })
            } else {
                this.$message.warning('请勾选一个门磁设备')
            }
        },
        minlumenFun() { //最小流明
            this.dialogLightFeel = true
            this.lightFeelTitle = '下限流明'
        },
        maxlumenFun() {
            this.dialogLightFeel = true
            this.lightFeelTitle = '上限流明'
        },
        intervalLumenFun() {
            this.dialogLightFeel = true
            this.lightFeelTitle = '间隔流明'
        },
        reportedRecordFun(value) { //上报记录
            if (value == "2") {
                this.dialogReportedRecord2 = true
            }
            this.dialogReportedRecord = true;
            this.$axios({
                    method: "get",
                    url: "/common2/eventsSensorEventss",
                    params: {
                        sn: this.changestatus.devSn,
                        type: this.changestatus.type
                    },
                })
                .then((res) => {
                    this.reportedRecordArr = res.data.result;
                })
                .catch((err) => {
                    this.isError(err, this);
                });
        },
        diagramLumenFun() { //流明曲线图
            this.dialogDiagram = true;
            this.$axios({
                method: 'get',
                url: '/common2/eventsSensorEventss',
                params: {
                    sn: this.changestatus.devSn
                }
            }).then(res => {
                this.reportedRecordArr = res.data.result
                console.log(this.reportedRecordArr)
                let seLowerLimitLumen = [],
                    seLumen = [],
                    seUpperLimitLumen = [],
                    seTime = [];
                if (this.reportedRecordArr) {
                    for (let item of this.reportedRecordArr) {
                        seLowerLimitLumen.unshift(item.seLowerLimitLumen);
                        seLumen.unshift(item.seLumen);
                        seUpperLimitLumen.unshift(item.seUpperLimitLumen);
                        seTime.unshift(item.seTime);
                    }
                }
                var myChart = this.$echarts.init(document.getElementById('myChart'))
                myChart.setOption({
                    title: {
                        text: '流明曲线图'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['流明值', '最小流明', '最大流明']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: seTime
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                            name: '流明值',
                            type: 'line',
                            stack: '总量',
                            data: seLumen
                        },
                        {
                            name: '最小流明',
                            type: 'line',
                            stack: '总量',
                            data: seLowerLimitLumen
                        },
                        {
                            name: '最大流明',
                            type: 'line',
                            stack: '总量',
                            data: seUpperLimitLumen
                        },
                    ]
                });
            }).catch(err => {
                this.isError(err, this)
            })
        },
        bindingEquipment() { //绑定设备
            this.$axios({
                method: 'get',
                url: '/common2/devices/list/operate',
                params: {
                    devType: '光感',
                    hsIds: this.form.clickrow.hsId,
                }
            }).then(res => {
                this.equipmentShuttleBox = res.data.result
                this.lpDeviceRelations()
            }).catch(err => {
                this.isError(err, this)
            })
        },
        lpDeviceRelations() { //查询光感绑定设备
            this.$axios({
                method: 'get',
                url: '/common2/lpDeviceRelations',
                params: {
                    dldrLpId: this.form.clickrow.jourDeviceId,
                }
            }).then(res => {
                this.equipmentJourDeviceId = res.data.result
                this.dialogBindingEquipment = true
                console.log(this.equipmentJourDeviceId);
            }).catch(err => {
                this.isError(err, this)
            })
        },
        minLumenChange() {
            if (this.minlumen > 65535 || this.minlumen < 1 || isNaN(this.minlumen)) {
                this.minlumen = ''
                this.$message.warning('请输入合法值')
            }
        },
        maxLumenChange() {
            if (this.maxlumen > 65535 || this.maxlumen < 1 || isNaN(this.maxlumen)) {
                this.maxlumen = ''
                this.$message.warning('请输入合法值')
            }
        },
        spaceLumenChange() {
            if (this.intervalLumen > 65535 || this.intervalLumen < 1 || isNaN(this.intervalLumen)) {
                this.intervalLumen = ''
                this.$message.warning('请输入合法值')
            }
        },
        lumenPost() {
            this.$axios({
                method: 'post',
                url: `/common2/devices/operate`,
                data: {
                    devId: this.form.clickrow.jourDeviceId,
                    devState: this.lightFeelTitle,
                    devLowerLimitLumen: this.minlumen,
                    devUpperLimitLumen: this.maxlumen,
                    devLumenSpacing: this.intervalLumen,
                }
            }).then(res => {
                this.dialogLightFeel = false
                this.$message.success('添加成功')
                this.minlumen = ''
                this.maxlumen = ''
                this.intervalLumen = ''
            }).catch(err => {
                this.isError(err, this)
            })
        },
        BindingEquipmentSave() { //光感绑定设备
            this.$axios({
                method: 'post',
                url: '/common2/lpDeviceRelations',
                data: {
                    dldrLpId: this.form.clickrow.jourDeviceId,
                    jourDeviceIds: this.equipmentJourDeviceId
                }
            }).then(res => {
                this.$message.success('保存成功')
                this.dialogBindingEquipment = false
            }).catch(err => {
                this.dialogBindingEquipment = false
                this.isError(err, this)
            })
        },
        doorPlateControl(num, state) {
            this.changestatus.devNumber = num;
            this.changestatus.devState = state;
            this.doControlDevice();
        },
        modifyPushAddress() {
            this.modifyPush.dialog = true
            this.loading5 = true
            this.$axios({
                method: "post",
                url: `/common2/devices/modifyPushAddr`,
                params: {
                    deviceId: this.enabled.params.devId,
                    type: '0'
                }
            })
            .then(res => {
                this.modifyPush.url = res.data.result.data.HTTPServer
            })
            .catch(err => {
                this.isError(err, this);
            })
            .finally(() => {
                this.loading5 = false
            })
        },
        deleteFaceDevice() {
            this.$confirm('此操作将删除该设备及其数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios({
                    method: "delete",
                    url: `/common2/devices/deleteFaceDevice`,
                    params: {
                        deviceId: this.enabled.params.devId,
                        serverUrl: null
                    }
                })
                .then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.dialogCaoZuoSheBei = false
                    this.modifyPush.dialog = false
                    this.form.cursor = 1
                    this.$emit('update')
                })
                .catch(err => {
                    this.isError(err, this);
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        //插卡面板
        cardSet() {
            this.cardPanelData.cardSetDialog = true;
        },
        cardSetTabPaneClick(tab, event) {
            console.log(tab, event);
        },
        lightSensationBtns(value) {
            this.enabled.dialogTitle = value
            this.enabled.dialogVisible = true
        },
        periodUnit() {
            if (this.enabled.hourlyBasis == '秒') {
                this.enabled.params.periodUnit = '01'
            } else if (this.enabled.hourlyBasis == '分') {
                this.enabled.params.periodUnit = '10'
            } else if (this.enabled.hourlyBasis == '时') {
                this.enabled.params.periodUnit = '11'
            }
            console.log(this.enabled.params.periodUnit)
        },
        // 6200光线传感器相关方法
        enabledReq() {
            let value = this.enabled.jobEnabled + this.enabled.digitalDimming + this.enabled.uploadingEnabled
            this.enabled.params.enable = value
            this.enabled.params.order = 2
            console.log(this.enabled.params)
            this.$axios({
                method: "post",
                url: `/common2/devices/operate`,
                data: {
                    devId: this.enabled.params.devId,
                    enable: this.enabled.params.enable,
                    period: this.enabled.params.period,
                    periodUnit: this.enabled.params.periodUnit,
                    order: this.enabled.params.order
                },
            })
            .then((res) => {
                this.$message.success("操作成功");
            })
            .catch((err) => {
                // this.loading4 = false;
                this.isError(err, this);
            });
        },
        async floorHeatingControl(devState) {
            this.changestatus.devState = devState
            if (devState == 'workingMode') {
                this.changestatus.mode = this.floorHeating.modeButton == '室温模式' ? '01' : this.floorHeating.modeButton == '地面模式' ? '02' : '03'
            } else if (devState == 'switch') {
                this.changestatus.devState = this.floorHeating.floorHeatingState.switchingState == '关' ? 'turnOn' : 'turnOff'
                this.floorHeating.switchButtonType = this.floorHeating.floorHeatingState.switchingState == 'info' ? 'primary' : 'info'
            } else if (devState == 'lock') {
                this.changestatus.devState = this.floorHeating.floorHeatingState.deviceLock == '关' ? 'lockUp' : 'unlock'
                this.floorHeating.lockButtonType = this.floorHeating.floorHeatingState.deviceLock == 'info' ? 'primary' : 'info'
            } else if (devState == 'roomTemp') {
                this.changestatus.devAirTemperature = this.floorHeating.floorHeatingState.roomTemp
            } else if (devState == 'landTemp') {
                this.changestatus.devAirTemperature = this.floorHeating.floorHeatingState.landTemp
            } else if (devState == 'currentTimeGear') {
                this.changestatus.devAirTemperature = this.floorHeating.floorHeatingState.currentTimeGear
            } else if (devState == 'antifreezeFunctionSwitch') {
                this.changestatus.devAirPattern = this.floorHeating.floorHeatingState.antifreezeFunctionSwitch
            } else if (devState == 'temperatureUpperLimit') {
                this.changestatus.devAirTemperature = this.floorHeating.floorHeatingState.temperatureUpperLimit
            } else if (devState == 'temperatureLowerLimit') {
                this.changestatus.devAirTemperature = this.floorHeating.floorHeatingState.temperatureLowerLimit
            } else if (devState == 'limitRange') {
                this.changestatus.devAirTemperature = this.floorHeating.floorHeatingState.limitRange
            } else if (devState == 'openAndStopTemperatureDifference') {
                this.changestatus.devAirTemperature = this.floorHeating.floorHeatingState.openAndStopTemperatureDifference
            } else if (devState == 'temperatureProtectionProbe') {
                this.changestatus.devAirTemperature = this.floorHeating.floorHeatingState.temperatureProtectionProbe
            } else if (devState == 'temperatureProbeError') {
                this.changestatus.devAirTemperature = this.floorHeating.floorHeatingState.temperatureProbeError
            } else if (devState == 'minimumTimeForRelayToOpen') {
                this.changestatus.devAirTemperature = this.floorHeating.floorHeatingState.minimumTimeForRelayToOpen
            }
            this.changestatus.devId = this.form.clickrow.jourDeviceId
            this.floorHeating.loading = true
            this.$axios({
                method: "post",
                url: '/common2/devices/operate',
                data: this.changestatus,
            }).then(res => {
                this.floorHeating.loading = false
                this.getOneState()
                this.$message.success('控制成功')
            }).catch(err => {
                this.floorHeating.loading = false
                this.isError(err, this)
            })
        },
        closeDialogPlugInCard() {
            this.plugInCardRecords = {
                dataList: [],
                cursor: 1, //当前页面
                limit: 5, //每页个数
                total: 0, //总条数
                pages: 0, //总页数
            }
        },
        plugInCardCurrent(cursor) {
            this.plugInCardRecords.cursor = cursor
            this.getPlugInCardRecords()
        },
        plugInCardSize(size) {
            this.plugInCardRecords.limit = size
            this.getPlugInCardRecords()
        },
        closeDialogGateLockRecords() {
            this.gateLockRecords = {
                dataList: [],
                cursor: 1, //当前页面
                limit: 5, //每页个数
                total: 0, //总条数
                pages: 0 //总页数
            }
        },
        gateLockCurrent(cursor) {
            this.gateLockRecords.cursor = cursor
            this.getGateLockRecords()
        },
        gateLockSize(size) {
            this.gateLockRecords.limit = size
            this.getGateLockRecords()
        },
        closeModifyPushDialog() {
            this.modifyPush.url = ''
            this.resetForm(['modifyPush'], this);
        },
        // 测试服务器保存
        saveServerAddress() {
            if (this.submitForm(['modifyPush'], this)) {
                this.$axios({
                    method: "post",
                    url: `/common2/devices/modifyPushAddr`,
                    params: {
                        deviceId: this.enabled.params.devId,
                        serverUrl: this.modifyPush.url,
                        type: '1'
                    }
                })
                .then(res => {
                    this.$message({
                        message: res.data.result.data,
                        type: res.data.result.msg
                    });
                    this.modifyPush.dialog = false
                })
                .catch(err => {
                    this.isError(err, this);
                });
            }
        },
        //子传父 自定义事件处理函数  修改 检测仪数据 detectorData
        changeDetectorData(detectorData){
            // console.log(detectorData);
            this.detectorData = detectorData
        },
    },
}
</script>

<style lang="scss">
// .airConditioner{
//     // border:1px solid red
// }
// .airConditioner .airHeader{
//     // border:1px solid blue
// }

.airSelect{
    // border: 1px solid red;
    text-align: center;
}
.airSelect .el-button{
    background: #eaeaea;
    color: #9b9c9d;
    // font-weight: bold;
    border: 0px;
    font-size: 18px;
    margin: 10px 20px;
}
.swButton{
    text-align: center;
}
.swButton .el-button{
    background: #63cf50;
    margin: 20px 10px;
    border:0px;
    font-size: 14px;
    width:100px;
}
.SWSelect{
    text-align: center;
}
.SWSelect .el-button{
    background: #eaeaea;
    color: #9b9c9d;
    border: 0px;
    font-size: 14px;
    margin: 0px 10px;
    width:100px;
    margin-bottom: 20px;
}
.VRVBtnBox {
  /deep/.el-button {
    height: 50px;
    width: 50px;
    background-color: #1d364f;
    border-color: #1d364f;
  }
  /deep/ .el-icon-plus,
  /deep/ .el-icon-minus {
    font-size: 20px;
  }
  /deep/ .el-icon-plus:before,
  /deep/ .el-icon-minus:before {
    color: #ffffff;
  } 
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
.floorOpenBtn .el-button--warning:focus{
    background: rgb(236, 245, 255);
}
</style>
<style lang="scss" scoped>
.sceneInput{
    width:150px;
    margin-right:10px;
}
.scenePaneBut{
    margin-bottom:10px;
}
/*  中央空调样式*/
.centralAirConditioning {
  .model {
      margin-bottom:10px;
      padding:10px;
      border-radius:10px;
      background-color: #E4E7ED;
      height:200px;
      position: relative;
      .juzhon{
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0%, -50%);
      }
      .bigDot {
        height: 52px;
        width: 50px;
        background-color: #9e9f9f;
        border-radius: 50%;
        margin: 10px auto;
        cursor: pointer;
      }
    .modelChild {
      text-align: center;
      .dot {
        height: 10px;
        width: 10px;
        // background-color: #9e9f9f;
        border-radius: 50%;
        margin: 5px auto 0;
      }
      p {
        color: #02192E;
        font-size: 16px;
        margin: 5px 0;
      }
      .bigDot {
        height: 52px;
        width: 50px;
        background-color: #9e9f9f;
        border-radius: 50%;
        margin: 10px auto;
        cursor: pointer;
      }
    }
  }
  .el-slider /deep/ .el-slider__button-wrapper{
  display: none;
}
.el-slider /deep/ .el-slider__runway{
  background-color:#C0C4CC;
}
.el-slider /deep/ .el-slider__runway.disabled .el-slider__bar{
  background-color:#02192E;
  transition: all .2s ease;
}
}
.screenInput {
    width: 125px;
}

.none-icon {
    /deep/ .el-input__suffix {
        display: none;
    }
}

.DoorLock {
    /deep/ .el-dialog__title {
        font-size: 24px;
    }

    p:nth-of-type(1) {
        font-size: 22px;
        letter-spacing: 3px;
        color: #000;
        margin-bottom: 0;
    }

    p:nth-of-type(2) {
        font-size: 40px;
        letter-spacing: 3px;
        color: red;
        margin-bottom: 0;
    }

    p:nth-of-type(3) {
        font-size: 15px;
        letter-spacing: 1px;
        color: #999999;
        margin-bottom: 0;
    }

    p:nth-of-type(4),
    p:nth-of-type(5),
    p:nth-of-type(6) {
        color: #000;
        font-size: 18px;
        letter-spacing: 2px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    p:nth-of-type(7) {
        font-size: 17px;
        letter-spacing: 1px;
        color: #999999;
        margin-bottom: 15px;
    }
}
.infrared {
    /deep/.el-button {
        margin-right: 10px;
    }
}
.controlBox{
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    .open{
        width: 200px;
        height: 200px;
        margin: 20px auto;
        // display: flex;
        // justify-content: center;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .ipt{
        width: 200px;
        display: flex;
        justify-content: space-between;
        input{
            width:50px;
            text-align: center;
        }
    }
}
.controlObjSttingBox /deep/ .el-dialog__header{
    background-color:#ECECEC;
}
.controlObjSttingBox /deep/ .el-dialog__body{
    background-color:#ECECEC;
}
.controlObjSttingBox /deep/.el-dialog__title{
    font-size:18px;
    color:#9B9C9D;
}
.controlObjSttingBox /deep/.el-input__inner{
    font-size:20px;
    color:#9B9C9D;
}
.controlObjSttingBox{
    .control{
        color:#9B9C9D;
    }
    .el-row{
        font-weight:bold;
        margin-bottom: 15px;
    }
    .hint{
        color:#7ed321;
    }

}
.control-Box{
    .hint{
        font-weight:bold;
        color:#7ed321;
    }
    .el-row{
        margin-bottom: 15px;
    }
    .el-button{
        width:100px;
        height:30px;
        font-size:16px;
        padding:0;
    }
    .controlBox-title{
        font-weight: bold;
    }
    .controlBox-iconBox{
        width:90px;
        height:90px;
        border-radius:50%;
        position:relative;
        .icon{
            position: absolute;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%)
        }
    }
    .color1{
        background-color:#7ed321;
    }
    .color2{
        background-color:#999;
    }
}
.controlEl /deep/ .el-input__inner{
    margin:10px 0 10px 0  ;
    width:170px;
}
.controlModel{
    div{
        margin:20px 0px 10px 0px;
        font-size:15px
    }
}

</style>