import request from '../utils/request';
import axios from 'axios'
import { method } from 'lodash';


// 查询所有一级跟二级设备类型详细信息
export const equipmentSecondLevel = (dstId) =>{
    return request({
        url:"/ems/secondType/getFirAndSec",
        method:"GET",
        params:{dstId},
    })
}
// 根据状态Id查询设备功能状态信息
export const statusInformationId =(dfsId)=>{
    return request({
        url:`/ems/deviceFunctionState/selectDFState`,
        method:"GET",
        params:{dfsId}
    })
}
// ------------------------------------------------------------------
//分页查询有情景的房间
export const queryHouseWithScenePage = (params) => {
    return request({
        url: '/common2/scene/page',
        method: 'GET',
        params
    })
}

// 查询房间下支持设置情景的设备集合 参数: 房间id,搜索的值
export const standSceneList = (data) => {
    return request({
        url: `/common2/devices/standScene/list`,
        method: 'POST',
        data
    })
}
//添加情景
export const saveScene = (data) => {
    return request({
        url: `/common2/scene/saveScene`,
        method: 'POST',
        data
    })
}
//添加设备触发 
//参数: hsIds 房间ID集合
//      selectType 查询类型(1为查询触发机制,2为查询执行动作) 
//      getDevNickname 设备名称
//      getDevAuthSecret 设备网关
//      getDevSn 设备sn
export const getLinkageDevice=(data)=>{
    return request({
        url:`/common2/linkage/getLinkageDevice`,
        method:'POST',
        data
    })
}

// 查询所有情景类别
export const getAllSceneCategory = () => {
    return request({
        url: `/common2/sceneCategory/getAll`,
        method: 'POST',
        isByUser:false
    })
}
// 查询情景详情
export const infoScene = (params) => {
    return request({
        url: `/common2/scene/info`,
        method: 'GET',
        params
    })
}
//支持查询情景联动的设备
export const standLinkageList = (hsId) => {
    return request({
        url: `/common2/devices/standLinkage/list/${hsId}`,
        method: 'GET',
    })
}
//查询房间下已保存的情景
export const sceneList = (params) => {
    return request({
        url: `/common2/scene/list`,
        method: 'GET',
        params,
    })
}

//查询房间下所有情景
export const sceneLinkageInfo = (params) => {
    return request({
        url: `/common2/sceneLinkage/info`,
        method: 'get',
        params
    })
}

// 添加情景联动
export const sceneLinkageSave = (data) => {
    return request({
        url: `/common2/sceneLinkage/save`,
        method: 'POST',
        data
    })
}

// 移除情景指令
export const scenelnstructionsRemove = (params) => {
    return request({
        url: `/common2/sceneInstructions/remove`,
        method: 'delete',
        params
    })
}

// 获取情景联动面板网关
export const devicesAll6200Mac = () => {
    return request({
        url: `/common2/devices/all6200Mac`,
        method: 'GET',
        
    })
}

// 获取情景联动面板网关
export const sceneLinkageMatchController = (params) => {
    return request({
        url: `/common2/sceneLinkage/matchController`,
        method: 'post',
        params
    })
}

// 添加情景模式名称
export const sceneCategoryAdd = (params) => {
    return request({
        url: `/common2/sceneCategory/add`,
        method: 'post',
        params
    })
}

// 情景执行
export const sceneModeControl = (params) => {
    return request({
        url: `/common2/scene/modeControl`,
        method: 'post',
        params
    })
}

// 添加情景模式名称
export const sceneCategoryModify = (params) => {
    return request({
        url: `/common2/sceneCategory/modify`,
        method: 'post',
        params
    })
}
// 添加情景联动
export const sceneLinkageKeep = (params,data) => {
    return request({
        url: `/common2/sceneLinkage/keep`,
        method: 'post',
        params,
        data,
    })
}

// 添加情景联动
export const linkageKeep = (params,data) => {
    return request({
        url: `/common2/linkage/addLinkage`,
        method: 'post',
        params,
        data,
    })
}

// 联动管理表格数据
export const linkPageData = (params) => {
    return request({
        url: `/common2/linkageComprehensive/page`,
        method: 'GET',
        params,
    })
}
// 联动管理时间表格数据
export const timingClock = (params) => {
    return request({
        url: `/common2/timingClock/pageList`,
        method: 'GET',
        params,
    })
}
// 联动管理设备表格数据
export const devLinkageTable = (params) => {
    return request({
        url: `/common2/sceneLinkage/pageList`,
        method: 'GET',
        params,
    })
}

//双击时间表
export const dbTimeLinkage = (params) => {
    return request({
        url: `/common2/timingClock/select`,
        method: 'GET',
        params,
    })
}
//双击设备表
export const dbDevLinkage = (params) => {
    return request({
        url: `/common2/sceneLinkage/select`,
        method: 'GET',
        params,
    })
}

export const sceneOffline = (params) => {
    return request({
        url: `/common2/scene/sceneOffline`,
        method: 'GET',
        params,
    })
}

export const linkagePage = (params) => {
    return request({
        url: `/common2/linkage/page`,
        method: 'GET',
        params,
    })
}

//注销，启动
export const linkageUpdateState = (params) => {
    return request({
        url: `/common2/linkage/updateState`,
        method: 'PUT',
        params,
    })
}

// 批量设置场景
export const saveScenes = (data) => {
    return request({
        url:'/common2/scene/saveBatchScene',
        method:'POST',
        data
    })
}

// 情景管理双击表
export const linkageGetLinkage = (params) => {
    return request({
        url:'/common2/linkage/getLinkage',
        method:'GET',
        params
    })
}

//在线配对
export const linkageOffline = (params) => {
    return request({
      url: '/common2/linkage/offline',
      method: 'GET',
      params
    })
  }

export const controllDeviceList = (arr) => {
    return axios.all(arr)
}

export const controllDeviceItem  = (data) => {
    return request({
        method: "post",
        url: '/common2/devices/operate',
        data:data
    })
}

export const locationControl = (data)=>{
    return request({
        method: 'post',
        url: `/common2/devices/ynhaiLocationControlDevice`,
        data:data
    })
}

export const linkageGetLinkageDevice = (data)=>{
    return request({
        method: 'post',
        url: `/common2/linkage/getLinkageDevice`,
        data:data
    })
}

export const linkageGetLinkageDevice2 = (params)=>{
    return request({
        method: 'GET',
        url: `/common2/linkage/getLinkageDevice`,
        params
    })
}

export const sceneControlScene = (params)=>{
    return request({
        method: 'GET',
        url: `/common2/scene/controlScene`,
        params
    })
}

export const sceneSelectSceneCategory = (params)=>{
    return request({
        method: 'GET',
        url: `/common2/scene/selectSceneCategory`,
        params
    })
}

export const sceneCategoryDelete = (params)=>{
    return request({
        method: 'GET',
        url: `/common2/sceneCategory/delete`,
        params
    })
}

export const sceneDeleteScene = (params)=>{
    return request({
        method: 'GET',
        url: `/common2/scene/deleteScene`,
        params
    })
}

export const sceneCategoryIcon = (params)=>{
    return request({
        method: 'GET',
        url: `/common2/sceneCategory/getIconManagement`,
        params
    })
}

export const linkageGetLinkageScene = (data)=>{
    return request({
        method: 'POST',
        url: `/common2/linkage/getLinkageScene`,
        data
    })
}

export const sceneUpdateOrderID = (data)=>{
    return request({
        method: 'PUT',
        url: `/common2/scene/updateOrderID`,
        data
    })
}

export const sceneLinkageAddSceneCode = (data)=>{
    return request({
        method: 'POST',
        url: `common2/sceneLinkage/addSceneCode`,
        data
    })
}

export const linkageDeviceState = (params)=>{
    return request({
        method: 'POST',
        url: `/common2/linkage/linkageDeviceState`,
        params
    })
}
