import request from '../utils/request'
import axios from 'axios'

const getSetting = (params) => {
    return request({
        url:"/common2/centrolRelation/getManageableDevice",
        method:'GET',
        params
    })
}

export const settingDevice = (data) => {
    return request({
        url:'/common2/centrolRelation/addCentrolDevice',
        method:'POST',
        data
    })
}

const alreadySetting = (params) => {
    return request({
        url:'/common2/centrolRelation/selectManagedCentrol',
        method:'GET',
        params
    })
}

export const allSettingData = (params) => {
 return axios.all([getSetting(params),alreadySetting(params)])
}

export const addHouseDevice = (hsId) => {
    return request({
        url:'/common2/centrolRelation/addHouseDevice',
        method:'GET',
        params:{
            hsId
        }
    })
}

export const selectCentrol = (params) => {
    return request({
        url:'/common2/centrolRelation/selectCentrol',
        method:'GET',
        params
    })
}