<template>
  <el-dialog v-dialogDrag
    width="500px"
    title="电箱控制"
    append-to-body
    :visible.sync="show"
    class="item"
    :close-on-click-modal="false"
    @close="dialogClose"
  >
    <el-form
      :label-position="labelPosition"
      label-width="150px"
      :model="formLabelAlign"
      :rules="rules"
      ref="formLabelAlign"
    >
      <el-form-item label="线路名称" prop="name">
        <el-input v-model="formLabelAlign.name" size="mini"></el-input>
      </el-form-item>
      <el-form-item label="最大功率（单位W）" prop="region">
        <el-input v-model="formLabelAlign.region" size="mini"></el-input>
      </el-form-item>
      <el-form-item label="过流门限值（单位A）" prop="type">
        <el-input v-model="formLabelAlign.type" size="mini"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">提交</el-button>
        <el-button type="danger" @click="show = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
const TYPES = ["power", "Leakage"];
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    panelForm: {
      type: Object,
      default() {
        return {};
      },
    },
    stateData: {
      type: Object,
      default() {
        return {};
      },
    },
    types: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    show(n, o) {
      this.$emit("close");
    },
  },
  data() {
    return {
      rules:{//表单验证
        name:[{ required: true, message: '线路名称不能为空', trigger: 'blur'}],
        region:[{ required: true, message: '功率不能为空', trigger: 'blur'}],
        type:[{ required: true, message: '电流不能为空', trigger: 'blur'}],
      },
      show: this.isShow,
      labelPosition: 'left',
      formLabelAlign: {
        //功率
        name: '',
        region: '',
        type: '',
      },
    };
  },
  created() {
    this.formLabelAlign.name = this.stateData.title
    this.formLabelAlign.region = this.stateData.mxgg
    this.formLabelAlign.type = this.stateData.mxgl
  },
  methods: {
    submit() {
      if (!this.submitForm(['formLabelAlign'], this)){
        return
      }
      this.$axios({
        method: 'post',
        url: `/common2/devices/operate`,
        data: {
          type: 'SWITCHSET',
          devId: this.panelForm.jourDeviceId,
          lineName: this.formLabelAlign.name,
          maximumPower: this.formLabelAlign.region,
          overflowThreshold: this.formLabelAlign.type
        }
      }).then(res=>{
        this.$message.success('修改成功')
      }).catch(err=>{
        this.isError(err,this)
      })
    },
    dialogClose() {
      this.resetForm(['formLabelAlign'], this);
      this.formLabelAlign = {
        name: '',
        region: '',
        type: '',
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  >>> .el-switch__core,
  .el-switch .el-switch__label {
    width: auto;
  }
}
</style>
