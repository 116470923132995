
import { mapState } from 'vuex';
export const autheEquipmentListMixin = {
    computed: {
        ...mapState(['authe']),
    },
    methods: {
        autheMixin(value) {
            return {
                "权限操作": this.authe.Hotel_IntelligentDevice_DeviceList_DoubleClickOperatingDevice,
                "添加智能设备":this.authe.Hotel_IntelligentDevice_DeviceList_AddSmartDevice,
                "修改设备类型":this.authe.Hotel_IntelligentDevice_DeviceList_ModifyDeviceType,
                "关联安装位置":this.authe.Hotel_IntelligentDevice_DeviceList_AssociatedInstallationLocation,
                "设置安装地址":this.authe.Hotel_IntelligentDevice_DeviceList_SetInstallationAddress,
                "设置情景模式":this.authe.Hotel_IntelligentDevice_DeviceList_SettingProfile,
                "定时任务管理":this.authe.Hotel_IntelligentDevice_DeviceList_ScheduledTaskManagement,
                "设备集控":this.authe.Hotel_IntelligentDevice_DeviceList_EquipmentCentralizedControl,
                "天猫设置":this.authe.Hotel_IntelligentDevice_DeviceList_EquipmentPermissions,
                "添加管理卡":this.authe.Hotel_IntelligentDevice_DeviceList_BatchCard,
                "注销管理卡":this.authe.Hotel_IntelligentDevice_DeviceList_CancelBatchcard,
                "设备拆分":this.authe.Hotel_IntelligentDevice_DeviceList_EquipmentSplit,
                "打印设备二维码":this.authe.Hotel_IntelligentDevice_DeviceList_PrintQrcode,
                "添加虚拟集控":this.authe.Hotel_Device_AddVirtualRemote,
                "酒店客控":this.authe.Hotel_IntelligentDevice_DeviceList_Visualization,//酒店专属
                "房间控制":this.authe.Hotel_IntelligentDevice_DeviceList_RoomControl,//酒店专属
                "刷新":this.authe.Hotel_IntelligentDevice_DeviceList_AutoRefresh,
            }[value]
        }
    }
}

